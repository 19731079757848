//https://telepresenz.com/operations-enablement-and-remote-support-software-for-vuzix-smart-glasses/
//Operations Enablement and Remote Support software for Vuzix Smart Glasses through Converge IoT and Telepresenz
import React from 'react';
import bannerimg20 from "../News/NewsData/NewsImageAssets/NewsCardImg19.png";
import './newsDetails.css';

const NewsDetails20 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Operations Enablement and Remote Support software for Vuzix Smart Glasses through Converge IoT and Telepresenz</h1>
        <div className='banner-Img '>
            <img src={bannerimg20} alt="perations Enablement and Remote Support software for Vuzix Smart Glasses through Converge IoT and Telepresenz" />
        </div>
        <p>Converge IoT, a National Distributor/Primary Agent for the T-Mobile for Business Channel Partner Program, will sell Vuzix 
            smart glasses utilizing Telepresenz software across multiple industry verticals using T-Mobile’s 5G network – the largest, 
            fastest, and most reliable 5G network in the U.S.</p>
        <p>Wearable solutions using Telepresenz software are expected to be impactful to businesses with field technical workers such as 
           residential and commercial property maintenance, construction, healthcare, utilities, automotive, and many more.</p>
        <p><i>“Converge IoT strives to be a leader in innovative solutions that utilize 4G and 5G wireless connectivity. 
           The addition of Telepresenz – Vuzix bundle to our line-up is the perfect example of how 5G will transform how companies do 
           business today,” <b>stated Mark Savage, CEO of Converge IoT</b>. “We look forward to supporting direct customers and resellers to deliver Vuzix smart glasses.“</i></p>
        <p><i>"We are really excited about our partnership with Converge IoT and Vuzix to provide customers with best-in-class 
        connected worker and remote operations software solutions over T-Mobile’s 5G network,” <b>commented Ritesh Tolia, CRO at Telepresenz.</b> 
        “This partnership will empower field workers with real-time access to expertise, data and information for diagnosing and resolving critical issues as they occur.”</i></p>
        <h6>About Converge IoT</h6>
        <p>Converge IoT supports Resellers, Agents, and Business customers with 4G LTE and 5G equipment to connect their wireless solutions. 
           Converge onboards best-in-class solutions into its Partner Teaming Program and its direct carrier sales teams cover strategic 
           accounts, enterprise, SMB/mid-market, and government.</p>
        <p>To learn more about Converge IoT and their partner program, visit 
            <a href="http://www.convergeiot.com/" target='_blank'> Converge IoT’s Website,</a> or their <a href="https://www.linkedin.com/company/converge-iot/" target='_blank'> LinkedIn profile.</a></p>
        <h6>About Telepresenz</h6>
        <p>Telepresenz offers an augmented reality-based collaboration platform for front-line workers. They service clients with geographically dispersed 
           field teams who require a collaboration platform that includes video on demand and workflow automation. They specialize in remote assistance, 
           digitized workflows, and client specific customizations of our platform that can be delivered on a variety of smart devices including AR glasses. 
           To discover more about Telepresenz, visit
           <a href="https://telepresenz.com/" target='_blank'> Telepresenz’s Website</a>, or their
           <a href="https://www.linkedin.com/company/telepresenz-official/" target='_blank'> LinkedIn profile.</a></p>
        <h6>Converge IoT Sales and Contact:</h6>
        <p><a href={'mailto:sales@convergeiot.com'}>sales@convergeiot.com</a></p>
        <h6>Telepresenz Sales and Contact:</h6>
        <p><a href={'mailto:sales@telepresenz.com'}>sales@telepresenz.com</a></p>

      </div>
      
    </>
  )
}

export default NewsDetails20;
