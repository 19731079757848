//https://telepresenz.com/how-remote-assistance-is-helping-companies-in-times-of-covid/
//How Remote Assistance Is Helping Companies in Times of COVID

import React from 'react';
import bannerimg42 from "../News/NewsData/NewsImageAssets/NewsCardImg41.png";
import './newsDetails.css';


const NewsDetails42 = () => {
  return (
    <> 
      <div className='newsDetails'>
          <h1>How Remote Assistance Is Helping Companies in Times of COVID</h1>
          <div className='banner-Img '>
            <img src={bannerimg42} alt="Remote Assistance Is Helping Companies in Times of COVID" />
          </div>
          <p>Many companies thought the need to go digital would develop years down the line because their current processes were “business as usual”. 
            Due to COVID-19, these corporations were forced to adopt a completely virtual workforce overnight. Remote assistance technologies 
            transitioned from a “want” to a “need”. From industrial manufactures to field service engineers to customer support centers, remote 
            assistance technologies combined with augmented reality is the solution to navigating new business practices.</p>
          <p>Establishments that were already willing to adopt newer technology were prepared to face the current pandemic at full force. Those 
            companies who turned to remote mentor solutions to increase efficiency and productivity were especially able to create a virtually 
            connected workforce to continue customer support and avoid disruptions in their facilities.</p>
          <p>Here are some ways our clients are using the Telepresenz® remote assistance solution to maintain their operations during shutdowns:</p>
          <div className='subContent'>
            <p className='subHeader'>Industrial Suppliers</p>
            <p>Our clients need to stay connected with their customers to make sure their manufacturing stays up and running. While previous customer support 
             included sending an engineer to go on-site to fix problems, they now have the ability to remotely connect to an expert in real-time, so 
             they see exactly what their client is seeing via augmented reality smart glasses. They can still work directly with customers and quickly 
             respond to issues, therefore decreasing their problem resolution time.</p>
            <p className='subHeader'>Manufacturing</p>
            <p>Manufacturing companies were forced to change procedures and install new equipment. Instead of giving customer support over the phone, 
             they are able to provide visual directions to their customers. This is as easy as sharing schematics and images directly to the mechanics viewer. 
             Even new hires and employees with little experience were able to learn and build expertise in various domains that they would not have been able 
             to under the new social distancing standards.</p>
            <p>The current pandemic has forced companies to think outside of the box to sustain a future. For the corporations that have adopted a remote 
            assistance technology combined with augmented reality, a reduction of problem resolution time of 65% was the result. It is time for 
            businesses to look into their operational procedures and implement new technological advancements to interact.</p>
            
          </div>
      </div>
      
    </>
  )
}

export default NewsDetails42;
