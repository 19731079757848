import React from "react";
import CardBlock from "../../BuildingBlocks/CardBlock/CardBlock";
import "./services.css";

export default function OurServices() {
  return (
    <div>
      <div className="services-banner"></div>
      <CardBlock />
    </div>
  );
}
