import React from "react";
import productImg1 from "../../assets/productsPageImages/Connected-Worker-tasks.jpg";
import productImg2 from "../../assets/productsPageImages/control-center.jpg";
import productImg3 from "../../assets/productsPageImages/technician-in-the-field.jpg";
import productImg4 from "../../assets/productsPageImages/telepresenz-audits-inspections.jpg";
import productImg5 from "../../assets/productsPageImages/telepresenz-worker-in-the-field.jpg";
import telepresenzProdCardImg1 from "../../assets/home/Transportation.jpg";
import telepresenzProdCardImg2 from "../../assets/home/Facility-Management.jpg";
import telepresenzProdCardImg3 from "../../assets/home/Energy-utility.jpg";
import telepresenzProdCardImg4 from "../../assets/home/manufacturing.jpg";
import telepresenzProdCardImg5 from "../../assets/home/healthcare.png";
import smartOperationSuite from "../../assets/productsPageImages/smart-op-suite.png";
import smartOPS from "../../assets/productsPageImages/smart-ops.jpg";
import smartEYE from "../../assets/productsPageImages/smart-eye.jpg";
import smartITM from "../../assets/productsPageImages/smart-itm.jpg";
import portfolioCardImg1 from "../../assets/home/portfolioCardImage1.png";
import portfolioCardImg2 from "../../assets/home/portfolioCardImage2.png";
import portfolioCardImg3 from "../../assets/home/portfolioCardImage3.png";
import portfolioCardImg4 from "../../assets/home/portfolioCardImage4.png";
import portfolioCardImg5 from "../../assets/home/portfolioCardIMage5.png";

import { Card } from "react-bootstrap";
import { useState } from "react";
import "./products.css";

const Products = () => {

  const [selectedImage, setSelectedImage] = useState(smartOPS) ; 
 
  const productDisplaySection = (event, sectionId) => {
    let selectionList = [
      "selectItem1",
      "selectItem2",
      "selectItem3",
      "selectItem4",
      "selectItem5",
    ];

    selectionList.map((item) =>
      document.getElementById(item).classList.remove("changeColor")
    );
    event.target.classList.remove("changeColor");
    event.target.classList.add("changeColor");

    let cardId = [
      "cardSection1",
      "cardSection2",
      "cardSection3",
      "cardSection4",
      "cardSection5",
    ];

    cardId.map(
      (item) => (document.getElementById(item).style.display = "none")
    );
    document.getElementById(sectionId).style.display = "flex";
  };

  const handleClick = (image) =>{
      setSelectedImage(image);
  };


  return (
    <div>
      <div className="ProductBanner"></div> 
      {/*<div className="Product-body">
        <div className="productSection">
          <div className="productimgSection">
            <img src={productImg1} alt="productImg" />
          </div>
          <div className="productContentSection">
            <h1>Smart operations</h1>
            <ul>
              <li>
                Digitized workflow reduces errors and improves speed and
                accuracy
              </li>
              <li>
                Easier access to expert oversight improves MRO turnaround time
              </li>
              <li>
                Remote inspections that allow real-time assistance and technical
                clarification
              </li>
            </ul>
          </div>
        </div>
        <div className="productSection">
          <div className="productContentSection">
            <h1>Doc Xplore</h1>
            <ul>
              <li>
                Ensure all tasks are completed and collect validation at task
                level
              </li>
              <li>Progress updates of all jobs reported in real-time</li>
              <li>
                Easy access to centralized repository of all jobs and their
                status
              </li>
              <li>
                System-generated notifications to alert when job is
                assigned/delayed/finished
              </li>
            </ul>
          </div>
          <div className="productimgSection">
            <img src={productImg2} alt="productImg" />
          </div>
        </div>
        <div className="productSection">
          <div className="productimgSection">
            <img src={productImg3} alt="productImg" />
          </div>
          <div className="productContentSection">
            <h1>Connected Worker</h1>
            <p>
              For enterprise companies that work with field teams, Telepresenz
              enables remote, real-time access to top-level expertise when you
              need it most. For mission-critical situations or any scenario that
              depends on a fast, accurate resolution, there is no better way to
              connect.
            </p>
          </div>
        </div>
        <div className="productSection">
          <div className="productContentSection">
            <h1>Remote Operations</h1>
            <p>
              In highly competitive markets for maintenance, repair and overhaul
              (MRO), companies that operate more efficiently gain a competitive
              edge. MROs face many challenges, ranging from manual work
              scheduling to non-accurate work estimations and lack of resource
              visibility. The need of the hour is to have a one-stop-shop MRO
              solution that enables paperless operations, manages complex tasks
              and is easy to use.
            </p>
          </div>
          <div className="productimgSection">
            <img className="prodImg4" src={productImg4} alt="productImg" />
          </div>
        </div>
        <div className="productSection">
          <div className="productimgSection">
            <img className="prodImg5" src={productImg5} alt="productImg" />
          </div>
          <div className="productContentSection">
            <h1>Knowledge capture</h1>
            <p>
              With the aging workforce leaving the labor market, leading
              enterprises are pursuing new ways of providing their frontline
              workers with critical tribal knowledge to keep them competitive
              and productive. As products become more complex and customer
              demands shift, the need to retain and scale key SME knowledge is
              vital to the industries’ success.
            </p>
          </div>
        </div>
      </div>*/}


      <div className="Product-body">
           <h1>SMART OPERATIONS SUITE</h1>
           <br />
           <div className="productSection">
                            <ul className="productRgtSec">
                              <li>At Telepresenz®, we believe in a future where
                                safety and efficiency are paramount for mobile
                                workforces.</li>
                                <li>We envision a world where every
                                frontline team has the tools they need to thrive.</li>
                                <li>By focusing on enhancing safety and digitization,
                                we've developed the Smart Operations product
                                suite.</li>
                                <li>Our expertise lies in harnessing cutting edge technologies to create intuitive, intelligent
                                systems.</li>
                                <li>These systems not only understand and
                                    predict user needs but also provide businesses
                                    and individuals with valuable, data-driven insights.</li>
                            </ul>
                             <div className="prodSec">
                             <img src={smartOperationSuite} alt="Smart Operation Suite" />

                              </div>  
                            
                            <ul className="productLftSec">
                                 <li>At Telepresenz®, we provide an off-the-shelf
                              Smart Operations platform along with customized
                              solutions to drive our customers' digital
                              transformation initiatives, delivering direct return
                              on investment.</li>
                              <li>Wherever you are in your process, Telepresenz®
                              can seamlessly integrate, co-opt, automate, and
                              elevate your operations.</li>
                              <li>Our core value as a
                              company is to partner with customers in
                              developing and deploying solutions that bring
                              meaningful and sustainable business impact.</li>
                            </ul>        
           </div>
           <div className=" horizontalDivider">      
            </div>
          
          
          <div className="portfolioSection">
                 <div className="portfolioList">
                 <ul>
                        <li className={`portfolioSectionHeader ${selectedImage === smartOPS ? 'active' : ''}`} 
                            onClick={() => handleClick(smartOPS)}> 
                            <p>Smart OPS</p>
                        </li>
                        <li className={`portfolioSectionHeader ${selectedImage === smartEYE ? 'active' : ''}`} 
                            onClick={() => handleClick(smartEYE)}>
                            <span className="vertilceDivider"></span>
                            <p>Smart EYE</p>
                        </li>
                        <li className={`portfolioSectionHeader ${selectedImage === smartITM ? 'active' : ''}`} 
                            onClick={() => handleClick(smartITM)}>
                            <span className="vertilceDivider"></span>
                            <p>Smart ITM</p>
                        </li>
                       {/* <li className="portfolioSectionHeader"
                            onClick={() => handleClick(smartITM)}>
                            <span className="vertilceDivider"></span>
                            <p>Smart Analytics</p>
                        </li>*/}
                    </ul>
                 </div>
          </div>
          <div className="productSection">
                 {selectedImage === smartOPS && (
                    <>
                        <div className="productSectionLeft">
                              <div className="productImgSection">
                                      <div key={selectedImage} className={`productImg ${selectedImage ? 'animate' : ''}`}>
                                            <img src={smartOPS} alt="Smart OPS" className="responsiveImage"/>
                                      </div>
                              </div>
                        </div>
                        <div className="porductSectionRight">
                            <div className="productContentSection ">
                                <div className="productContent animate">
                                    <h4>Enhanced Frontline Productivity</h4>
                                    <ul>
                                        <li>Step-by-step digital workflows coupled with accessible guides.</li>
                                        <li>Instant access to information,minimising errors, and streamlining tasks.</li>
                                   </ul>
                                   <h4>Real-Time Data Reporting</h4>
                                   <ul>
                                       <li>Swift communication between frontline and back-office.</li>
                                       <li>Quicker decision-making based on up-to-date task completion insights.</li>
                                   </ul>
                                    <h4>Remote Expert Guidance.</h4>
                                    <ul>
                                       <li>Instantly connect with experts worldwide.</li>
                                       <li>Receive real-time guidance via AR for quick issue resolution.</li>
                                    </ul>
                                </div>                        
                            </div>
                        </div>                                       
                    </>
                )}
                                    
                 {selectedImage === smartEYE && (
                    <>
                        <div className="productSectionLeft">
                              <div className="productImgSection">
                                      <div key={selectedImage} className={`productImg ${selectedImage ? 'animate' : ''}`}>
                                            <img src={smartEYE} alt="Smart OPS" className="responsiveImage"/>
                                      </div>
                              </div>
                        </div>
                        <div className="porductSectionRight">
                            <div className="productContentSection ">
                            <div className="productContent animate">
                                    <h4>Remote Monitor</h4>
                                      <ul>
                                          <li>Instant live feeds from multiple sources like CCTV, Portable camera, drones etc.</li>
                                          <li>Real time Tracking, Alerts and compliance with Company policies and SOP’s.</li>
                                      </ul>
                                    <h4>Live Inspections | Workflows</h4>
                                    <ul>
                                      <li>Conduct live inspections with remote experts and perform emergency response procedures.</li>
                                      <li>Share visual data, perform actionsas per the workflow and guidance.</li>
                                    </ul>
                                    <h4>Smart Dashboards:</h4>
                                    <ul>
                                      <li>Customized control room dashboards based on verticals of operations.</li>
                                    </ul>
                                </div>    
                            </div>
                        </div>                     
                    </>
                  )}

                 {selectedImage === smartITM && (
                    <>
                        <div className="productSectionLeft">
                              <div className="productImgSection">
                                      <div key={selectedImage} className={`productImg ${selectedImage ? 'animate' : ''}`}>
                                            <img src={smartITM} alt="Smart OPS" className="responsiveImage"/>
                                      </div>
                              </div>
                        </div>
                        <div className="porductSectionRight">
                            <div className="productContentSection ">
                                             

                                  <div className="productContent animate">
                                        <h4>Knowledge Capture</h4>
                                        <ul>
                                            <li>Capture live content inline with Work Instructions & Jobs.</li>
                                            <li>Automated workflow and SOP generator from the field.</li>
                                            <li>Create knowledge bank for upskilling and training.</li>
                                            <li>Reduce risk caused by aging workforce.</li>
                                      </ul>
                                      <h4>DocXplore</h4>
                                      <ul>
                                          <li>Digital Document Viewer for hands free operations.</li>
                                          <li>Smart search, night mode, annotations, remarks etc., some key features.</li>
                                      </ul>
                                </div>          
                            </div>
                        </div>                                     
                    </>
                   )}
       
          </div>  
           
      </div>
      <div className=" horizontalDivider">      
      </div>
      <div className="portfolioSection">
        <div className="portfolioList">
          <ul>
            <li onClick={(event) => productDisplaySection(event, "cardSection1")}>
              <p id="selectItem1" className="changeColor">Transportation</p>
            </li>
            <li onClick={(event) => productDisplaySection(event, "cardSection2")}>
              <span className="vertilceDivider"></span>
              <p id="selectItem2">Facility Management</p>
            </li>
            <li onClick={(event) => productDisplaySection(event, "cardSection3")}>
              <span className="vertilceDivider"></span>
              <p id="selectItem3">Energy & Utilities</p>
            </li>
            <li onClick={(event) => productDisplaySection(event, "cardSection4")}>
              <span className="vertilceDivider"></span>
              <p id="selectItem4">Manufacturing</p>
            </li>
            <li onClick={(event) => productDisplaySection(event, "cardSection5")}>
              <span className="vertilceDivider"></span>
              <p id="selectItem5">Healthcare</p>
            </li>
          </ul>
        </div>

        <div id="cardSection1"className="portfolioCardSection portfolioCardSectionDisplayed">
          <div className="CardContentimg">
            <img src={telepresenzProdCardImg1} alt="portfolioImg"/>
          </div>
          <div className="portfolioCardContentSec">
            <div className="CardContent">
              <div className="portfolioImg">
                  
                {/*{cardContentSection1.map(card => (
                          <div key={card.id} className="">
                            <Card>
                              <Card.Body>
                                <Card.Text>{card.content}</Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                      ))}   */}  
                <img src={portfolioCardImg1} alt="Transportation" />
                
              </div>
                
            </div>
          </div>
        </div>

        <div id="cardSection2" className="portfolioCardSection">
          <div className="CardContentimg">
            <img src={telepresenzProdCardImg2} alt="portfolioImg"/>
          </div>
          <div className="portfolioCardContentSec">
            <div className="CardContent">
               <div className="portfolioImg">
                 <img src={portfolioCardImg2} alt="Transportation" />
              </div>
            </div>
          </div>
        </div>

        <div id="cardSection3" className="portfolioCardSection">
          <div className="CardContentimg">
            <img src={telepresenzProdCardImg3} alt="portfolioImg"/>
          </div>
          <div className="portfolioCardContentSec">
            <div className="CardContent">
              <div className="portfolioImg">
                   <img src={portfolioCardImg3} alt="Transportation" /> 
              </div>
            </div>
          </div>
        </div>

       
        <div id="cardSection4" className="portfolioCardSection">
          <div className="CardContentimg">
            <img src={telepresenzProdCardImg4} alt="portfolioImg"/>
          </div>
          <div className="portfolioCardContentSec">
            <div className="CardContent">
                <div className="portfolioImg">
                    <img src={portfolioCardImg4} alt="Transportation" />
                 </div>
            </div>
          </div>
        </div>

         <div id="cardSection5" className="portfolioCardSection">
          <div className="CardContentimg">
            <img src={telepresenzProdCardImg5} alt="portfolioImg"/>
          </div>
          <div className="portfolioCardContentSec">
            <div className="CardContent">
              <div className="portfolioImg">
                 <img src={portfolioCardImg5} alt="Transportation" />
                 </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
