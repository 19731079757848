//https://telepresenz.com/moziware-and-telepresenz-team-up-to-launch-a-new-pocket-connected-worker/
//Moziware® and Telepresenz® team up to launch a new Pocket Connected Worker
import React from 'react';
import smartUtilitiesWorker from './NewsDetailsAssets/smart-utilities-worker.png';
import bannerimg24 from "../News/NewsData/NewsImageAssets/NewsCardImg23.png";
import './newsDetails.css';


const NewsDetails24 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Moziware® Teams Up With Telepresenz® To Launch New Pocket Connected Worker</h1>
        <div className='banner-Img '>
            <img src={bannerimg24} alt="Pocket Connected Worker" />
        </div>
        <p>Moziware® today announced a new partnership that combines their lightweight pocket-sized hands-free wearable CIMO 
           (pronounced ‘see-more’) with Telepresenz® integrated connected worker solutions. Immediately available are Telepresenz 
           Connected Worker and Remote Operations bundled with CIMO.</p>
        <p>Telepresenz have further developments on the horizon, including a new field led Knowledge Capture and Training solution 
            that protects loss of knowledge being caused by ‘the great crew change’.</p>
        <p>Bo Li, co-founder and CEO of Moziware Technology, highlighted the significance of the partnership by saying,</p>
        <p><i>“Telepresenz excites us with their easy to use fully functional platform that delivers digital 
            transformation to mobile field workers across a wide range of industry use cases. They bring practical know-how with real-world 
            experience and consultative support that’s easy to consume.”</i></p>
        <p>Telepresenz® CRO and Head of Global Strategic Partnerships Ritesh Tolia, responded with,</p>
        <p><i>“With CIMO, we now have a lightweight, rugged, voice-controlled wearable that’s affordable, easy to use and 
            it fits in your pocket or in the palm of your hand! It’s perfect for Utilities, MRO, Manufacturing and Emergency Services, where 
            customers can get a pocket powered wearable for just $50 a month when they subscribe to Telepresenz®”</i></p>
        <p>Ritesh advises the launch of their CIMO based Pocket Connected Worker solutions commences at partner events across Australia and 
            New Zealand late July, with plans to continue launching across the world in the coming months. Email sales@telepresenz.com for more details.</p>
        <p>For more information on Moziware Technology, contact at <a href={'mailto:sales@moziware.us'}>sales@moziware.us</a></p>    
      </div>
    </>
  )
}

export default NewsDetails24;
