//https://telepresenz.com/vuzix-expands-hipaa-compliant-healthcare-offerings-with-the-addition-of-telepresenz-from-care4d-for-the-m400-smart-glasses/
//Vuzix Expands HIPAA Compliant Healthcare Offerings with the Addition of Telepresenz from  CARE4D for the M400 Smart Glasses

import React from 'react';
import bannerimg39 from "../News/NewsData/NewsImageAssets/NewsCardImg38.jpg";
import './newsDetails.css';

const NewsDetails39 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Vuzix Expands HIPAA Compliant Healthcare Offerings with the Addition of Telepresenz from  CARE4D for the M400 Smart Glasses</h1>
        <div className='banner-Img '>
            <img src={bannerimg39} alt="Vuzix Expands HIPAA Compliant Healthcare Offerings with the Addition of Telepresenz from  CARE4D for the M400 Smart Glasses" />
        </div>
        <p>FOR IMMEDIATE RELEASE</p>
        <p>Telepresenz® info@care4d.com 732-440-9453</p>
        <p>Telepresenz® from Care4D offers hands-free augmented reality functionality for healthcare and enterprise professionals </p>
        <p>ROCHESTER, N.Y., Aug. 13, 2020 /PRNewswire/ –Vuzix® Corporation (NASDAQ: VUZI), (“Vuzix” or, the “Company”), a leading 
           supplier of Smart Glasses and Augmented Reality (AR) technology and products, announced today that Vuzix’ M400 Smart Glasses are now supported by
           <a href="https://care4d.com/innovation/" target='_blank'> CARE4D’s </a>Telepresenz®, a HIPAA compliant augmented reality telepresence solution that connects remote 
           users to mentors, experts, or supervisors across healthcare and industry. </p>
        <p><a href="https://www.telepresenzdemo.com/" target='_blank'>Telepresenz</a> enables medical professionals or enterprise technicians to connect to a remote expert 
        using the Vuzix Smart Glasses to securely stream to a remote expert. The Telepresenz® platform enables real-time, two-way audio-video communication between a 
        base station expert and remote users who could be in any location, anywhere in the world. </p>
        <p>In addition to remote support, the Telepresenz® platform also provides access to procedures and workflows to help enterprise customers with step-by-step 
        instructions or guided instructions in the field. </p>
        <p>“We at CARE4D are excited to partner with Vuzix, the leading supplier of Smart AR Glasses. Telepresenz® solution and the Vuzix M400 Glasses together make an attractive 
        and complete offering for the end customer,” said Ritesh Tolia, Co-Founder and Chief Revenue Officer at CARE4D. </p>
        <p>“We are pleased to have the support of CARE4D as another HIPAA compliant software partner that can deliver augmented intelligence to frontline healthcare workers 
        or field technicians performing inspections or remote maintenance,” said Paul Travers, President and Chief Executive Officer at Vuzix. </p>
        <h5>About Vuzix Corporation </h5>
        <p>Vuzix is a leading supplier of Smart-Glasses and Augmented Reality (AR) technologies and products for the consumer and enterprise markets. The Company’s 
            products include personal display and wearable computing devices that offer users a portable high-quality viewing experience, provide solutions for mobility, 
            wearable displays and augmented reality. Vuzix holds 166 patents and patents pending and numerous IP licenses in the Video Eyewear field. The Company has 
            won Consumer Electronics Show (or CES) awards for innovation for the years 2005 to 2020 and several wireless technology innovation awards among others. 
            Founded in 1997, Vuzix is a public company (NASDAQ: VUZI) with offices in Rochester, NY, Oxford, UK, and Tokyo, Japan. For more information, visit Vuzix
            <a href="https://www.vuzix.com/" target='_blank'> Website</a>,
            <a href="https://twitter.com/Vuzix" target='_blank'> Twitter</a> and
            <a href="https://www.facebook.com/Vuzix/" target='_blank'> Facebook</a> pages.</p>
        <h5>Forward-Looking Statements Disclaimer </h5>
        <p>Certain statements contained in this news release are “forward-looking statements” within the meaning of the Securities Litigation Reform Act of 1995 and applicable 
        Canadian securities laws. Forward looking statements contained in this release relate to Vuzix M400 Smart Glasses, our business relationship with CARE4D, related future 
        business opportunities, and among other things the Company’s leadership in the Smart Glasses and AR display industry. They are generally identified by words such as 
        “believes,” “may,” “expects,” “anticipates,” “should” and similar expressions. Readers should not place undue reliance on such forward-looking statements, which are 
        based upon the Company’s beliefs and assumptions as of the date of this release. The Company’s actual results could differ materially due to risk factors and other 
        items described in more detail in the “Risk Factors” section of the Company’s Annual Reports and MD&A filed with the United States Securities and Exchange Commission 
        and applicable Canadian securities regulators (copies of which may be obtained at www.sedar.com or www.sec.gov). Subsequent events and developments may cause these 
        forward-looking statements to change. The Company specifically disclaims any obligation or intention to update or revise these forward-looking statements as a result 
        of changed events or circumstances that occur after the date of this release, except as required by applicable law. </p>
        <p>SOURCE Vuzix Corporation </p>
        <p>To learn more about the Telepresenz® solution from CARE4D and Vuzix Smart Glasses, receive a 2-week free trial by visiting
        <a href="https://www.telepresenzdemo.com/" target='_blank'> www.telepresenzdemo.com </a> or call (732) 440-9453.</p>
      </div>
    </>
  )
}

export default NewsDetails39;
