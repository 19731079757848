//https://telepresenz.com/industrial-wearables/
//Industrial Wearables – why they're important and how this new technology is helping industrial workers.

import React from 'react';
import bannerimg31 from "../News/NewsData/NewsImageAssets/NewsCardImg30.png";
import industrialWearables from './NewsDetailsAssets/industrial_wearables.jpg';
import mro from './NewsDetailsAssets/mro.jpg';
import gamifyingJobs from './NewsDetailsAssets/gamifying-jobs.png';
import './newsDetails.css';

const NewsDetails31 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Industrial Wearables – why they're important and how this new technology is helping industrial workers.</h1>
        <div className='banner-Img '>
            <img src={bannerimg31} alt="Industrial Wearables" />
        </div>
        <p>Wearables have been getting a lot of attention recently, with major tech companies like Apple and 
            Samsung leading the charge to mass-market smart watches and other wearables.</p>
        <p>While they have been targeted primarily at consumers, they have quickly found an application in 
            the industrial sector as well. In fact, the oil and gas industries have been using them for years to 
            help their employees do their jobs more efficiently and safely.</p>
        <div className='newsDetailImg'>
             <img src={mro} alt="Industrial Wearables-helping industrial workers" />
        </div>
        <h3>1) What are Industrial Wearables?</h3>
        <p>Industrial wearables are essentially connected sensors that monitor performance, health, or wellbeing in some way. These devices vary widely 
           in size, style, and functionality but what unites them all is their power to transform our working lives for the better—and at a very real 
           level by providing real-time data on productivity, alerting of errors or issues before they occur or even extending the range of motion so 
           work can be done with greater ease by anyone, no matter their physical ability.</p>
        <p>As businesses worldwide rush to implement these new technologies across their organizations, it’s vital we get ahead of them by asking ourselves 
            what our goals are for using industrial wearables in our business.</p>
        <p>Some of the top industrial wearables are    and . 
            <a href="https://www.realwear.com/" target='_blank'> RealWear</a>,
            <a href="https://www.vuzix.com/" target='_blank'> Vuzix</a>,
            <a href="https://www.rokid.com/" target='_blank'> Rokid Glass</a>,
            <a href="https://eleksen.com/" target='_blank'> Eleksen</a> and
            <a href="https://behrtech.com/" target='_blank'> Behr Tech</a>
            . They all have product lines designed to help workers with their day-to-day processes.</p>
        <h3>2) Why Are Industrial Wearables Important?</h3>
        <p>Industrial wearables are a new wave of technology designed to help factory, field, assembly line and other industrial employees complete 
            their tasks with greater efficiency, while also providing greater insight into what’s happening on-site.</p>
        <p>These devices help enable workers to better do their jobs by improving machine uptime and increasing worker safety. And because wearables 
            typically have low barriers to entry—many solutions can be set up in under 30 minutes—many businesses are turning to these tools as a way 
            to gain a competitive edge in manufacturing operations.</p>
        <p>Other benefits include greater accuracy when it comes to asset utilization rates, improved decision-making capabilities, and reduced workplace injuries.</p>
        <div className='newsDetailImg'>
            <img src={industrialWearables} alt="Industrial Wearables-helping industrial workers" />
        </div>
        <h3>3) How Are Industrial Wearables Helping Workers?</h3>
        <p>As stated in an industry report, two of every three people working in manufacturing are expected to be operating machinery by themselves within ten years. 
           To that end, these employees will need some sort of wearable technology—both for safety reasons and to improve efficiency on their jobs—to ensure they 
           stay safe while completing their duties.</p>
        <p>Of course, not all wearables created specifically for industrial applications work in quite the same way; however, understanding just what some of 
           these technologies can do can help you choose which one best suit your needs!</p>
        <h5>Industrial Wearables for Training Industrial Workers</h5>
        <p>New technology in wearables allows for an unprecedented level of efficiency in training. Whereas a few years ago a process worker might need a whole 
           day to get a single task down, these days it’s possible to show that same person exactly what to do with just a short video.</p>
        <p>In fact, wearable tech can make it even easier for novice operators because it provides augmented assistance when needed most—when tasks are hardest or unfamiliar.</p>
        <h5>Industrial Wearables for Gamifying Industrial Jobs</h5>
        <p>Gamification can be thought of as a way to use game-like features in non-game environments. Many companies are now using gamification to encourage certain behaviors, 
           drive engagement or increase productivity – and that includes industrial applications. One of these applications that has been gaining momentum lately is gamifying 
           industrial work – not just for fun but to improve performance as well. Wearable technology adds a new level to this.</p>
        <div className='newsDetailImg'> 
           <img src={gamifyingJobs} alt="Industrial Wearables for Gamifying Industrial Jobs" />
        </div>
        <h3>4) Why You Need Specific Software for Industrial Wearables</h3>
        <p>When you look at a worker in a manufacturing plant, one of their most valuable tools isn’t a piece of equipment, but rather a software program designed to 
           help them work more efficiently and stay on task.</p>
        <p>There are lots of software options for wearables but very few exists specifically for industrial workers. If you’re interested in trying industrial-focused 
            software for wearables you can get access to a great one for free <a href="https://telepresenz.com/try-for-free/" target='_blank'>here</a>.</p>
        <h3>Final Thoughts</h3>
        <p>If your industrial company requires some of its employees to perform repetitive tasks, try gamifying those jobs with wearable tech. This can help them 
           better understand what needs to be done and help companies retain their top talent by focusing on areas where employee satisfaction is low and turnover high.</p>

      </div>
    </>
  )
}

export default NewsDetails31;
