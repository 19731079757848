import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import "./careers.css";

const Careers = () => {
    return (
        <div className='contact-container'>
            <div className='contact-banner'>
                {/*<h1>Join Our Team</h1>
                <h5>We are an innovative, fast-growing, SaaS company seeing rapid global adoption of Telepresenz®, our smart operations platform. We offer an off-the-shelf smart operations SaaS platform, as well as custom solutions for our customers’ digital transformation initiatives.</h5>*/}
            </div>
            <div className='contact-whywork'>
                <h1>Why Work at Telepresenz®?</h1>
                <p>At Telepresenz®, we believe that talent is the key to our success. We foster a dynamic and inclusive work environment that encourages creativity, collaboration, and personal growth. When you join our team, you’ll be part of a diverse group of exceptional individuals who are all committed to driving the future of remote collaboration.</p>
            </div>

            <div className="Open-Positions">
                <h1>Open Positions</h1>
                <p>Are you seeking an opportunity to make a real difference in a company at the cusp of their growth? Come join us and grow with a team of people who will energize and inspire you!</p>
            </div>
            <div className='contact-hr'>
                <h4><strong>To Apply:</strong></h4>
                <p> Drop an email to <span>hr@telepresenz.com</span> with your updated resume and the job title in the subject line.</p>
            </div>
            <div className='accordian-section'>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h5><strong>Full Stack Developer</strong></h5></Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <p><strong><span>Location: </span><span>Bangalore</span></strong></p>
                                <p><strong><span>Position Type: </span><span>Full Time</span></strong></p>
                                <p><strong><span>Years Of Experience: </span><span>6+</span></strong></p>
                                <p><strong>Generic Requirements</strong></p>
                                <ul>
                                    <li>Must have excellent communication skills</li>
                                    <li>Must showcase the ability to comprehend discussions and work assignments</li>
                                    <li>Must have very good problem solving skills</li>
                                    <li>Must be a team player</li>
                                    <li>Must be flexible and willing to wear multiple hats as needed in the best interest of the product and release plans</li>
                                    <li>Must have been involved actively with Software Product Development and Release Cycles for 6+ years in the industry</li>
                                    <li>Must be willing to work in a start-up work environment</li>
                                    <li>Nice to have exposure to one or more of the following:
                                        <ul>
                                            <li> Live video streaming</li>
                                            <li> GPS related programming</li>
                                            <li> Windows sockets</li>
                                            <li> AWS/Azure deployment setup​</li>
                                            <li> Payment gateway interfaces</li>
                                            <li> 3D model rendering (with and without animations)</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p><strong>Technical Requirements and Responsibilities</strong></p>
                                <ul>
                                    <li>6+ years of working experience with one or more JavaScript frameworks like Angular / Node / React</li>
                                    <li>At least 2 years with Reactjs / Nodejs</li>
                                    <li>Full stack web developer</li>
                                    <li>Reviewing and analyzing system specifications</li>
                                    <li>Proven track record to take features from concept/specification to complete implementation in production</li>
                                    <li>Attend technical requirements, design and engineering discussions and take it from concept to high quality implementation</li>
                                    <li>Implementing unit test scripts and reviewing results</li>
                                    <li>Benchmark performance metrics of critical implementation of the implementation</li>
                                    <li>Documenting technical issues</li>
                                </ul>
                                <p><strong>Skills</strong></p>
                                <ul>
                                    <li>Proven experience as an experienced software engineer for at least 6 years</li>
                                    <li>Must be a JavaScript expert</li>
                                    <li>Experience working with a complete software development life cycle</li>
                                    <li>Familiarity with Agile frameworks</li>
                                    <li>Ability to document and troubleshoot errors</li>
                                    <li>Attention to detail</li>
                                    <li>Analytical mind and proven problem-solving</li>
                                    <li>Strong organizational skills and team player</li>
                                </ul>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h5><strong>Junior Android Developer</strong></h5></Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <p><strong><span>Location: </span><span>Bangalore</span></strong></p>
                                <p><strong><span>Position Type: </span><span>Full Time</span></strong></p>
                                <p><strong><span>Years Of Experience: </span><span>3+</span></strong></p>
                                <p><strong>Responsibilities:</strong></p>
                                <ol>
                                    <li>Collaborate with cross-functional teams to define, design, and ship new features. </li>
                                    <li>Develop and maintain high-quality Android applications. </li>
                                    <li>Implement and test application functionalities to ensure optimal performance. </li>
                                    <li>Collaborate with product managers and UI/UX designers to translate design specifications into functional code. </li>
                                    <li>Troubleshoot, debug, and optimize code to address issues and improve application performance.</li>
                                    <li>Stay updated on the latest industry trends and technologies to contribute fresh ideas for continuous improvement. </li>
                                </ol>
                                <p><strong>Qualifications:</strong></p>
                                <ol>
                                    <li>Bachelor's degree in Computer Science, Engineering, or a related field.</li>
                                    <li>Proven experience developing Android applications, either through coursework or personal projects.</li>
                                    <li>Solid understanding of Java and Kotlin programming languages. </li>
                                    <li>Familiarity with Android Studio, Git, and other relevant tools. </li>
                                    <li>Strong problem-solving skills and attention to detail. </li>
                                    <li>Ability to work collaboratively in a team environment. </li>
                                    <li>Excellent communication skills to convey technical concepts effectively. </li>
                                </ol>
                                <p><strong>Nice to Have:</strong></p>
                                <ul>
                                    <li>Experience with third-party libraries and APIs.</li>
                                    <li>Knowledge of mobile app security best practices</li>
                                    <li>Understanding of Agile development methodologies.</li>
                                    <li>Previous internship or work experience in Android development.</li>
                                </ul>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><h5><strong>Senior Python Developer with OpenCV, AI, and Machine Learning ExperienceJob</strong></h5></Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <p><strong><span>Location: </span><span>Bangalore</span></strong></p>
                                <p><strong><span>Position Type: </span><span>Full Time</span></strong></p>
                                <p><strong><span>Years Of Experience: </span><span>4 To 10 Years</span></strong></p>
                                <p><strong><span>Overview:</span><span></span></strong></p>
                                <p>We are seeking a highly experienced Senior Python Developer with a strong background in OpenCV and a profound understanding of AI and Machine Learning. The ideal candidate will have 4-10 years of relevant industry experience and will be instrumental in advancing our computer vision, AI, and machine learning initiatives.</p>
                                <p><strong><span>Key Responsibilities:</span><span></span></strong></p>
                                <p><strong>Advanced AI and Machine Learning Development:</strong> Develop cutting-edge AI and machine learning models to enhance computer vision algorithms using Python and OpenCV.</p>
                                <ul>
                                    <li><p><strong>Data Analysis and Algorithm Development:</strong>Analyze complex datasets to improve machine learning models and algorithm accuracy.</p></li>
                                    <li><p><strong>Cross-Functional Leadership:</strong> Lead and collaborate with teams to integrate AI and machine learning capabilities into comprehensive systems.</p></li>
                                    <li><p><strong>Algorithm Optimization and Performance Tuning:</strong> Optimize AI models and computer vision algorithms for superior performance and accuracy.</p></li>
                                    <li><p><strong>Quality Assurance and Testing:</strong> Conduct rigorous testing and maintenance of AI models and algorithms to ensure stability and reliability.</p></li>
                                    <li><p><strong>Technical Documentation and Reporting:</strong> Maintain detailed documentation and reporting on AI and machine learning developments and innovations.</p></li>
                                </ul>
                                <p><strong>Required Skills and Qualifications:</strong></p>
                                <ul>
                                    <li><p><strong>Expertise in Python Programming:</strong> Proficient in writing efficient, clean, and well-documented Python code.</p></li>
                                    <li><p><strong>Extensive Experience with OpenCV:</strong> Demonstrated experience in OpenCV for complex image processing and computer vision tasks.</p></li>
                                    <li><p><strong>Strong AI and Machine Learning Background:</strong> In-depth knowledge and practical experience in AI and machine learning, including model development and deployment.</p></li>
                                    <li><p><strong>Advanced Data Analysis and Problem-Solving Skills:</strong> Ability to analyze large datasets and solve complex problems effectively.</p></li>
                                    <li><p><strong>Team Collaboration and Communication:</strong> Excellent interpersonal and communication skills for effective team collaboration.</p></li>
                                    <li><p><strong>Bachelor's Degree in Computer Science or Related Field:</strong> Specialization in AI, machine learning, computer vision, or a related discipline.</p></li>
                                </ul>
                                <p><strong>Preferred Qualifications:</strong></p>
                                <ul>
                                    <li>4-10 years of industry experience in Python, OpenCV, AI, and machine learning.</li>
                                    <li>Proficiency in machine learning frameworks like TensorFlow, PyTorch.</li>
                                    <li>Experience with other programming languages (e.g., C++, Java) will be great</li>
                                    <li>Experience working in a collaborative development environment using tools such as Git, Devops etc</li>
                                    <li>Knowledge of software development best practices, including testing and version control.</li>
                                </ul>
                            </div>

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    )
}

export default Careers
