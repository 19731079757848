import React from 'react';
import { Link } from "react-router-dom";
import moment from "moment";
import Badge from "react-bootstrap/Badge";
import { LuClock3 } from "react-icons/lu";
import { IoIosArrowForward } from "react-icons/io";
import "./newscard.css";

const NewsCard = (props) => {
  
  return (
    <div className="news-card">
      <div className='cardsec1'>
        <img
          src={props.CardImg}
          className="news-img"
          alt="news-img"
        />
      </div>

      <Link to={`/newsdetails/${props.CardUrl}`} className="news-links">
        <div className='newscard-badge'>
          <h6>
            <Badge bg="secondary">{props.CardCategory}</Badge>
          </h6>
        </div>
      </Link>
      <div className='card-section'>
        <Link to={`/newsdetails/${props.CardUrl}`} className="news-links">
          <h2 className="newscard-header">{props.CardTitle}</h2>
        </Link>

        <div className='newscard-content'>
          {props.CardContent}
        </div>
      </div>
      <div className="newscard-lastline">
        <div>
          <LuClock3 size={20} />
          <span className="news-date">
            {moment(props.CardDate).format("MMMM Do YYYY")}
          </span>
        </div>
        <div>
          <Link to={`/newsdetails/${props.CardUrl}`} className="news-links">
            <p>
               Read More <IoIosArrowForward />
            </p>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NewsCard;
