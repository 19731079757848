//https://telepresenz.com/nj-tech-weekly-interviews-our-founders/
//NJ Tech Weekly interviews our Founders

import React from 'react';
import founders from './NewsDetailsAssets/founders.jpg';
import './newsDetails.css';

const NewsDetails37 = () => {
  return (
    <>
      <div className='newsDetails'>
          <h1>NJ Tech Weekly interviews our Founders</h1>
          <div className='newsDetailImg'>
               <img src={founders} alt="Founders" />
          </div>
          <p>“<a href='https://www.telepresenzdemo.com/' target='_blank'>Telepresenz </a>(Edison) has an unusual origin story.</p>
          <p>Wife and husband team Indu and Ritesh Tolia had created CARE4D (Edison), an edtech startup, in 2014, with the 
            idea of bringing immersive technology — like augmented reality (AR) and virtual reality (VR) — to education,</p>
          <p>However, during that company’s successful run — where they developed much engaging AR educational content for 
            students to use on their iPads and tablets — the founders were approached by other companies that were aware of their AR expertise.</p>
          <p>At the beginning, they were asked to help companies figure out what to do with AR glasses and to offer some basic AR training. 
            The founders were bootstrapping at the time, so they were eager to accept this work to help keep their startup afloat…”</p>
      </div>
    </>
  )
}

export default NewsDetails37;
