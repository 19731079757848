//https://telepresenz.com/2019-advancements-in-ar/
//2019 Advancements in AR

import React from 'react';
import bannerimg47 from "../News/NewsData/NewsImageAssets/NewsCardImg46.jpg";
import './newsDetails.css';

const NewsDetails47 = () => {
  return (
    <>
       <div className='newsDetails'>
             <h1>2019 Advancements in AR</h1>
             <div className='banner-Img '>
                  <img src={bannerimg47} alt="2019 Advancements in AR" />
             </div>

             <p className='subHeader'>2019 Advancements in AR</p>
             <p>As time passes, technology improves. This year we’ve seen advancements in Augmented Reality regarding processors, training, and costs. 
                With projected investments rising the worth of the AR/VR industry to <a href="https://techcrunch.com/2019/10/18/vr-ar-startup-valuations-reach-45-billion-on-paper/" target='_blank'>
                $45 billion dollars</a>, it’s becoming clear that engineers and investors are devoted to enhancing the quality and speed of this innovative software.</p>
             <p className='subHeader' >Functionality of Devices</p>
             <p>As processors improve with each passing year, all types of technologies benefit from this development. With the added enhancement of 
                computational capabilities and more efficient computer vision technologies, these aspects directly improve the science and progress of AR. 
                All this, plus higher-quality cameras will provide better-quality feedback for AR engineers and trainees. For example, RealWear’s HMT-1 
                prides itself on a completely hands-free voice-controlled user interface allowing workers to operate the tools and equipment needed for 
                the job. This 100% hands-free operating system has also made huge strides regarding cybersecurity, ensuring unbreachable systems for 
                their clients’ sensitive information.</p>
            <p className='subHeader' >Training and Development</p>
            <p>Enhanced connections and cameras allow trainees to be immersed in a collaborative learning environment. These improvements permit engineers and trainees to have 
                open communication and direction. However, this technology is not only applicable to science and engineering but also teaching. AR can significantly and 
                appropriately change the way in which the future generation will learn more effectively. </p>
            <p className='subHeader' >Lower Costs</p>
            <p>As technology continues to develop, affordable prices begin to reach enterprises of all sizes and scale. AR has always been less expensive than VR since no 
                dedicated equipment or infrastructure is required. Now, hands-on work, accompanied by AR remote direction to employees, is more accessible, affordable and 
                reliable than ever before.</p>
            <p className='subHeader'>What This Means For <a href="https://www.telepresenzdemo.com/" target='_blank'>Telepresenz®</a></p>
            <p> <a href="https://www.telepresenzdemo.com/meet-indu-tolia-creator-and-founder-of-care4d-and-now-telepresenz/" target='_blank'>Indu Tolia</a>, creator and founder of
            <a href="https://www.telepresenzdemo.com/" target='_blank'> Telepresenz®</a>, gives insights on what these advancements mean for the industry. “Augmented reality saw its 
            record growth in 2018 in terms of new projects, new companies and overall awareness. Commercial support for AR is positioned to be strong, with Fortune 
            500 companies like Microsoft, Amazon, Facebook, Apple and Google making some serious commitments. 2019 was filled with important achievements as the 
            work force understood the power of AR and saw all the pieces coming together. 2020 will be the true start of AR’s journey in becoming a life changing 
            computing platform.” – <a href="https://www.telepresenzdemo.com/meet-indu-tolia-creator-and-founder-of-care4d-and-now-telepresenz/" target='_blank'>Indu Tolia</a></p>
            
       </div>

      
    </>
  )
}

export default NewsDetails47;
