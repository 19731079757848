import React, { useState } from 'react';
import "./category.css";

const Category = (props) => {

    const categoryList = [
        "All",
        "Client Resources",
        "Customer Stories",
        "Deployment Case",
        "Downloads",
        /*"Featured",*/
        "News",
        "Power & Utilities",
        "Press Releases",
    ]

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const categoryStructure = categoryList.map((category, index) => (
        <h6
            key={index}
            onClick={() => {
                props.getSelectedCategory(category);
                scrollToTop();
            }}
            className="categories"
        >{category}</h6>
    ))

    return (
        <div className="newscategories">
            <h3>Categories</h3>
            {categoryStructure}
        </div>
    )
}

export default Category
