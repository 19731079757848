import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Swiper, SwiperSlide } from "swiper/react";
import PartnerSwiper from "../../BuildingBlocks/HorizontalSwiper/PartnerSwiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import { RiDoubleQuotesL } from "react-icons/ri";
import { RiDoubleQuotesR } from "react-icons/ri";
import VerticleCarousel from "../../BuildingBlocks/VerticleCarousel/VerticleCarousel";
import telepresenzCardVideo from "../../assets/home/videos/TP Platform Overview.mp4";
import telepresenzCardImg from "../../assets/home/telepresenzCardImg.gif"
import telepresenzCardImg1 from "../../assets/home/Transportation.jpg";
import telepresenzCardImg2 from "../../assets/home/Facility-Management.jpg";
import telepresenzCardImg3 from "../../assets/home/Energy-utility.jpg";
import telepresenzCardImg4 from "../../assets/home/manufacturing.jpg";
import telepresenzCardImg5 from "../../assets/home/healthcare.png";
import testimonyImg1 from "../../assets/home/realwearcircle-icon-460x460.png";
import testimonyImg2 from "../../assets/home/HAECO-Group-Logo.jpg";
import portfolioCardImg1 from "../../assets/home/portfolioCardImage1.png";
import portfolioCardImg2 from "../../assets/home/portfolioCardImage2.png";
import portfolioCardImg3 from "../../assets/home/portfolioCardImage3.png";
import portfolioCardImg4 from "../../assets/home/portfolioCardImage4.png";
import portfolioCardImg5 from "../../assets/home/portfolioCardIMage5.png";
import telepresenzHighlight from "../../assets/home/telepresenzKeyHighlight.jpg"
import "./Home.css";

const Home = () => {

  const customersTestimony = [
    {
      id: 1,
      image: testimonyImg1,
      name: "Realwear",
      person: "Sanjay Jhawar, Co-Founder & President",
      testimony:
        "Telepresenz is making headway into the connected worker space including winning a significant deployment of RealWear HMT-1 assisted reality devices using Telepresenz solution for an impressive end customer in South East Asia",
    },
    {
      id: 2,
      image: testimonyImg2,
      name: "HAECO Group",
      person: "Pierre Seon, On-Wing Structure Support Manager",
      testimony:
        "The Telepresenz team has been proactive and supportive in securing a successful pilot in a short time frame. Being advised and trained has been a smooth and enjoyable experience. The Telepresenz team is very collaborative and eager to receive feedback to constantly improve and innovate. The Telepresenz platform has delivered promising results, allowing us to transform towards digital remote inspections, as well as digital training and support. ",
    },
  ];

  const displaySection = (event, sectionId) => {
    let selectionList = [
      "selectItem1",
      "selectItem2",
      "selectItem3",
      "selectItem4",
      "selectItem5",
    ];

    selectionList.map((item) =>
      document.getElementById(item).classList.remove("changeColor")
    );
    event.target.classList.remove("changeColor");
    event.target.classList.add("changeColor");

    let cardId = [
      "cardSection1",
      "cardSection2",
      "cardSection3",
      "cardSection4",
      "cardSection5",
    ];

    cardId.map(
      (item) => (document.getElementById(item).style.display = "none")
    );
    document.getElementById(sectionId).style.display = "flex";
  };

  

  return (
    <>
      {/*<VerticleCarousel />*/}
      <div className="homePgVedioSecion">
         <video className="" controls autoPlay muted>
           <source src={telepresenzCardVideo} type="video/mp4"/>
         </video>
      </div>
      <div className="horizontalDivider"></div>
      <div className="homePgCardSecion">
        <div className="homePgCardSecLeft">
          <img className="cardLeftImg" src={telepresenzCardImg} alt="Key Highlights" />
        </div>
        <div className="homePgCardSecRight">
          {/*<div className="cardRtImg1"><h3>COTS</h3></div>
          <div className="cardRtImg2"><h3>Control Room</h3></div>
          <div className="cardRtImg3"><h3>Mobile & Wearables</h3></div>
          <div className="cardRtImg4"><h3>Adaptive, Streaming & secure</h3></div>*/}
          <img className="cardRtImg" src={telepresenzHighlight} alt="Platform Agnostic Approach" />
        </div>
      </div>
      <div className="horizontalDivider"></div>
      <div className="portfolioSection">
        <div className="portfolioList">
          <ul>
            <li onClick={(event) => displaySection(event, "cardSection1")}>
              <p id="selectItem1" className="changeColor">Transportation</p>
            </li>
            <li onClick={(event) => displaySection(event, "cardSection2")}>
              <span className="vertilceDivider"></span>
              <p id="selectItem2">Facility Management</p>
            </li>
            <li onClick={(event) => displaySection(event, "cardSection3")}>
              <span className="vertilceDivider"></span>
              <p id="selectItem3">Energy & Utilities</p>
            </li>
            <li onClick={(event) => displaySection(event, "cardSection4")}>
              <span className="vertilceDivider"></span>
              <p id="selectItem4">Manufacturing</p>
            </li>
            <li onClick={(event) => displaySection(event, "cardSection5")}>
              <span className="vertilceDivider"></span>
              <p id="selectItem5">Healthcare</p>
            </li>
          </ul>
        </div>

        <div id="cardSection1"className="portfolioCardSection portfolioCardSectionDisplayed">
          <div className="CardContentimg">
            <img src={telepresenzCardImg1} alt="portfolioImg"/>
          </div>
          <div className="portfolioCardContentSec">
            <div className="CardContent">
              <div className="portfolioImg">
                  
                {/*{cardContentSection1.map(card => (
                          <div key={card.id} className="">
                            <Card>
                              <Card.Body>
                                <Card.Text>{card.content}</Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                      ))}   */}  
                <img src={portfolioCardImg1} alt="Transportation" />
                
              </div>
                
            </div>
          </div>
        </div>

        <div id="cardSection2" className="portfolioCardSection">
          <div className="CardContentimg">
            <img src={telepresenzCardImg2} alt="portfolioImg"/>
          </div>
          <div className="portfolioCardContentSec">
            <div className="CardContent">
               <div className="portfolioImg">
                 <img src={portfolioCardImg2} alt="Transportation" />
              </div>
            </div>
          </div>
        </div>

        <div id="cardSection3" className="portfolioCardSection">
          <div className="CardContentimg">
            <img src={telepresenzCardImg3} alt="portfolioImg"/>
          </div>
          <div className="portfolioCardContentSec">
            <div className="CardContent">
              <div className="portfolioImg">
                   <img src={portfolioCardImg3} alt="Transportation" /> 
              </div>
            </div>
          </div>
        </div>

        <div id="cardSection4" className="portfolioCardSection">
          <div className="CardContentimg">
            <img src={telepresenzCardImg4} alt="portfolioImg"/>
          </div>
          <div className="portfolioCardContentSec">
            <div className="CardContent">
                <div className="portfolioImg">
                    <img src={portfolioCardImg4} alt="Transportation" />
                 </div>
            </div>
          </div>
        </div>

        <div id="cardSection5" className="portfolioCardSection">
          <div className="CardContentimg">
            <img src={telepresenzCardImg5} alt="portfolioImg"/>
          </div>
          <div className="portfolioCardContentSec">
            <div className="CardContent">
              <div className="portfolioImg">
                 <img src={portfolioCardImg5} alt="Transportation" />
                 </div>
            </div>
          </div>
        </div>
      </div>

      <div className="horizontalDivider"></div>
      <PartnerSwiper />
      <div className="testimonySection">
        <div className="peoplecl">
          <h1>What People Say</h1>
        </div>
      </div>

      <div className="testimonyContainer">
        <div className="testimonyBgLayer1"></div>
        <div className="testimonyBgLayer2"></div>
        <div className="testimonySwiper">
          <Swiper
            loop={true}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,
              loop: true,
            }}
            pagination={{
              clickable: true,
            }}
            speed={500}
            effect={"fade"}
            modules={[Autoplay, Pagination]}
            className="testimonySwiperSlide"
          >
            {customersTestimony.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="homeclient">
                    <img
                      src={item.image}
                      alt="testimonyImage"
                      style={{ marginBottom: "20px" }}
                      className="testimonyImg"
                    />

                    <div className="testimonyPara">
                      <RiDoubleQuotesL className="testimonyQuote quoteLeft" />
                      <p>{item.testimony}</p>
                      <RiDoubleQuotesR className="testimonyQuote quoteRight" />
                    </div>
                    <h4>{item.name}</h4>
                    <p style={{ color: "blueviolet" }}>{item.person}</p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Home;
