import BlogCardImg from "./BlogImageAssets/BlogCardImg.jpg";
import BlogCardImg1 from "./BlogImageAssets/BlogCardImg1.jpg";
import BlogCardImg2 from "./BlogImageAssets/BlogCardImg2.jpg";
import BlogCardImg3 from "./BlogImageAssets/BlogCardImg3.jpg";
import BlogCardImg4 from "./BlogImageAssets/BlogCardImg4.jpg";
import BlogCardImg5 from "./BlogImageAssets/BlogCardImg5.jpg";
import BlogCardImg6 from "./BlogImageAssets/BlogCardImg6.png";
import BlogCardImg7 from "./BlogImageAssets/BlogCardImg7.png";
import BlogCardImg8 from "./BlogImageAssets/BlogCardImg8.png";
import BlogCardImg9 from "./BlogImageAssets/BlogCardImg9.jpg";
import BlogCardImg10 from "./BlogImageAssets/BlogCardImg10.jpg";
import BlogCardImg11 from "./BlogImageAssets/BlogCardImg11.jpg";
import BlogCardImg12 from "./BlogImageAssets/BlogCardImg12.jpg";
import BlogCardImg13 from "./BlogImageAssets/BlogCardImg13.png";
import BlogCardImg14 from "./BlogImageAssets/BlogCardImg14.png";

{/*export const BlogData = [
  {
    "id": 1,
    "date": "November 27, 2023",
    "category": "Featured",
    "title": "The Significance of Personal Protective Equipment (PPE) and AI-Based Smart Operations",
    "img": BlogCardImg1 ,
    "content": "In the dynamic and ever-evolving landscape of the utilities industry, ensuring the safety of field workers is paramount. The nature of their tasks often involves working in hazardous environments, making it imperative to employ protective measures. One such indispensable tool.",
    "url": "the-significance-of-personal-protective-equipment-ppe-and-ai-based-smart-operations/",
  },
  {
    "id": 2,
    "date": "October 27, 2023",
    "category": "Featured",
    "title": "Exploring the Future of Wearable Technology: What Lies Ahead?",
    "content": "Today, technology is rapidly advancing, and wearables have emerged as an interesting frontier for innovation. Wearables, to put it briefly, are devices designed to be worn on the body, providing various functionalities that are not only personalized but also conveniently",
    "img": BlogCardImg2,
    "url": "exploring-the-future-of-wearable-technology-what-lies-ahead/",
  },
  {
    "id": 3,
    "date": "September 25, 2023",
    "category": "Featured",
    "title": "Wearables vs. Handheld Devices: A Field MRO Perspective",
    "content": "In the dynamic world of Field Maintenance, Repair, and Operations (MRO), staying connected and efficient is paramount. The choice between wearables and handheld devices has become a pivotal decision for businesses aiming to optimize their field operations. As companies persist in making significant technological investments in tablets and phones, a pertinent question arises: do these investments hinder the seamless integration of wearables into business operations? Before we even make a choice, it’s important for us to understand the requirements and various scenarios in which field maintenance and repair operations are carried.",
    "img": BlogCardImg3,
    "url": "wearables-vs-handheld-devices-a-field-mro-perspective/",
  },
  {
    "id": 4,
    "date": "August 30, 2023",
    "category": "Featured",
    "title": "Harnessing Remote Assistance and Digital Workflows for Comprehensive Digitalization",
    "content": "In the current state of digitalization strategies for field operations, industries are rapidly embracingcutting-edge technologies such as the Internet of Things (IoT), Artificial Intelligence (AI), and AugmentedReality (AR) to optimize processes and enhance productivity. Real-time data collection, predictivemaintenance, and remote ",
    "img": BlogCardImg4,
    "url": "harnessing-remote-assistance-and-digital-workflows-for-comprehensive-digitalization/",
  },
  {
    "id": 5,
    "date": "August 8, 2023",
    "category": "Featured",
    "title": "From Reactive to Proactive: Real-time Data, Analytics, and Telepresenz for Maintenance Excellence",
    "content": "In today’s rapidly advancing technological landscape, businesses are constantly seeking innovative ways to optimize their operations and improve efficiency. One such approach gaining significant traction is the utilization of real-time data and analytics, along with Telepresenz for Maintenance Excellence. By",
    "img": BlogCardImg5,
    "url": "from-reactive-to-proactive-real-time-data-analytics-and-telepresenz-for-maintenance-excellence/",
  },
  {
    "id": 6,
    "date": "June 29, 2023",
    "category": "Featured",
    "title": "Stay Safe in Hazardous Environments: 3 Tips for Assisted Reality Users",
    "content": "Assisted reality devices have revolutionized the way we interact with the world around us. It has the ability to superimpose information of the real world by allowing a person to view a screen within his immediate field of vision, hands",
    "img": BlogCardImg6,
    "url": "stay-safe-in-hazardous-environments-3-key-tips-for-assisted-reality-device-users/",
  },
  {
    "id": 7,
    "date": "May 26, 2023",
    "category": "Featured",
    "title": "Reducing Downtime and Costs: The Impact of Remote MRO on Utilities Asset Management",
    "content": "Utility companies are tasked with managing complex industrial assets that require regular maintenance to ensure they perform optimally. However, managing these assets can be a daunting task, particularly when they are spread across multiple locations. Fortunately, remote MRO solutions offer.",
    "img": BlogCardImg7,
    "url": "reducing-downtime-and-costs-the-impact-of-remote-mro-on-utilities-asset-management/",
  },
  {
    "id": 8,
    "date": "April 28, 2023",
    "category": "Featured",
    "title": "Innovations and Trends Disrupting Services & MRO",
    "content": "Recently, EthAum Venture Partners hosted a virtual thought leadership panel on the topic “Innovations Disrupting Services & MRO Industry”. The panel featured a diverse group of experts, including Indu Tolia, CEO, Telepresenz, who shared her valuable insights and trends observed",
    "img": BlogCardImg8,
    "url": "innovations-and-trends-disrupting-services-mro/",
  },
  {
    "id": 9,
    "date": "March 24, 2023",
    "category": "Featured",
    "title": "Offset Environmental Impact with Remote MRO",
    "content": "Advancement of technologies has opened numerous possibilities to support repair and maintenance operations remotely. Reduced travel and efficient use of resources can be an effective way to offset environmental impact with remote mro while still ensuring that equipment and systems",
    "img": BlogCardImg9,
    "url": "offset-environmental-impact-with-remote-mro/",
  },
  {
    "id": 10,
    "date": "February 15, 2023",
    "category": "Featured",
    "title": "Maximizing Efficiency and Safety in Mining Operations",
    "content": "Maximizing efficiency and ensuring safety in the mining industry is essential for both worker well-being and environmental protection. As a critical sector that provides raw materials for various industries, the mining industry plays a vital role in driving economic growth",
    "img": BlogCardImg10,
    "url": "maximizing-efficiency-and-safety-in-mining-operations/",
  },
] */}

export const BlogData = [

  {
    "id": 1,
    "date": "August 1, 2022",
    "category": "Featured",
    "title": "Cutting-edge MRO Tech Training In The Field",
    "content": "Download our White Paper",
    "img": BlogCardImg14,
    "url": "cutting-edge-mro-tech-training-in-the-field/",
  },

  {
    "id": 2,
    "date": "August 1, 2022",
    "category": "Featured",
    "title": "Extending Digital Twin Software to Frontline Workers",
    "content": "Telepresenz and Antea announce a strategic partnership to integrate and jointly pursue projects in the energy sector. Telepresenz’s award winning remote assistance software platform runs on hands-free wearable devices (such as Realwear™, Vuzix™, Rokid Glass™.... and others). It provides frontline workers with digital workflows and remote expert guidance.The Antea™ platform is one of the industrial market’s leading AIM (Asset Integrity Management) solutions, creating a 3D digital",
    "img": BlogCardImg13,
    "url": "extending-digital-twin-software-to-frontline-workers/",
  },

  

  {
    "id": 3,
    "date": "December 19, 2022",
    "category": "Featured",
    "title": "Telepresenz® for Seamless Rail Operations During FIFA World Cup 2022",
    "content": "We are excited to announce that Telepresenz® provides the underlying real-time support platform to manage rail operations in Doha.... ",
    "img": BlogCardImg12,
    "url": "telepresenz-for-seamless-rail-operations-during-fifa-world-cup-2022/",
  },

  {
    "id": 4,
    "date": "January 17, 2023",
    "category": "Featured",
    "title": "2023 Trends to Watch in Connected Worker Solutions",
    "content": "As we start the new year, it’s important to keep an eye on the latest trends in connected worker solutions. These technologies are becoming increasingly important for companies looking to improve the efficiency, safety, and communication of their field.... workers",
    "img": BlogCardImg11,
    "url": "2023-trends-to-watch-in-connected-worker-solutions/",
  },

  {
    "id": 5,
    "date": "February 15, 2023",
    "category": "Featured",
    "title": "Maximizing Efficiency and Safety in Mining Operations",
    "content": "Maximizing efficiency and ensuring safety in the mining industry is essential for both worker well-being and environmental protection. As a critical sector that provides raw materials for various industries, the mining industry plays a vital role in driving.... economic growth",
    "img": BlogCardImg10,
    "url": "maximizing-efficiency-and-safety-in-mining-operations/",
  },

  {
    "id": 6,
    "date": "March 24, 2023",
    "category": "Featured",
    "title": "Offset Environmental Impact with Remote MRO",
    "content": "Advancement of technologies has opened numerous possibilities to support repair and maintenance operations remotely. Reduced travel and efficient use of resources can be an effective way to offset environmental impact with remote mro while still ensuring that.... equipment and systems",
    "img": BlogCardImg9,
    "url": "offset-environmental-impact-with-remote-mro/",
  },
  {
    "id": 7,
    "date": "April 28, 2023",
    "category": "Featured",
    "title": "Innovations and Trends Disrupting Services & MRO",
    "content": "Recently, EthAum Venture Partners hosted a virtual thought leadership panel on the topic “Innovations Disrupting Services & MRO Industry”. The panel featured a diverse group of experts, including Indu Tolia, CEO, Telepresenz, who shared her valuable insights and... trends observed globally in the maintenance repair and operations industry.",
    "img": BlogCardImg8,
    "url": "innovations-and-trends-disrupting-services-mro/",
  },

  {
    "id": 8,
    "date": "May 26, 2023",
    "category": "Featured",
    "title": "Reducing Downtime and Costs: The Impact of Remote MRO on Utilities Asset Management",
    "content": "Utility companies are tasked with managing complex industrial assets that require regular maintenance to ensure they perform optimally. However, managing these assets can be a daunting task, particularly when they are spread across multiple locations.Fortunately...",
    "img": BlogCardImg7,
    "url": "reducing-downtime-and-costs-the-impact-of-remote-mro-on-utilities-asset-management/",
  },

  {
    "id": 9,
    "date": "June 29, 2023",
    "category": "Featured",
    "title": "Stay Safe in Hazardous Environments: 3 Tips for Assisted Reality Users",
    "content": "Assisted reality devices have revolutionized the way we interact with the world around us. It has the ability to superimpose information of the real world by allowing a person to view a screen within his immediate field of vision, hands..",
    "img": BlogCardImg6,
    "url": "stay-safe-in-hazardous-environments-3-tips-for-assisted-reality-users/",
  },

  {
    "id": 10,
    "date": "August 8, 2023",
    "category": "Featured",
    "title": "From Reactive to Proactive: Real-time Data, Analytics, and Telepresenz for Maintenance Excellence",
    "content": "In today’s rapidly advancing technological landscape, businesses are constantly seeking innovative ways to optimize their operations and improve efficiency. One such approach gaining.... ",
    "img": BlogCardImg5,
    "url": "from-reactive-to-proactive-real-time-data-analytics-and-telepresenz-for-maintenance-excellence/",
  },

  {
    "id": 11,
    "date": "August 30, 2023",
    "category": "Featured",
    "title": "Harnessing Remote Assistance and Digital Workflows for Comprehensive Digitalization",
    "content": "In the current state of digitalization strategies for field operations, industries are rapidly embracing cutting-edge technologies such as the Internet of Things (IoT), Artificial Intelligence (AI), and AugmentedReality (AR) to optimize processes and enhance.... productivity. Real-time data collection, predictivemaintenance, and remote ",
    "img": BlogCardImg4,
    "url": "harnessing-remote-assistance-and-digital-workflows-for-comprehensive-digitalization/",
  },
  
  {
    "id": 12,
    "date": "September 25, 2023",
    "category": "Featured",
    "title": "Wearables vs. Handheld Devices: A Field MRO Perspective",
    "content": "In the dynamic world of Field Maintenance, Repair, and Operations (MRO), staying connected and efficient is paramount. The choice between wearables and handheld devices has become a pivotal decision for businesses aiming to optimize.... their field operations. As companies persist in making significant technological investments in tablets and phones, a pertinent question arises: do these investments hinder the seamless integration of wearables into business operations? Before we even make a choice, it’s important for us to understand the requirements and various scenarios in which field maintenance and repair operations are carried.",
    "img": BlogCardImg3,
    "url": "wearables-vs-handheld-devices-a-field-mro-perspective/",
  },

  {
    "id": 13,
    "date": "October 27, 2023",
    "category": "Featured",
    "title": "Exploring the Future of Wearable Technology: What Lies Ahead?",
    "content": "Today, technology is rapidly advancing, and wearables have emerged as an interesting frontier for innovation. Wearables, to put it briefly, are devices designed to be worn on the body, providing various functionalities that are not only personalized but also.... conveniently",
    "img": BlogCardImg2,
    "url": "exploring-the-future-of-wearable-technology-what-lies-ahead/",
  },

  {
    "id": 14,
    "date": "November 27, 2023",
    "category": "Featured",
    "title": "The Significance of Personal Protective Equipment (PPE) and AI-Based Smart Operations",
    "img": BlogCardImg1 ,
    "content": "In the dynamic and ever-evolving landscape of the utilities industry, ensuring the safety of field workers is paramount. The nature of their tasks often involves working in hazardous environments, making it imperative to employ protective measures. One such indispensable.. tool.",
    "url": "the-significance-of-personal-protective-equipment-ppe-and-ai-based-smart-operations/",
  },

 {
    "id": 15,
    "date":"February 05, 2024",
    "category":"Featured",
    "title":"Application of Assisted Reality (AR) and AI in 2024",
    "img": BlogCardImg,
    "content":"We all know AR and AI have been the buzzwords in the Industrial Revolution 4.0! While Augmented reality (AR) has hogged the limelight, another lesser-known yet impactful AR, known as Assisted Reality, is emerging as a game-changer. Think of it as AR’s...  ",
    "url":"application-of-assisted-reality-ar-and-ai-in-2024/",
  },

]