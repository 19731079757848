import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import member1 from "../../assets/team/team.png"
import "./Team.css";

const Team = () => {

  const [showteam, setShowTeam] = useState('teamOne');

  const handleTabClick = (divId) =>{
      setShowTeam(divId);
  };
  return (
    <>
        <div className='teamContainer'>
           <h1>Meet Our Team </h1>

           
           
           <div className='teamBody'>

           <div className='horizontaltab'>
                        <button
                        className={`tabContent ${showteam === 'teamOne' ? 'active' : ''}`}
                        onClick={() => handleTabClick('teamOne')}>Management Team</button>
                           
                        <button
                        className={`tabContent ${showteam === 'teamTwo' ? 'active' : ''}`}
                        onClick={() => handleTabClick('teamTwo')}>Core Team</button>
                          
                        <button
                         className={`tabContent ${showteam === 'teamThree' ? 'active' : ''}`}
                           onClick={() => handleTabClick('teamThree')}>Advisory Team</button>
                        
                        <button
                         className={`tabContent ${showteam === 'teamFour' ? 'active' : ''}`}
                           onClick={() => handleTabClick('teamFour')}>Team</button>
                           
                     
           </div>
            {showteam == 'teamOne' && (
               <div className='teamOne'>
                        
               <Card>
                     <Card.Img variant='top' src={member1} alt='img1'/>
                       <Card.Body>
                           <Card.Title>Name</Card.Title>
                           <Card.Body>Designation</Card.Body>
                       </Card.Body>
               </Card>

               <Card>
                     <Card.Img variant='top' src={member1} alt='img1'/>
                       <Card.Body>
                           <Card.Title>Name</Card.Title>
                           <Card.Body>Designation</Card.Body>
                       </Card.Body>
               </Card>

               <Card>
                     <Card.Img variant='top' src={member1} alt='img1'/>
                       <Card.Body>
                           <Card.Title>Name</Card.Title>
                           <Card.Body>Designation</Card.Body>
                       </Card.Body>
               </Card>

         </div> 
            )}

            {showteam == 'teamTwo' && (
               <div className='teamTwo'>
                        
               <Card>
                     <Card.Img variant='top' src={member1} alt='img1'/>
                       <Card.Body>
                           <Card.Title>Full Name</Card.Title>
                           <Card.Body>Designation</Card.Body>
                       </Card.Body>
               </Card>

               <Card>
                     <Card.Img variant='top' src={member1} alt='img1'/>
                       <Card.Body>
                           <Card.Title>Full Name</Card.Title>
                           <Card.Body>Designation </Card.Body>
                       </Card.Body>
               </Card>

               <Card>
                     <Card.Img variant='top' src={member1} alt='img1'/>
                       <Card.Body>
                           <Card.Title>Full Name</Card.Title>
                           <Card.Body>Designation</Card.Body>
                       </Card.Body>
               </Card>

         </div> 
            )}


            {showteam == 'teamThree' && (
               <div className='teamThree'>
                        
               <Card>
                     <Card.Img variant='top' src={member1} alt='img1'/>
                       <Card.Body>
                           <Card.Title>Name</Card.Title>
                           <Card.Body>Enter Designation</Card.Body>
                       </Card.Body>
               </Card>

               <Card>
                     <Card.Img variant='top' src={member1} alt='img1'/>
                       <Card.Body>
                           <Card.Title>Name</Card.Title>
                           <Card.Body>enter Designation</Card.Body>
                       </Card.Body>
               </Card>

               <Card>
                     <Card.Img variant='top' src={member1} alt='img1'/>
                       <Card.Body>
                           <Card.Title>Name</Card.Title>
                           <Card.Body>enter Designation</Card.Body>
                       </Card.Body>
               </Card>

         </div> 
            )}  

            {showteam == 'teamFour' && (
               <div className='teamThree'>
                        
               <Card>
                     <Card.Img variant='top' src={member1} alt='img1'/>
                       <Card.Body>
                           <Card.Title>Full Name</Card.Title>
                           <Card.Body>Enter Designation</Card.Body>
                       </Card.Body>
               </Card>

               <Card>
                     <Card.Img variant='top' src={member1} alt='img1'/>
                       <Card.Body>
                           <Card.Title>Full Name</Card.Title>
                           <Card.Body>enter Designation</Card.Body>
                       </Card.Body>
               </Card>

               <Card>
                     <Card.Img variant='top' src={member1} alt='img1'/>
                       <Card.Body>
                           <Card.Title>Full Name</Card.Title>
                           <Card.Body>enter Designation</Card.Body>
                       </Card.Body>
               </Card>

         </div> 
            )}  
           </div>

          
           
           
        </div>
    </>
  )
}

export default Team
