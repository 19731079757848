//https://telepresenz.com/telepresenz-for-seamless-rail-operations-during-fifa-world-cup-2022/
//Telepresenz® for Seamless Rail Operations During FIFA World Cup 2022
import React from 'react';
import bannerimg15 from "../News/NewsData/NewsImageAssets/NewsCardImg14.jpg";
import './newsDetails.css';

const NewsDetails15 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Telepresenz® for Seamless Rail Operations During FIFA World Cup 2022</h1>
        <div className='banner-Img '>
            <img src={bannerimg15} alt="Telepresenz® for Seamless Rail Operations During FIFA World Cup 2022" />
        </div>
        <h4 className='property'>We are excited to announce that Telepresenz® provides the underlying real-time support platform to manage rail operations in Doha.</h4>
        <p>Telepresenz® is being implemented as the underlying platform to provide world-class operational support at the 2022 FIFA World Cup in Doha. Our esteemed partner 
           <a href="https://www.motherwell.net.au/" target='_blank'> Motherwell Automation </a> along with <a href="https://www.andromeda-global.com/"target='_blank'>Andromeda </a>
           Engineering has implemented the solution to monitor the performance and activity using our augmented reality-powered Connected Worker platform.</p>
        <p>FIFA World Cup is the most prestigious tournament in the world bringing millions of people together for their passion and love for the game.  FIFA Men’s World Cup™ 
           2022 is being played from 20 November to 18 December with 32 teams that will compete across 64 matches. More than 2.4 million people attended group stage matches, 
           as reported by the official authorities and more are expected for future matches. Such a huge crowd brings the real-time operational challenge for public 
           transport systems continuously operating under maximum capacity.</p>
        <p>Across three lines, and 37 stations along 75km of track, the Telepresenz® Connected Worker platform provides real-time eyes and ears to centrally located remote 
           maintenance experts to ensure the reliability and seamless management of the rail performance. Using Telepresenz® on smart wearable headsets, operational experts, 
           and Metro rail station workers quickly diagnose, inspect and resolve issues encountered, saving time and avoiding any potential downtimes.</p>
        <p>The platform enables station rail workers to receive an immediate notification of work orders issued directly through Telepresenz®, which included all the 
           necessary documentation, drawings, and videos. They also have real-time access to technical experts to get timely guidance and mentoring when required.</p>
        <h5>Key Benefits of Telepresenz®</h5>
        <p>
            <ul>
                <li>Improve the efficiency of remote field operations</li>
                <li>Improve worker safety with hand-free voice-controlled AR headsets</li>
                <li>Improve fix time fix rates and reduce downtime</li>
                <li>Empower field workers with real-time access to expertise, data, and information for diagnosing and resolving critical issues as they occur</li>
            </ul>
        </p>
        <h5>How Did Telepresenz® Empower Frontline Workers in the Doha Metro Station?</h5>
        <p>
            <ul>
                <li>Connected the rail station workers instantly and securely using any wearable device, smartphone, or tablet in the field</li>
                <li>Facilitated a more effective method to collaborate, plan and orchestrate operations no matter where they are located</li>
                <li>Connected SMEs with field teams for real-time diagnosis and issue resolution</li>
            </ul>
        </p>
        <p>To learn more about our Connected Worker Platform contact us today at <a href={'mailto:sales@telepresenz.com'}>sales@telepresenz.com</a></p>
      </div>
    </>
  )
}

export default NewsDetails15;
