import React from "react";
import { useRef, useEffect, useState } from "react";
import aboutUsWhoAreWe from "../../assets/about/WhoAreWe.jpg";
import aboutUsWhatWeDo from "../../assets/about/WhatWeDo.jpg";
import aboutUsOurVision from "../../assets/about/OurVision.jpg";
import aboutUsOurJourney from "../../assets/about/OurJourney.jpg";
import aboutUsOurProcess from "../../assets/about/DiscoveryCall.jpg"
import "./about.css";

const About = () => {

  return (
    <div className="aboutUS">

      <div className="aboutUsBanner">
      </div>

      <div className="aboutUsContainer">
        <div className="aboutUsSection">
          <div className="aboutUsLeftSection orderTwo">
            <div className="aboutUsImgContainer">
              <img src={aboutUsWhoAreWe} alt="WhoAreWeImg" />
            </div>
          </div>
          <div className="aboutUsRightSection orderOne">
            <div className="contentSection">
              <h1>Who We Are</h1>
              <h4>Telepresenz - Pioneering the Future of Technology</h4>
              <p>At Telepresenz®, we believe in a future where safety and efficiency are paramount for mobile workforces.
                  We envision a world where every frontline team has the tools they need to thrive.
              </p>
              <p>By focusing on enhancing safety and digitization, we've developed the Smart Operations product suite. 
                  Our expertise lies in harnessing cutting-edge technologies to create intuitive, intelligent systems. 
                  These systems not only understand and predict user needs but also provide businesses and individuals with 
                  valuable, data-driven insights.</p>
              <p>Telepresenz® is a software solutions company that offers an off-the-shelf Smart Operations product suite. 
                 Our mission is clear: to empower frontline teams with instant access to data, information, and expertise 
                 at their fingertips. With Telepresenz, we're not just revolutionizing operations; we're ensuring better
                 decision-making while prioritizing the safety of those on the front lines.</p>
            </div>
          </div>
        </div>
        <div className="aboutUsSection">
          <div className="aboutUsLeftSection">
            <div className="contentSection">
              <h1>Our Vision</h1>
              <h4>Empowering Change, Enriching Lives</h4>
              <p>
                <ul>
                    <li>Our vision is to harness the power of AR and AI to create transformative experiences 
                        that enrich lives and empower businesses worldwide.</li>
                    <li> We believe in technology as a force for positive change, enabling smarter decisions, 
                        enhancing human capabilities, and opening new possibilities.</li>
                    <li>We believe in offering end to end solution where by its is able to solve some real world 
                        problems by harnessing the Nextgen technologies to create a value add to the ecosystem.</li>
  
                </ul>
              </p>
            </div>
          </div>
          <div className="aboutUsRightSection">
            <div className="aboutUsImgContainer">
              <img src={aboutUsOurVision} alt="OurVisionImg" />
            </div>
          </div>
        </div>
        <div className="aboutUsSection">
          <div className="aboutUsLeftSection orderTwo">
            <div className="aboutUsImgContainer">
              <img src={aboutUsWhatWeDo} alt="WhatWeDoImg" />
            </div>
          </div>
          <div className="aboutUsRightSection orderOne">
            <div className="contentSection">
              <h1>What We Do</h1>
              <h4>Creating Seamless, Intelligent Interactions</h4>
              <p> At Telepresenz®, we provide an off-the-shelf Smart Operations SaaS platform along with customized solutions 
                  to drive our customers' digital transformation initiatives, delivering direct return on investment. Through 
                  objective assessments and close collaboration with business owners, we ensure the implementation of value-driven 
                  solutions. Our focus is on delivering modular and scalable digital transformation capabilities.</p>
              <p>Wherever you are in your process, Telepresenz® can seamlessly integrate, co-opt, automate, and elevate your operations. 
                 Our core value as a company is to partner with customers in developing and deploying solutions that bring meaningful 
                 and sustainable business impact.</p>
            </div>
          </div>
        </div>
        <div className="aboutUsSection">
          <div className= "aboutUsLeftSection">
            <div className="contentSection">
              <h1>Our Process</h1>
              <p>
                <ul>
                   <li><b>Discovery Call</b>: We work with a customer innovation leader to identify a business unit to run the pilot.</li>
                    <li><b>Tailored Demo</b>: Operational needs, constraints and safety requirements – identify success metrics.</li>
                    <li><b>Consulting</b>: Connected worker, proof of value, IT and Connectivity, Visionary</li>
                    <li><b>Proof of Value</b>: Run pilot for the prescribed time, supporting your team as needed.</li>
                    <li><b>Support</b>: SLA based for critical operations, global coverage, procurement and release</li>
                    <li><b>Solution Deployment</b>: We quickly train your team on platform use and co-develop digital workflows.</li>
                    <li><b>Training</b>: End user training dedicated hardware and software support</li>
                    <li><b>Scale Expansion</b>: Discuss efficacy and feasibility of a larger rollout Customizations & Integrations</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="aboutUsRightSection">
            <div className="aboutUsImgContainer">
              <img src={aboutUsOurProcess} alt="OurJourneyImg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
