//https://telepresenz.com/amgc-webinar-summary/
// AMGC Webinar Summary

import React from 'react';
import ReactPlayer from 'react-player';
import bannerimg30 from "../News/NewsData/NewsImageAssets/NewsCardImg29.png";
import './newsDetails.css';

const NewsDetails30 = () => {

  const videoUrl='https://player.vimeo.com/video/640088896?h=f68c7b06f1&dnt=1&app_id=122963'
  return (
    <>
      <div className='newsDetails'>
        <h1>AMGC Webinar Summary</h1>
        <div className='banner-Img '>
            <img src={bannerimg30} alt="Remote Assistance Webinar" />
        </div>
        <div className='playerWrapper'>
             <ReactPlayer  url={videoUrl} controls={true} />
        </div>
      </div>
    </>
  )
}

export default NewsDetails30;
