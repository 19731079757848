import React from "react";
import Card from "react-bootstrap/Card";
import "./CardBlock.css";
import { contents } from "./CardContent";

const CardBlock = () => {

  function handleMouseOver(num) {
    document
      .getElementById(`header${num}`)
      .classList.add("addUnderLineEffect");
  }

  function handleMouseOut(num) {
    document
      .getElementById(`header${num}`)
      .classList.remove("addUnderLineEffect");
  }

  return (
    <div className="CardSection">
      {contents.map((content, index) => ( 
          <Card key={index}
          onMouseOver={() => handleMouseOver(`${index}`)}
          onMouseOut={() => handleMouseOut(`${index}`)}
        >
          <Card.Img variant="top" src={content.imageURL} />
          <Card.Body>
            <Card.Title>
              <h3 id={`header${index}`} className="">{content.header}</h3>
            </Card.Title>
            <ul>
            {content.description.map((item, index) => <li key={index}>{item}</li>)}
            </ul>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default CardBlock;