//https://telepresenz.com/emergency-response-pdf/
//Emergency Response PDF
import React from 'react';
import emergencyResponse from './NewsDetailsAssets/emergency-response-1.png';
import emergency_Response from './NewsDetailsAssets/emergency-response-2.png';
import bannerimg16 from "../News/NewsData/NewsImageAssets/NewsCardImg15.png";
import './newsDetails.css';

const NewsDetails16 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Emergency Response PDF</h1>
        <h2>Request our Emergency Response PDF</h2>
        <div className='flexContainer'>
            <div className='flexItemRight'>
                <img src={emergencyResponse} alt="Emergrncy Response" />
            </div>
            <div className='flexItemLeft'>
                <img src={emergency_Response} alt="Emergency Response" />
            </div>
        </div>

      </div>
    </>
  )
}

export default NewsDetails16;
