
//https://telepresenz.com/haeco-group-deploys-ar-technology-to-enhance-maintenance-operation-and-inspection/
//HAECO Group deploys AR technology to enhance maintenance operation and inspection
import React from 'react';
import bannerimg10 from "../News/NewsData/NewsImageAssets/NewsCardImg9.jpg";
import enggExpert from './NewsDetailsAssets/engg_expert.jpg';
import './newsDetails.css';

const NewsDetails10 = () => {

    const videoUrl='https://telepresenz.com/wp-content/uploads/2023/04/Haeco-Case-Study-Video.mp4'
  return (
    <>
       <div className='newsDetails'>
        <h1>HAECO Group deploys AR technology to enhance maintenance operation and inspection</h1>
        <div className='banner-Img'>
            <img src={bannerimg10} alt="AR technology" />
        </div>
        <p><a href="http://www.haeco.com/" target='_blank'>HAECO Group</a>, one of the world’s leading aviation solutions provider, has begun deploying augmented reality (AR) technology from Telepresenz® 
           to enhance the efficiency and quality of its maintenance operation and inspection. Currently in use by HAECO Hong Kong and HAECO Landing Gear 
           Services in Xiamen, the new tool provides a one-stop solution for frontline technicians to visualize and process real-time actionable 
           information with the original equipment manufacturer and the engineering team, enabling critical decisions to be made safely and timely,
           and at the actual point of workplace.</p>
        <p>Telepresenz® specializes in utilizing augmented reality smart glasses to connect technicians with engineering experts in the office for real-time 
           collaboration. The platform facilitates the transfer of expert knowledge and provides remote assistance during structural and component inspections, 
           enabling technicians to work more effectively and efficiently. Additionally, it enhances HAECO’s capability to train new employees.</p>
        <p>“Telepresenz® has been a solid partner in the past months to guide us as we discover the potential of remote technologies to increase safety, quality, 
            performance and customer value. Our collaboration allows us to become more proficient with new technologies and to revisit our ways of working, as 
            well as our ways to deliver customer service,” said Kei Ning, Head of On-Wing Structure Support, HAECO Hong Kong.</p>
        <div className='newsDetailImg'>
            <img src={enggExpert} alt="Engineering Expert providing technical instruction" />
        </div>
        <p>“We have extended Telepresenz® technology and know-how from our sister company HAECO Hong Kong and introduced the remote inspection technology at HAECO 
            Landing Gear Services in Xiamen. The added value of AR technology acknowledged by our team and recognised by our customers and various authorities. 
            The technology helped achieve some significant cost savings and improved customer experience. Special thanks to Telepresenz® for their efficient 
            support in helping us to successfully deploy the platform and connected goggles to our business,” said Christian Pinter, Director & General Manager, 
            HAECO Landing Gear Services.</p>
        <p>“Our top priority is being able to support our customers with their needs and evolving challenges so they may remain leaders in their industries,” 
            said Indu Tolia, Chief Executive Officer of Telepresenz®. “We are honored to be picked as the solution of choice by HAECO for connecting their 
            teams and optimizing their inspections and look forward to extending our services to their facilities worldwide.”</p>
        <div className='videoContainer'>
            <video className='videoProperty' controls>
                <source src={videoUrl} type='video/mp4'/>
                Browser does not support video tag
            </video>
        </div>
        <br />
        <p>For more information, please contact:</p>
        <h6>HAECO Group</h6>
        <p>Email: <a href={'mailto:corpcomm@haeco.com'}>corpcomm@haeco.com</a></p>
        <h6>Telepresenz</h6>
        <p>Email: <a href={'mailto:jella@telepresenz.com'}>jella@telepresenz.com</a></p>
       </div>
    </>
  )
}

export default NewsDetails10;
