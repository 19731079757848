import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
/*import { NewsDetailsDataPage1 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage1";
import { NewsDetailsDataPage2 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage2";
import { NewsDetailsDataPage3 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage3";
import { NewsDetailsDataPage4 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage4";
import { NewsDetailsDataPage5 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage5";
import { NewsDetailsDataPage6 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage6";
import { NewsDetailsDataPage7 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage7";
import { NewsDetailsDataPage8 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage8";
import { NewsDetailsDataPage9 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage9";
import { NewsDetailsDataPage10 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage10";
import { NewsDetailsDataPage11 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage11";
import { NewsDetailsDataPage12 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage12";
import { NewsDetailsDataPage13 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage13";
import { NewsDetailsDataPage14 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage14";
import { NewsDetailsDataPage15 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage15";
import { NewsDetailsDataPage16 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage16";
import { NewsDetailsDataPage17 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage17";
import { NewsDetailsDataPage18 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage18";
import { NewsDetailsDataPage19 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage19";
import { NewsDetailsDataPage20 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage20";
import { NewsDetailsDataPage21 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage21";
import { NewsDetailsDataPage22 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage22";
import { NewsDetailsDataPage23 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage23";
import { NewsDetailsDataPage24 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage24";
import { NewsDetailsDataPage25 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage25";
import { NewsDetailsDataPage26 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage26";
import { NewsDetailsDataPage27 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage27";
import { NewsDetailsDataPage28 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage28";
import { NewsDetailsDataPage29 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage29";
import { NewsDetailsDataPage30 } from "../../NewsData/NewsDetailsData/NewsDetailsDataPage30";*/
import NewsDetails01 from '../../../NewsDetailsPages/NewsDetails01';
import NewsDetails02 from '../../../NewsDetailsPages/NewsDetails02';
import NewsDetails03 from '../../../NewsDetailsPages/NewsDetails03';
import NewsDetails04 from '../../../NewsDetailsPages/NewsDetails04';
import NewsDetails05 from '../../../NewsDetailsPages/NewsDetails05';
import NewsDetails06 from '../../../NewsDetailsPages/NewsDetails06';
import NewsDetails07 from '../../../NewsDetailsPages/NewsDetails07';
import NewsDetails08 from '../../../NewsDetailsPages/NewsDetails08';
import Newsdetails09 from '../../../NewsDetailsPages/Newsdetails09';
import NewsDetails10 from '../../../NewsDetailsPages/NewsDetails10';
import NewsDetails11 from '../../../NewsDetailsPages/NewsDetails11';
import NewsDetails12 from '../../../NewsDetailsPages/NewsDetails12';
import NewsDetails13 from '../../../NewsDetailsPages/NewsDetails13';
import NewsDetails14 from '../../../NewsDetailsPages/NewsDetails14';
import NewsDetails15 from '../../../NewsDetailsPages/NewsDetails15';
import NewsDetails16 from '../../../NewsDetailsPages/NewsDetails16';
import NewsDetails17 from '../../../NewsDetailsPages/NewsDetails17';
import NewsDetails18 from '../../../NewsDetailsPages/NewsDetails18';
import NewsDetails19 from '../../../NewsDetailsPages/NewsDetails19';
import NewsDetails20 from '../../../NewsDetailsPages/NewsDetails20';
import NewsDetails21 from '../../../NewsDetailsPages/NewsDetails21';
import NewsDetails22 from '../../../NewsDetailsPages/NewsDetails22';
import NewsDetails23 from '../../../NewsDetailsPages/NewsDetails23';
import NewsDetails24 from '../../../NewsDetailsPages/NewsDetails24';
import NewsDetails25 from '../../../NewsDetailsPages/NewsDetails25';
import NewsDetails26 from '../../../NewsDetailsPages/NewsDetails26';
import NewsDetails27 from '../../../NewsDetailsPages/NewsDetails27';
import NewsDetails28 from '../../../NewsDetailsPages/NewsDetails28';
import NewsDetails29 from '../../../NewsDetailsPages/NewsDetails29';
import NewsDetails30 from '../../../NewsDetailsPages/NewsDetails30';
import NewsDetails31 from '../../../NewsDetailsPages/NewsDetails31';
import NewsDetails32 from '../../../NewsDetailsPages/NewsDetails32';
import NewsDetails33 from '../../../NewsDetailsPages/NewsDetails33';
import NewsDetails34 from '../../../NewsDetailsPages/NewsDetails34';
import NewsDetails35 from '../../../NewsDetailsPages/NewsDetails35';
import NewsDetails36 from '../../../NewsDetailsPages/NewsDetails36';
import NewsDetails37 from '../../../NewsDetailsPages/NewsDetails37';
import NewsDetails38 from '../../../NewsDetailsPages/NewsDetails38';
import NewsDetails39 from '../../../NewsDetailsPages/NewsDetails39';
import NewsDetails40 from '../../../NewsDetailsPages/NewsDetails40';
import NewsDetails41 from '../../../NewsDetailsPages/NewsDetails41';
import NewsDetails42 from '../../../NewsDetailsPages/NewsDetails42';
import NewsDetails43 from '../../../NewsDetailsPages/NewsDetails43';
import NewsDetails44 from '../../../NewsDetailsPages/NewsDetails44';
import NewsDetails45 from '../../../NewsDetailsPages/NewsDetails45';
import NewsDetails46 from '../../../NewsDetailsPages/NewsDetails46';
import NewsDetails47 from '../../../NewsDetailsPages/NewsDetails47';
import NewsDetails48 from '../../../NewsDetailsPages/NewsDetails48';




import "./newsdetails.css";

const NewsDetails = (props) => {
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => { setPageNumber(props.pageNumber) }, [props.pageNumber]);

    const pageList = [
        NewsDetails01,
        NewsDetails02,
        NewsDetails03,
        NewsDetails04,
        NewsDetails05,
        NewsDetails06,
        NewsDetails07,
        NewsDetails08,
        Newsdetails09,
        NewsDetails10,
        NewsDetails11,
        NewsDetails12,
        NewsDetails13,
        NewsDetails14,
        NewsDetails15,
        NewsDetails16,
        NewsDetails17,
        NewsDetails18,
        NewsDetails19,
        NewsDetails20,
        NewsDetails21,
        NewsDetails22,
        NewsDetails23,
        NewsDetails24,
        NewsDetails25,
        NewsDetails26,
        NewsDetails27,
        NewsDetails28,
        NewsDetails29,
        NewsDetails30,
        NewsDetails31,
        NewsDetails32,
        NewsDetails33,
        NewsDetails34,
        NewsDetails35,
        NewsDetails36,
        NewsDetails37,
        NewsDetails38,
        NewsDetails39,
        NewsDetails40,
        NewsDetails41,
        NewsDetails42,
        NewsDetails43,
        NewsDetails44,
        NewsDetails45,
        NewsDetails46,
        NewsDetails47,
        NewsDetails48,
    ]
    

    {/*const pageList = [
        NewsDetailsDataPage1,
        NewsDetailsDataPage2,
        NewsDetailsDataPage3,
        NewsDetailsDataPage4,
        NewsDetailsDataPage5,
        NewsDetailsDataPage6,
        NewsDetailsDataPage7,
        NewsDetailsDataPage8,
        NewsDetailsDataPage9,
        NewsDetailsDataPage10,
        NewsDetailsDataPage11,
        NewsDetailsDataPage12,
        NewsDetailsDataPage13,
        NewsDetailsDataPage14,
        NewsDetailsDataPage15,
        NewsDetailsDataPage16,
        NewsDetailsDataPage17,
        NewsDetailsDataPage18,
        NewsDetailsDataPage19,
        NewsDetailsDataPage20,
        NewsDetailsDataPage21,
        NewsDetailsDataPage22,
        NewsDetailsDataPage23,
        NewsDetailsDataPage24,
        NewsDetailsDataPage25,
        NewsDetailsDataPage26,
        NewsDetailsDataPage27,
        NewsDetailsDataPage28,
        NewsDetailsDataPage29,
        NewsDetailsDataPage30,
    ]*/}

    const PageComponent =pageList[pageNumber];
    return(
        <>
      {/* Render the corresponding page component */}
         <PageComponent />
    </>
    )

    /*return (
        <div className='newsdetails-container'>
            <div className='newsdeatils-body'>
                {pageList[pageNumber].map((item, index) => (
                    <>
                        {item.h1 && <h1>{item.h1}</h1>}
                        {item.h2 && <h2>{item.h2}</h2>}
                        {item.h3 && <h3>{item.h3}</h3>}
                        {item.h4 && <h4>{item.h4}</h4>}
                        {item.h5 && <h5>{item.h5}</h5>}
                        {item.h6 && <h6>{item.h6}</h6>}
                        {item.p && <p>{item.p}</p>}
                        {item.strong && <p><strong>{item.strong}</strong></p>}
                        {item.link && <Link>{item.link}</Link>}
                        {item.ul && <ul>{item.ul.map((listItem, index) => (<li>{listItem}</li>))}</ul>}
                        {item.ol && <ol>{item.ol.map((listItem, index) => (<li>{listItem}</li>))}</ol>}
                        {item.bannerimg && <div className="banner-img"><img src={item.bannerimg} alt='banner-img'></img></div>}
                        {item.img && <div className={`div${index}`}><img src={item.img} alt='newsDetailsImage'></img></div>}
                    </>
                ))}
            </div>
        </div>
    )*/
}

export default NewsDetails
