import { Route, Routes } from "react-router-dom";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Services from './Pages/Services/Services';
import Privacy from './Pages/Privacy/Privacy';
import Products from "./Pages/Products/Products";
import News from "./Pages/News/News.jsx";
import Blog from './Pages/Blog/Blog';
import Contact from './Pages/Contact/Contact';
import Careers from "./Pages/Careers/Careers.jsx";
import ScrollToTop from "./BuildingBlocks/ScrollToTop";
import NewsDetails from "./Pages/News/components/NewsDetails/NewsDetails.jsx";
import Team from "./Pages/Team/Team.jsx";
import './App.css';

function App() {

  const pageUrlList = [
    "application-of-assisted-reality-ar-and-ai-in-2024/",
    "the-significance-of-personal-protective-equipment-ppe-and-ai-based-smart-operations/",
    "exploring-the-future-of-wearable-technology-what-lies-ahead",
    "wearables-vs-handheld-devices-a-field-mro-perspective",
    "harnessing-remote-assistance-and-digital-workflows-for-comprehensive-digitalization",
    "from-reactive-to-proactive-real-time-data-analytics-and-telepresenz-for-maintenance-excellence",
    "stay-safe-in-hazardous-environments-3-tips-for-assisted-reality-users/",
    "reducing-downtime-and-costs-the-impact-of-remote-mro-on-utilities-asset-management",
    "innovations-and-trends-disrupting-services-mro",
    "haeco-group-deploys-ar-technology-to-enhance-maintenance-operation-and-inspection",
    "offset-environmental-impact-with-remote-mro",
    "maximizing-efficiency-and-safety-in-mining-operations",
    "2023-trends-to-watch-in-connected-worker-solutions",
    "telepresenz-selected-as-one-of-top-12-ventures-in-ltp-startup-challenge-for-mro-solutions-in-aviation-industry",
    "telepresenz-for-seamless-rail-operations-during-fifa-world-cup-2022",
    "emergency-response-pdf",
    "cbt-partners-with-telepresenz-to-expand-connected-worker-capabilities",
    "softwareone-hong-kong-teams-up-with-telepresenz",
    "how-to-determine-if-wearables-are-right-for-your-workforce",
    "operations-enablement-and-remote-support-software-for-vuzix-smart-glasses",
    "extending-digital-twin-software-to-frontline-workers/",
    "moziware-teams-up-with-telepresenz-to-launch-new-pocket-connected-worker",
    "cutting-edge-mro-tech-training-in-the-field/",
    "moziware-and-telepresenz-team-up-to-launch-a-new-pocket-connected-worker",
    "remote-digital-operations-monitoring-and-training-capabilities-white-paper",
    "telepresenz-partners-with-antea-to-extend-digital-twin-to-the-frontline-worker",
    "asset-integrity-management-and-remote-worker-white-paper",
    "automated-industrial-technologies",
    "telepresenz-earns-vendor-of-the-year-award-at-the-2021-ingram-micro-experience-event",
    "amgc-webinar-summary",
    "industrial-wearables",
    "successful-deployment-utilities/",
    "remote-assist-and-inspection-use-cases/",
    "telepresenz-experiences-global-hyper-growth-delivering-industrial-digital-transformation-for-the-connected-worker-through-smart-glass-technology/",
    "industrial-wearable-guide/",
    "telepresenz-platform-capabilities/",
    "nj-tech-weekly-interviews-our-founders/",
    "free-real-time-remote-assistance-and-workflows-with-every-device-you-purchase/",
    "vuzix-expands-hipaa-compliant-healthcare-offerings-with-the-addition-of-telepresenz-from-care4d-for-the-m400-smart-glasses/",
    "utilizing-remote-assistance-software-to-get-more-done/",
    "termsconditions/",
    "how-remote-assistance-is-helping-companies-in-times-of-covid/",
    "how-remote-mentor-solutions-can-help-in-times-of-covid-19/",
    "press-release-apr-14-2020/",
    "elevator-service-and-remote-mentor/",
    "realwear-ready-partner-spotlight-care4d/",
    "2019-advancements-in-ar/",
    "meet-indu-tolia-creator-and-founder-of-care4d-and-now-telepresenz/",
  ]
 
  return (
    <div className="App">
      <Header />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/products" element={<Products />} />
          <Route path="/news" element={<News />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/career" element={<Careers />} />
          <Route path="/team"   element={<Team/>} />
          {/* <Route path="/newsdetails/the-significance-of-personal-protective-equipment-ppe-and-ai-based-smart-operations/" element={<NewsDetails pageNumber="1"/>} /> */}
          {
          pageUrlList.map((item, index) =>
            <>
              key={index}
              <Route path={`/newsdetails/${item}/`} element={<NewsDetails pageNumber={index} />} />
            </>
          )}
        </Routes>
      </ScrollToTop>
      <Footer />
    </div>
  );
}

export default App;