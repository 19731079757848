//https://telepresenz.com/free-real-time-remote-assistance-and-workflows-with-every-device-you-purchase/
//Free Real-time remote assistance and workflows with every device you purchase

import React from 'react';
import bannerimg38 from "../News/NewsData/NewsImageAssets/NewsCardImg37.jpg";
import freeAssistance from './NewsDetailsAssets/free-remote-assistance.png';
import './newsDetails.css';

const NewsDetails38 = () => {

    const handleClick = () => {
      
    };
    
  return (
    <>
      <div className='newsDetails'>
        <h1>Free Real-time remote assistance and workflows with every device you purchase</h1>
        <div className='banner-Img '>
            <img src={bannerimg38} alt="Real-time remote assistance" />
        </div>
        <p>Industrial enterprise customers everywhere are discovering a unique way to manage business continuity, improve service levels, 
            train employees, improve compliance, and reduce costs. CARE4D and Ingram Micro Inc., a leading global technology distributor, 
            are now offering channel partners and their customers a free trial of Telepresenz® – a remote assistance and workflow management 
            software with every purchase of the Realwear HMT-1 or HMT-1Z1 head mounted device. As part of the new offering, channel partners 
            and their customers are able to trial the full suite of Telepresenz software and assess for themselves the benefits of embarking 
            on their digital transformation journey!</p>
        <p>“With the move to work from home and the staying power of work from anywhere, businesses everywhere are rethinking how they work, 
            stay connected and manage their workforce remotely,” says Eric Hembree, U.S. Director of IoT for Ingram Micro. “By offering a free 
            trial of Telepresenz with Realwear’s head mounted devices we are hoping to remove any barriers to entry for companies considering 
            remote assistance and workflow management solutions.”</p>
        <h4>Connecting Remote Field Teams Has Never Been So Easy</h4>
        <p>Telepresenz® facilitates a connected workforce for enterprise companies with field teams. A convenient, user-friendly system gives 
            your stakeholders instant access to the support they need for all their mission-critical functions, no matter where they’re located.</p>
        <p>Experts can also connect to multiple team members at once, a convenient and time-saving function, especially for mission-critical 
           scenarios. The capability of having one SME monitor and mentor a group of field operators lends itself to various use cases, such as 
           installation, commissioning, and training. Telepresenz®,a highly secure streaming platform is fully encrypted and satisfies data 
           privacy frameworks, reducing risk while increasing value from the factory floor to the field. Better yet, following a SaaS model, Telepresenz® offers a
           <a href="https://www.telepresenzdemo.com/pricing/" target='_blank'> predictable monthly cost</a> for access to the expertise you need, anytime you need it, 
           providing a cost-effective solution for all your training, workflow automation and troubleshooting needs.</p>
        <div className='cardImg'>
            <img src={freeAssistance} alt="Free Real-time remote assistance" />
        </div>
        <h4>Who Is Telepresenz® For?</h4>
        <p>Telepresenz® connects experts to field users wherever they might be working, either for real-time support or to assign/monitor tasks. 
            The expert uses a computer or mobile device to connect from the command center, while the field user enjoys hands-free connection 
            through the Telepresenz® application on the Realwear HMT-1 or HMT1-Z1 devices. Once connected, the expert can then walk the field 
            user through a process, provide information or documentation to support their task, or monitor what they’re doing in real-time. 
            It’s a seamless experience that makes more sense than ever, especially in the post-COVID world.</p>
        <p>Telepresenz® has a wide range of industry use-cases, including:</p>
        <p>
            <ul>
                <li> Manufacturing</li><br />
                <li>Assembly Plants</li><br />
                <li>Field Service Technicians</li><br />
                <li>Healthcare</li><br />
                <li>Maintenance and Repair</li><br />
                <li>Remote Inspection</li><br />
                <li>Training</li>
            </ul>
        </p>
        <h4><i>Made for The Way You Work</i></h4>
        <p>Telepresenz® is delivered via Realwear HMT-1 and HMT1-Z1 devices, a voice-controlled head-mounted device. They are easy to use and comfortable 
            to wear and won’t get in the way, even in the most challenging environments. The HMT1-Z1 device, in particular, is useful for enterprises 
            working either in sterile or hazardous environments. It’s designed to mount easily on standard hard helmets and to work with bump caps and safety glasses.</p>
        <h4><i>Out of the box, and ready to go in 5 minutes </i></h4>
        <p>Available to purchase from Ingram Micro, the Telepresenz® solution on the Realwear HMT1 and HMT-1Z1 devices are simple to use right out of the box; 
           within minutes, channel partners will have their customers up and running. At the time of purchasing a Realwear HMT1 or HMT-1Z1 device a customer 
           opts to trial Telepresenz®, the device will automatically download and install the software when unpacked and connected to the internet. 
           Each device is provided a unique QR code to log in and start collaborating on this industry-leading remote assistance platform.</p>
        <h4><i>Trial the solution for free before you decide</i></h4>
        <p>Offered exclusively to Ingram Micro channel partners, this free trial provides a comprehensive view of the Telepresenz® remote assistance 
            technology and its capabilities! Rather than researching various software platforms, Ingram Micro channel partners can have their customers 
            the new technology for free before purchasing the platform! An integrated workflow provides the added benefit for customers looking to 
            streamline processes, manage tasks, and even control inspections/audits remotely.</p>
        <p>Please contact <a href={'mailto:US.IoT@Ingrammicro.com'}>US.IoT@Ingrammicro.com </a> to get a 10-day free demo today.</p>
        <br />
        <p>For key capabilities of the Telepresenz® platform:</p>
        <div > 
            <a href="https://telepresenz.com/telepresenz-platform-capabilities/" target='_blank'>
                <button className='buttonPrimary'>Download the Telepresenz® Platform Capabilities Sheet</button></a>
        </div>
        <br /><br />
        <p>For more information on RealWear® Wearables:</p>
        <div>
            <a href="https://telepresenz.com/realwear-wearable-guide/" target='_blank'>
                <button className='buttonSecondary'>Download the RealWear® Guide</button></a>
        </div>



      </div>
    </>
  )
}

export default NewsDetails38;
