//https://telepresenz.com/remote-assist-and-inspection-use-cases-at-eea-conference/
//Remote Assist and Inspection Use Cases

import React from 'react';
import remoteAssistInspection from './NewsDetailsAssets/remote-assist-and-inspection.png';
import './newsDetails.css';

const NewsDetails33 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Remote Assist and Inspection Use Cases</h1>
        <h3>Request our Remote Assist and Inspection Use Cases at EEA Conference</h3>
        <p>At the recent EEA 2021 Conference held in Wellington, New Zealand, Telepresenz joined the HV Power, Gridworks, and CSL stand and demonstrated their 
           advanced collaboration, remote assistance, and inspection solutions featuring Realwear HMT’s. Many electrical distribution and field services 
           companies attended and took the opportunity to check out the solutions.</p>
        <div className='newsDetailImg'> 
            <img src={remoteAssistInspection} alt="Remote Assist and Inspection Use Cases" />
        </div>
        
      </div>
    </>
  )
}

export default NewsDetails33;
