//https://telepresenz.com/telepresenz-white-paper/
//Remote digital operations, monitoring, and training capabilities White Paper
import React from 'react';
import remoteDigitalOperatn from './NewsDetailsAssets/remote-digital-operatn.jpg';
import remoteDigitalMonitor from './NewsDetailsAssets/remote-digital-monitor.jpg';

const NewsDetails25 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Request our Remote Digital Operations, Monitoring, and Training Capabilities White Paper</h1>
        <div className='flexContainer'>
            <div className='flexItemRight'>
                <img src={remoteDigitalOperatn} alt="Emergrncy Response" />
            </div>
            <div className='flexItemLeft'>
                <img src={remoteDigitalMonitor} alt="Emergency Response" />
            </div>
        </div>
      </div>
    </>
  )
}

export default NewsDetails25;
