//https://telepresenz.com/how-remote-mentor-solutions-can-help-in-times-of-covid-19/
//How Remote Mentor Solutions Can Help in Times of COVID-19

import React from 'react';
import bannerimg43 from "../News/NewsData/NewsImageAssets/NewsCardImg42.jpg";
import './newsDetails.css';


const NewsDetails43 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>How Remote Mentor Solutions Can Help in Times of COVID-19</h1>
        <div className='banner-Img '>
            <img src={bannerimg43} alt="Remote Mentor Solutions Can Help in Times of COVID-19" />
        </div>
        <p>Unprecedented times call for novel solutions companies can implement to continue running and adapting to a rapidly changing world. 
            Adversities such as restrictions in the number of employees allowed on job sites, delayed production, altered manufacturing, and 
            limited servicing can be detrimental, not only to the company but to society as a whole. Manufacturers are halting the production 
            of their own products overnight in order to produce PPE, sanitizers, and various other medical devices necessary for saving lives.  </p>
        <p className='subHeader'>Who We Are</p>
        <p>Telepresenz® is an augmented reality telepresence solution that connects remote users to mentors, experts, or supervisors in their field, 
            enabling remote learning on a scale that has never before been imagined. The Telepresenz® platform enables real-time, two-way communication 
            between a base station expert and remote users who could be in any location, anywhere in the world.</p>
        <p className='subHeader'>The Possibilities </p>
        <p >The ability to provide cutting-edge training to doctors, engineers, and any other highly specialized segment without restrictions on borders, 
            travel or finance. Telepresenz® enables a contactless solution by connecting remote experts with in-field engineers. </p>
        <p>Telepresenz® can replace or enhance your existing training systems, allowing your team to interact with top talent 
            in their field in real-time, using techniques and technologies found in augmented and mixed reality innovations.</p>
        <p className='subHeader'>Using Remote Mentor in Manufacturing</p>
        <p>Over 30% of Telepresenz® users reported an increase in efficiency and improved first-time fix rates in 2019, leading to a 
            90% increase in the accuracy of goods added to orders. </p>
        <p>
            <ul>
                <li>32% more productive workforce</li>
                <li>30% higher quality output</li>
                <li> 20% greater utilization</li>
            </ul>
        </p>
        <p className='subHeader'>Telepresenz Offers a Free Trial Period</p>
        <p>“The world has changed drastically with the COVID-19 pandemic, and we’re all going to have to adapt to new ways of keeping people safe while 
            trying to maintain continuity in our lives. CARE4D pledges to support businesses and communities that are affected, and help in any way we can.” 
            Indu Tolia, CEO of Telepresenz®</p>
        <p>Details on the free trial:
            <ul>
                <li>Valid for new customers affected by the COVID-19</li>
                <li>60 days trial period</li>
                <li>5 free licenses until June 2020</li>
            </ul>
        </p>
        <p>To sign up for a demo and 2-month free trial click <a href="https://www.telepresenzdemo.com/covid-19/" target='_blank'>here</a>.</p>
      </div>
    </>
  )
}

export default NewsDetails43;
