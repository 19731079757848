export const nameValidator = (name) => {
  if (!name) {
    return "Name is required";
  } else if (!new RegExp(/^[A-Za-z ]+$/.test(name))) {
    return "Incorrect name format";
  }
  return null;
};

export const emailValidator = (email) => {
  if (!email) {
    return "Email is required";
  } else if (!new RegExp(/\S+@\S+\.\S+/).test(email)) {
    return "Incorrect email format";
  }
  return null;
};

export const phoneNumberValidator = (phonenumber) => {
  if (!phonenumber) {
    return "Phone Number is required";
  } else if (!new RegExp(/\D/.test(phonenumber))) {
    return "Incorrect phone Number format";
  }
  return null;
};

export const companyNameValidator = (companyname) => {
  if (!companyname) {
    return "Company Name is required";
  } else if (!new RegExp(/^[A-Za-z ]+$/.test(companyname))) {
    return "Incorrect  format";
  }
  return null;
};
