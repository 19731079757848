//https://telepresenz.com/elevator-service-and-remote-mentor/
//Elevator Service and Remote Mentor

import React from 'react';
import bannerimg45 from "../News/NewsData/NewsImageAssets/NewsCardImg44.png";
import './newsDetails.css';

const NewsDetails45 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Elevator Service and Remote Mentor</h1>
        <div className='banner-Img '>
            <img src={bannerimg45} alt="Telepresenz" />
        </div>
        <p>With the surge in residential high-rise buildings in major cities, the elevator industry has reached new heights. MSN reported it was one of the
           <a href="https://expertservicesolutions.com/the-elevator-industry-is-growing/" target='_blank'> fastest-growing industries</a> of 2018. With limited land space, 
           developers are forced to build up to support local populations. Not only is demand high for elevators in new buildings, but riders want a smooth, 
           uninterrupted experience. With today’s constant online habits and social media, when a breakdown occurs, users are quick to make it a headline and 
           hold building owners responsible for a quick repair.</p>
        <p className='subHeader'>Industry Challenges</p>
        <p>With this boom on new installations comes the increase in demand for elevator technicians joining the market, which is expected to grow 12% by 2026. 
        The elevator service industry is currently faced with a skills gap due to the demand for new technicians. Younger technicians do not have the knowledge 
        or expertise as their more experienced co-workers.</p>
        <p className='subHeader'>New Technology Trends</p>
        <p>Companies need to stay ahead of the market and not only keep up with the market but find new ways to innovate their processes and maintenance times. 
        AR Remote Mentoring is becoming an in-demand solution to fill the missing gaps within the industry. Pride and Service Elevator in Edison, NJ 
        states that they are using the <a href="https://www.telepresenzdemo.com/testimonials/" target='_blank'> Telepresenz®</a> Solution to train their novice technicians. The
        <a href="https://www.telepresenzdemo.com/" target='_blank'> Telepresenz®</a> remote mentor platform helps their seasoned experts give their knowledge and expertise 
        from their headquarters to the infield technicians on the scene.</p>
        <p>Creator and CEO of <a href="https://www.telepresenzdemo.com/" target='_blank'> Telepresenz®</a>, <a href="https://www.telepresenzdemo.com/meet-indu-tolia-creator-and-founder-of-care4d-and-now-telepresenz/" target='_blank'>
        Indu Tolia</a> applauds their client for staying ahead of the future by adopting innovative technologies. “Pride and Service Elevators is a progressive 
        company that is self innovating to better service their customers, making them better prepared for the expanding elevator installation and service 
        industry! We are proud and humbled to support them in their endeavors by equipping teams with the technology they can rely on to thrive in today’s 
        landscape and also scale with future market needs!”</p>
        <p>The future of the industry has untapped potential. With over 18 billion rides per year on American elevators, this industry is expected to reach
        <a href="https://www.marketsandmarkets.com/PressReleases/elevator-escalator.asp" target='_blank'> $125.22</a> Billion by 2021. Companies need to stay ahead of the 
        curve or they will get left in the dust from their competitors who are adopting new technologies like <a href="https://www.telepresenzdemo.com/testimonials/" target='_blank'> Telepresenz®</a></p>
        <p>To stay on top of augmented reality and remote mentor trends check out more of our <a href="https://www.telepresenzdemo.com/blog/" target='_blank'> blogs</a>!</p>

      </div>
    </>
  )
}

export default NewsDetails45;

