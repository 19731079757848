//https://telepresenz.com/telepresenz-earns-vendor-of-the-year-award-at-the-2021-ingram-micro-experience-event/
//Telepresenz Earns “Vendor of the Year” Award at the 2021 Ingram Micro Experience Event

import React from 'react';
import bannerimg29 from "../News/NewsData/NewsImageAssets/NewsCardImg28.png";
import telepresenz from './NewsDetailsAssets/telepresenz-image.png';

const NewsDetails29 = () => {
  return (
    <>
      <div className='newsDetails'>
         <h1>Telepresenz Earns “Vendor of the Year” Award at the 2021 Ingram Micro Experience Event</h1>
         <div className='banner-Img '>
            <img src={bannerimg29} alt="Telepresenz Earns “Vendor of the Year” Award at the 2021 Ingram Micro Experience Event" />
         </div>
         <div className='newsDetailImg'>
            <img src={telepresenz} alt="Telepresenz Earns “Vendor of the Year” Award" />
         </div>
         <p className='imgSubtitle'>The prestigious accolade celebrates Telepresenz’s partner-led strategy, exemplary level of engagement and focus on mutual business growth</p>
         <p><b>Edison, NJ –</b> Telepresenz is pleased to be named IoT “Vendor of the Year” by Ingram Micro Inc. The annual Ingram Micro “Vendor of the Year” 
            awards celebrate the success of more than two dozen emerging and established-channel focused vendor organizations across several categories 
            including technology, markets, and communities. The awards were presented in October 2021 at Ingram Micro’s online Experience Event.</p> 
         <p><i>“Ingram Micro is proud to work with Telepresenz and enable our mutual channel partners with technology, services and resources needed to 
            differentiate their business and bring more value to their customers,” says Ingram Micro’s Executive Director of Marketing and host of the 
            2021 Experience Event Dennis Crupi. “Congratulations to Telepresenz and all of our 2021 Experience Vendor Award Winners.”</i></p>
        <p>Pulling from the industry’s most comprehensive portfolio of vendor brands, the Ingram Micro team selected 30 “Vendor of the Year” recipients 
            as part of the 2021 Virtual Experience Event. Earning the title of Name of Award, Telepresenz is commended by Ingram Micro for its unwavering 
            commitment to the channel and excellence at all levels of engagement, enablement and execution.</p>
        <p><i>“We are super thrilled and excited about being awarded the IIoT Vendor of the Year for 2021! Thank You, Ingram-Micro & the 
            entire IoT team, for recognizing Telepresenz with this prestigious award and for your continuous support. We look forward to continuing this great 
            collaboration and helping companies accelerate their digital transformation journey!” says Telepresenz CEO, Indu Tolia.</i></p>
        <h6>About Telepresenz</h6>
        <p>Telepresenz® was founded in 2017 and offers an augmented reality-based collaboration platform for front-line workers. We service clients with 
            geographically dispersed field teams who require a collaboration platform that includes video on demand and workflow automation. We specialize 
            in remote assistance, digitized workflows, and client specific customizations of our platform, that can be delivered on a variety of smart 
            devices including AR glasses. Our business mission is to empower field workers with real-time access to expertise, data and information for 
            diagnosing and resolving critical issues as they occur. Please visit
            <a href="http://www.telepresenz.com/" target='_blank'> www.telepresenz.com</a> for more information.</p>
        <h6>About Ingram Micro</h6>
        <p>Ingram Micro helps businesses realize the promise of technology. It delivers a full spectrum of global technology and supply chain services to 
            businesses around the world. Deep expertise in technology solutions, mobility, cloud, and supply chain solutions enables its business partners 
            to operate efficiently and successfully in the markets they serve. Unrivaled agility, deep market insights and the trust and dependability 
            that come from decades of proven relationships, set Ingram Micro apart and ahead. Discover how Ingram Micro can help you realize the promise 
            of technology. More at <a href="https://cts.businesswire.com/ct/CT?id=smartlink&url=http%3A%2F%2Fwww.ingrammicro.com%2F&esheet=52121767&newsitemid=20191101005492&lan=en-US&anchor=www.ingrammicro.com&index=3&md5=70096a993bb25ebc0b64eafe8d01df41" target='_blank'>
            www.ingrammicro.com</a></p>
        
        <p> </p>
      </div>
      
    </>
  )
}

export default NewsDetails29;
