//https://telepresenz.com/telepresenz-experiences-global-hyper-growth-delivering-industrial-digital-transformation-for-the-connected-worker-through-smart-glass-technology/
//Telepresenz® Experiences Global Hyper-Growth Delivering Industrial Digital Transformation for The Connected Worker Through Smart Glass Technology

import React from 'react';
import bannerimg34 from "../News/NewsData/NewsImageAssets/NewsCardImg33.png";
import './newsDetails.css';

const NewsDetails34 = () => {
  return (
    <>
      <div className='newsDetails'>
          <h1>Telepresenz® Experiences Global Hyper-Growth Delivering Industrial Digital Transformation for The Connected Worker Through Smart Glass Technology</h1>
          <div className='banner-Img '>
            <img src={bannerimg34} alt="Smart Glass Technology" />
          </div>
          <p>Telepresenz®, a leading provider of advanced collaboration software for the industrial connected worker, is taking steps to position itself for 
            hyper-growth. During COVID-19, Telepresenz® offered industrial companies around the globe the ability to pivot digitally and work more remotely. 
            Not just overcoming the challenges of social distancing – but also increasing safety and productivity.</p>
          <p>“There is a clear gap between today’s unified communications platforms and the industrial worker,” commented Telepresenz® Co-Founder and Chief Revenue Officer, 
            Ritesh Tolia. “Telepresenz® offers a unique set of easy-to-use features and wearables including remote assistance, augmented reality telestration, and 
            digital workflows that truly connects the mobile workforce to the entire team.”</p>
          <p>The increasing need for a mobile decentralized workforce over the past year accelerated adoption and allowed Telepresenz® to expand globally, growing their 
            network of distribution partners around the world. In order to meet growing demand and reduce onboarding time, Telepresenz® has taken a number of business 
            and technical steps to streamline operations.</p>
          <p>To allow for straightforward, cost-effective adoption and full utilization of their product, Telepresenz® now offers smart glass device financing bundling with 
            their SaaS software. The platform deploys easily on any IT infrastructure including AWS, Microsoft Azure or on premise servers and integrates with most other 
            major EAM, ERP, unified communications platforms, and remote sensor systems.</p>
          <p>“We are working on integrating our digital workflow functionality with other common collaboration platforms,” said Ritesh. “Presenting procedural information, IIoT data, 
            and live-feed telestration all via augmented reality visualization. Think of it like Microsoft Teams on steroids.”</p>
          <p>Due to Telepresenz®’s global expansion and focus on the industrial market, the company is spinning off its medical and healthcare clients into CARED4D. CARE4D will be offering 
            similar advanced tools, workflows and wearable devices specifically with healthcare providers in mind.</p>
          <p>Telepresenz® plans to continue its forward momentum providing clients with exciting new capabilities in 2021.</p>
      </div>
    </>
  )
}

export default NewsDetails34;
