import React from "react";
import Banner from "../../BuildingBlocks/Banner/Banner";
import PrivacyTable from "./PrivacyTable";
import { Link } from "react-router-dom";
import "./privacy.css";

const Privacy = () => {
  return (
    <div>
      <Banner pageName="Privacy Policy" />
      <div className="privacy-MainContent">
        <div>
          <h6>PRIVACY NOTICE</h6>
        </div>

        <div className="privacySection">
          <h2>Last updated January 28, 2024</h2>
          <p>
            Thank you for choosing to be part of our community at Telepresenz
            Inc. (<b>“we“</b>, <b>“us“</b>, <b>“our“</b>). We are committed to
            protecting your personal information and your right to privacy. If
            you have any questions or concerns about this privacy notice, or our
            practices with regards to your personal information, please contact
            us at &nbsp;
            <Link to="mailto:admin@telepresenz.com">admin@telepresenz.com</Link>
            .
          </p>
          <p>
            When you visit our websites &nbsp;
            (<Link to="https://app.telepresenz.com/" target="_blank">
              https://app.telepresenz.com
            </Link>,&nbsp;
            <Link to="https://anz.telepresenz.com" target="_blank">https://anz.telepresenz.com</Link>
            , the <b>“Website“</b>), and more generally, use any of our services (the
            <b>“Services“</b>, which include the Website), we appreciate that
            you are trusting us with your personal information. We take your
            privacy very seriously. In this privacy notice, we seek to explain
            to you in the clearest way possible what information we collect, how
            we use it and what rights you have in relation to it. We hope you
            take some time to read through it carefully, as it is important. If
            there are any terms in this privacy notice that you do not agree
            with, please discontinue use of our Services.
          </p>
          <p>
            This privacy notice applies to all information collected through our
            Services (which, as described above, includes our Website), as well
            as, any related services, sales, marketing or events.
          </p>
          <h6>
            Please read this privacy notice carefully as it will help you
            understand what we do with the information that we collect.
          </h6>
        </div>

        <div className="privacySection table-content">
          <h2>TABLE OF CONTENTS</h2>
          <ol>
            <li>WHAT INFORMATION DO WE COLLECT?</li>
            <li>HOW DO WE USE YOUR INFORMATION?</li>
            <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
            <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
            <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
            <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
            <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
            <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
            <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
            <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
            <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
            <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
            <li>
              HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?
            </li>
          </ol>
          <h3>1.&nbsp;&nbsp;WHAT INFORMATION DO WE COLLECT?</h3>
          <h5>Personal information you disclose to us </h5>
          <p>
            <b>
              <i>In Short:</i>
            </b>&nbsp;
            We collect personal information that you provide to us.
          </p>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Website, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Website (such as by posting
            messages in our online forums or entering competitions, contests or
            giveaways) or otherwise when you contact us.
          </p>
          <p>
            The personal information that we collect depends on the context of
            your interactions with us and the Website, the choices you make and
            the products and features you use. The personal information we
            collect may include the following:
          </p>
          <p>
            <b>Personal Information Provided by You.</b> We collect names; email
            addresses; phone numbers; billing addresses; debit/credit card
            numbers; mailing addresses; and other similar information.
          </p>
          <p>
            <b>Payment Data.</b> We may collect data necessary to process your
            payment if you make purchases, such as your payment instrument
            number (such as a credit card number), and the security code
            associated with your payment instrument. All payment data is stored
            by Stripe. You may find their privacy notice link(s) here:
            &nbsp;
            <u><Link to="https://stripe.com/privacy" target="_blank">
              https://stripe.com/privacy
            </Link></u>.
          </p>
          <p>
            All personal information that you provide to us must be true,
            complete and accurate, and you must notify us of any changes to such
            personal information.
          </p>
        </div>

        <div className="privacySection">
          <h3>2.&nbsp;&nbsp;HOW DO WE USE YOUR INFORMATION?</h3>
          <p>
            <b>
              <i>In Short:</i>
            </b>&nbsp; We process your information for purposes based on
            legitimate business interests, the fulfillment of our contract with
            you, compliance with our legal obligations, and/or your consent.
          </p>
          <p>
            We use personal information collected via our Website for a variety
            of business purposes described below. We process your personal
            information for these purposes in reliance on our legitimate
            business interests, in order to enter into or perform a contract
            with you, with your consent, and/or for compliance with our legal
            obligations. We indicate the specific processing grounds we rely on
            next to each purpose listed below.
          </p>
          <p>We use the information we collect or receive:</p>
          <p>
            <b>To facilitate account creation and logon process.</b> If you
            choose to link your account with us to a third-party account (such
            as your Google or Facebook account), we use the information you
            allowed us to collect from those third parties to facilitate account
            creation and logon process for the performance of the contract.
          </p>
          <p>
            <b>To post testimonials.</b> We post testimonials on our Website
            that may contain personal information. Prior to posting a
            testimonial, we will obtain your consent to use your name and the
            content of the testimonial. If you wish to update, or delete your
            testimonial, please contact us at <Link to="mailto:admin@telepresenz.com">admin@telepresenz.com</Link> and be sure
            to include your name, testimonial location, and contact information.
          </p>
          <p>
            <b>Request feedback.</b> We may use your information to request
            feedback and to contact you about your use of our Website.
          </p>
          <p>
            <b>To enable user-to-user communications.</b> We may use your
            information in order to enable user- to-user communications with
            each user’s consent.
          </p>
          <p>
            <b>To manage user accounts.</b> We may use your information for the
            purposes of managing our account and keeping it in working order.
          </p>
        </div>

        <div className="privacySection">
          <h3>3.&nbsp;&nbsp;WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>
          <p>
            <b>
              <i>In Short:</i>
            </b>&nbsp;
            We only share information with your consent, to comply with laws, to
            provide you with services, to protect your rights, or to fulfill
            business obligations.
          </p>
          <p>
            We may process or share your data that we hold based on the
            following legal basis:
          </p>
          <p>
            <b>Consent:</b> We may process your data if you have given us
            specific consent to use your personal information for a specific
            purpose.
          </p>
          <p>
            <b>Legitimate Interests:</b> We may process your data when it is
            reasonably necessary to achieve our legitimate business interests.
          </p>
          <p>
            <b>Performance of a Contract:</b> Where we have entered into a
            contract with you, we may process your personal information to
            fulfill the terms of our contract.
          </p>
          <p>
            <b>Legal Obligations:</b> We may disclose your information where we
            are legally required to do so in
          </p>
          <p>
            order to comply with applicable law, governmental requests, a
            judicial proceeding, court order, or legal process, such as in
            response to a court order or a subpoena (including in response to
            public authorities to meet national security or law enforcement
            requirements).
          </p>
          <p>
            <b>Vital Interests:</b> We may disclose your information where we
            believe it is necessary to investigate, prevent, or take action
            regarding potential violations of our policies, suspected fraud,
            situations involving potential threats to the safety of any person
            and illegal activities, or as evidence in litigation in which we are
            involved.
          </p>
          <p>
            More specifically, we may need to process your data or share your
            personal information in the following situations:
          </p>
          <p>
            <b>Business Transfers.</b> We may share or transfer your information
            in connection with, or during negotiations of, any merger, sale of
            company assets, financing, or acquisition of all or a portion of our
            business to another company.
          </p>
        </div>

        <div className="privacySection">
          <h3>4.&nbsp;&nbsp;DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
          <p>
            <b>
              <i>In Short:</i>
            </b>&nbsp;
            We may use cookies and other tracking technologies to collect and
            store your information.
          </p>
          <p>
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </p>
        </div>

        <div className="privacySection">
          <h3>5.&nbsp;&nbsp;HOW LONG DO WE KEEP YOUR INFORMATION? </h3>
          <p>
            <b>
              <i>In Short:</i>
            </b>&nbsp;
            We keep your information for as long as necessary to fulfill the
            purposes outlined in this privacy notice unless otherwise required
            by law.
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
        </div>
        <div className="privacySection">
          <h3>6.&nbsp;&nbsp;HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
          <p>
            <b>
              <i>In Short:</i>
            </b>&nbsp;
            We aim to protect your personal information through a system of
            organizational and technical security measures.
          </p>
          <p>
            We have implemented appropriate technical and organizational
            security measures designed to protect the security of any personal
            information we process. However, despite our safeguards and efforts
            to secure your information, no electronic transmission over the
            Internet or information storage technology can be guaranteed to be
            100% secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able
            to defeat our security, and improperly collect, access, steal, or
            modify your information. Although we will do our best to protect
            your personal information, transmission of personal information to
            and from our Website is at your own risk. You should only access the
            Website within a secure environment.
          </p>
        </div>

        <div className="privacySection">
          <h3>7.&nbsp;&nbsp;DO WE COLLECT INFORMATION FROM MINORS?</h3>
          <p>
            <b>
              <i>In Short:</i>
            </b>&nbsp; We do not knowingly collect data from or market to
            children under 18 years of age.{" "}
          </p>
          <p>
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Website, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent’s use of the Website. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we may have collected from children under
            age 18, please contact us at{" "}
            <Link to="mailto:admin@telepresenz.com">admin@telepresenz.com</Link>
            .
          </p>
        </div>

        <div className="privacySection">
          <h3>8.&nbsp;&nbsp;WHAT ARE YOUR PRIVACY RIGHTS?</h3>
          <p>
            <b>
              <i>In Short:</i>
            </b>&nbsp;
            In some regions, such as the European Economic Area (EEA) and United
            Kingdom (UK), you have rights that allow you greater access to and
            control over your personal information. You may review, change, or
            terminate your account at any time.
          </p>
          <p>
            In some regions (like the EEA and UK), you have certain rights under
            applicable data protection laws. These may include the right (i) to
            request access and obtain a copy of your personal information, (ii)
            to request rectification or erasure; (iii) to restrict the
            processing of your personal information; and (iv) if applicable, to
            data portability. In certain circumstances, you may also have the
            right to object to the processing of your personal information. To
            make such a request, please use the contact details provided below.
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </p>
          <p>
            If we are relying on your consent to process your personal
            information, you have the right to withdraw your consent at any
            time. Please note however that this will not affect the lawfulness
            of the processing before its withdrawal, nor will it affect the
            processing of your personal information conducted in reliance on
            lawful processing grounds other than consent.
          </p>
          <p>
            If you are a resident in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your local data protection supervisory
            authority. You can find their contact details here: &nbsp;
            <Link to="https://ec.europa.eu/info/law/law-topic/data-protection_en" target="_blank">
              https://ec.europa.eu/info/law/law-topic/data-protection_en
            </Link>
          </p>
          <p>
            If you are a resident in Switzerland, the contact details for the
            data protection authorities are available here: &nbsp;
            <Link to="http://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank">
              www.edoeb.admin.ch/edoeb/en/home.html
            </Link>
          </p>
          <p>
            If you have questions or comments about your privacy rights, you may
            email us at &nbsp;
            <Link to="mailto:admin@telepresenz.com">admin@telepresenz.com</Link>
            .
          </p>

          <h1>Account Information</h1>
          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <ul>
            <li>
              Log in to your account settings and update your user account.
            </li>
            <li>Contact us using the contact information provided.</li>
          </ul>
          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our Terms of Use and/or comply with applicable legal
            requirements.
          </p>
          <p>
            <b>
              <u>Cookies and similar technologies:</u>
            </b>&nbsp;
            Most Web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove cookies
            and to reject cookies. If you choose to remove cookies or reject
            cookies, this could affect certain features or services of our
            Website.
          </p>
          <p>
            <b>
              <u>Opting out of email marketing:</u>
            </b>&nbsp;
            You can unsubscribe from our marketing email list at any time by
            clicking on the unsubscribe link in the emails that we send or by
            contacting us using the details provided below. You will then be
            removed from the marketing email list — however, we may still
            communicate with you, for example to send you service-related emails
            that are necessary for the administration and use of your account,
            to respond to service requests, or for other non-marketing purposes.
            To otherwise opt-out, you may:
          </p>
          <ul>
            <li>Contact us using the contact information provided.</li>
          </ul>
        </div>

        <div className="privacySection">
          <h3>9.&nbsp;&nbsp;CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not- Track (“DNT”) feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
        </div>

        <div className="privacySection">
          <h3>10.&nbsp;&nbsp;DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
          <p>
            <b>
              <i>In Short:</i>
            </b>&nbsp;
            Yes, if you are a resident of California, you are granted specific
            rights regarding access to your personal information.
          </p>
          <p>
            California Civil Code Section 1798.83, also known as the “Shine The
            Light” law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
          </p>
          <p>
            If you are under 18 years of age, reside in California, and have a
            registered account with the Website, you have the right to request
            removal of unwanted data that you publicly post on the Website. To
            request removal of such data, please contact us using the contact
            information provided below, and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Website,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g. backups, etc.).
          </p>
          <h4>CCPA Privacy Notice</h4>
          <p>The California Code of Regulations defines a “resident” as:</p>
          <ol>
            <li>
              every individual who is in the State of California for other than
              a temporary or transitory purpose and
            </li>
            <li>
              every individual who is domiciled in the State of California who
              is outside the State of California for a temporary or transitory
              purpose
            </li>
          </ol>
          <p>All other individuals are defined as “non-residents.”</p>
          <p>
            If this definition of “resident” applies to you, we must adhere to
            certain rights and obligations regarding your personal information.
          </p>
          <h4>What categories of personal information do we collect?</h4>
          <p>
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </p>
          <PrivacyTable />
          <p>
            We may also collect other personal information outside of these
            categories instances where you interact with us in-person, online,
            or by phone or mail in the context of:
          </p>

          <ul>
            <li>Receiving help through our customer support channels;</li>
            <li>Participation in customer surveys or contests; and</li>
            <li>
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </li>
          </ul>
          <h3>How do we use and share your personal information?</h3>
          <p>
            More information about our data collection and sharing practices can
            be found in this privacy notice.
          </p>
          <p>
            You may contact us by email at{" "}
            <Link to="mailto:support@telepresenz.com">
              support@telepresenz.com
            </Link>
            , or by referring to the contact details at the bottom of this
            document.
          </p>
          <p>
            If you are using an authorized agent to exercise your right to
            opt-out we may deny a request if the authorized agent does not
            submit proof that they have been validly authorized to act on your
            behalf.
          </p>
          <h3>Will your information be shared with anyone else?</h3>
          <p>
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Each service provider is a for-profit entity that processes the
            information on our behalf.
          </p>
          <p>
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            “selling” of your personal data.
          </p>
          <p>
            TELEPRESENZ® has not disclosed or sold any personal information to
            third parties for a business or commercial purpose in the preceding
            12 months. TELEPRESENZ® will not sell personal information in the
            future belonging to website visitors, users and other consumers.
          </p>
          <h3>Your rights with respect to your personal data</h3>
          <p>
            <u>Right to request deletion of the data – Request to delete</u>
          </p>
          <p>
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech, our
            compliance requirements resulting from a legal obligation or any
            processing that may be required to protect against illegal
            activities.
          </p>
          <p>
            <u>Right to be informed – Request to know</u>
          </p>
          <p>Depending on the circumstances, you have a right to know:</p>
          <ul>
            <li>whether we collect and use your personal information;</li>
            <li>the categories of personal information that we collect;</li>
            <li>
              the purposes for which the collected personal information is used;
            </li>
            <li>whether we sell your personal information to third parties;</li>
            <li>
              the categories of personal information that we sold or disclosed
              for a business purpose;
            </li>
            <li>
              the categories of third parties to whom the personal information
              was sold or disclosed for a business purpose; and
            </li>
            <li>
              the business or commercial purpose for collecting or selling
              personal information.
            </li>
          </ul>
          <p>
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response to
            a consumer request or to re-identify individual data to verify a
            consumer request.
          </p>
          <p>
            <u>
              Right to Non-Discrimination for the Exercise of a Consumer’s
              Privacy Rights
            </u>
          </p>
          <p>
            We will not discriminate against you if you exercise your privacy
            rights.
          </p>
          <p>
            <u>Verification process</u>
          </p>
          <p>
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g. phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
          </p>
          <p>
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. If, however, we cannot verify your
            identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity, and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </p>
          <p>
            <u>Other privacy rights</u>
          </p>
          <ul>
            <li>you may object to the processing of your personal data</li>
            <li>
              you may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the data
            </li>
            <li>
              you can designate an authorized agent to make a request under the
              CCPA on your behalf. We may deny a request from an authorized
              agent that does not submit proof that they have been validly
              authorized to act on your behalf in accordance with the CCPA.
            </li>
            <li>
              you may request to opt-out from future selling of your personal
              information to third parties. Upon receiving a request to opt-out,
              we will act upon the request as soon as feasibly possible, but no
              later than 15 days from the date of the request submission.
            </li>
          </ul>
          <p>
            To exercise these rights, you can contact us by email at{" "}
            <Link to="mailto:support@telepresenz.com">
              support@telepresenz.com
            </Link>
            , or by referring to the contact details at the bottom of this
            document. If you have a complaint about how we handle your data, we
            would like to hear from you.
          </p>
        </div>

        <div className="privacySection">
          <h3>11.&nbsp;&nbsp;DO WE MAKE UPDATES TO THIS NOTICE?</h3>
          <p>
            <b>
              <i>In Short:</i>
            </b>&nbsp;
            Yes, we will update this notice as necessary to stay compliant with
            relevant laws.
          </p>
          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated “Revised” date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
        </div>

        <div className="privacySection">
          <h3>12.&nbsp;&nbsp;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
          <p>
            If you have questions or comments about this notice, you may email
            us at <Link to="mailto:admin@telepresenz.com">admin@telepresenz.com</Link> or by post to:
          </p>
          <p>
            Telepresenz Inc. <br />
            2015 NJ-27, 234 <br />
            Edison, NJ 08817 <br />
            United States <br />
          </p>
        </div>

        <div className="privacySection">
          <h3>
            13.&nbsp;&nbsp;HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h3>
          <p>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please visit:&nbsp;
            <Link to="mailto:support@telepresenz.com">
              support@telepresenz.com
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
