//https://telepresenz.com/telepresenz-extends-antea-digital-twin-to-frontline-workers/
//Extending Digital Twin Software to Frontline Workers
import React from 'react';
import bannerimg21 from "../News/NewsData/NewsImageAssets/NewsCardImg20.png";
import digitalTwin from './NewsDetailsAssets/digital_twin_sw.jpeg';
import './newsDetails.css';

const NewsDetails21 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Extending Digital Twin Software to Frontline Workers</h1>
        <div className='banner-Img '>
            <img src={bannerimg21} alt="Extending Digital Twin Software to Frontline Workers" />
        </div>
        <p>Telepresenz and Antea announce a strategic partnership to integrate and jointly pursue projects in the energy sector.</p>
        <p>Telepresenz’s award winning remote assistance software platform runs on hands-free wearable devices (such as Realwear™, 
            Vuzix™, Rokid Glass™, and others). It provides frontline workers with digital workflows and remote expert guidance.</p>
        <p>The Antea™ platform is one of the industrial market’s leading AIM (Asset Integrity Management) solutions, creating 
           a 3D digital twin of a facility allowing operators to track inspection data, maintenance, and failure risk profiles 
           for every piece of equipment.</p>
        <p>Combining remote assistance technology with Antea’s digital twin solution enables field workers to stay connected 
           with back-office experts and all mission critical equipment data. This creates huge operational efficiencies improving 
           both safety and speed for inspections, maintenance, and repair.</p>
        <p><i>“At Antea, we strive to deliver the most feature-rich mechanical integrity risk-based inspection 
           platform available. Together, Antea and Telepresenz will help change the way work happens with asset integrity management 
           in a post-pandemic world,” Floyd Baker, VP Antea North America.</i></p>
        <p>This integrated solution allows the field team to capture inspection data in a paperless fashion with hands-free smart glasses. 
            Data such as videos, photos, measurements and sensor data captured during inspections will be directly uploaded into the Antea 
            platform eliminating the need for manual data entry.</p>
        <p> <i>“We are very excited to partner with Antea and believe this partnership will drive accelerated growth for both 
           companies in the global market,” said Indu Tolia, CEO/Founder of Telepresenz. “An integrated offering of Antea’s asset intelligence 
           and services with the Telepresenz real-time remote assistance and digital workflow platform will reduce equipment downtime, 
           improve overall service delivery, and reduce costs for businesses.”</i></p>
        <p>The digital transformation technologies from Telepresenz and Antea promise operators significant benefit in digital plant management 
            while keeping field personnel safe in hazardous industrial environments.</p>
        <div className='newsDetailImg'>
            <img src={digitalTwin} alt="Field personnel safe in hazardous industrial environments" />
        </div>
      </div>
    </>
  )
}

export default NewsDetails21;
