//https://telepresenz.com/mro-tech-training/
//Cutting-edge MRO Tech Training In The Field
import React from 'react';
import remoteDigitalOperatn from './NewsDetailsAssets/remote-digital-operatn.jpg';
import remoteDigitalMonitor from './NewsDetailsAssets/remote-digital-monitor.jpg';

const NewsDetails23 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Cutting-edge MRO Tech Training In The Field</h1>
        <div className='flexContainer'>
            <div className='flexItemRight'>
                <img src={remoteDigitalOperatn} alt="Emergrncy Response" />
            </div>
            <div className='flexItemLeft'>
                <img src={remoteDigitalMonitor} alt="Emergency Response" />
            </div>
        </div>

      </div>
    </>
  )
}

export default NewsDetails23;
