//https://telepresenz.com/telepresenz-platform-capabilities/
//Telepresenz® Platform Capabilities

import React from 'react';
import capablities from './NewsDetailsAssets/capabilities.jpg';
import './newsDetails.css'

const NewsDetails36 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Request the Telepresenz® Platform Capabilities Sheet</h1>
        <div className='contentImg'>
                 <img src={capablities} alt="Telepresenz® Platform Capabilities" />
          
             
        </div>

      </div>
    </>
  )
}

export default NewsDetails36;
