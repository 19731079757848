//https://telepresenz.com/successful-deployment-utilities/
//Successful Deployment: Utilities

import React from 'react';
import bannerimg32 from "../News/NewsData/NewsImageAssets/NewsCardImg31.png";
import './newsDetails.css';

const NewsDetails32 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Successful Deployment: Utilities</h1>
        <div className='banner-Img '>
            <img src={bannerimg32} alt="Successful Deployment of Utilities" />
        </div>
        <h5>Problem</h5>
        <p>A sizeable mobile technician workforce who are contractors at a National Utility Board is required to be supported and supervised 
            as they perform their regular maintenance and repair operations, with safety being paramount. Expert supervisors are often mobile 
            and need to support and supervise multiple teams when they are at their office and on the move.</p>
        <h5>Solutions</h5>
        <p>Dedicated private cloud deployment within customers’ infrastructure.</p>
        <p>Telepresenz® Connected Worker with Realwear HMT-1 devices for Technicians and iPads for Supervisors.</p>
        <h5>Result of Deployment</h5>
        <p>Customer performs support, job allocation, supervision, monitoring, and quality control with auditing and compliance.</p>
        <p>Support actions include remote mentoring, problem solving, sharing of documents and drawings. Silent recordings archived 
           for record-keeping and auditing purposes.</p>
        <p>Supervisors no longer need to be at the site to support and supervise technicians or for quality assurance purposes.</p>
      </div> 
    </>
  )
}

export default NewsDetails32;
