//https://telepresenz.com/telepresenz-selected-as-one-of-top-12-ventures-in-ltp-startup-challenge-for-mro-solutions-in-aviation-industry/
//Telepresenz® Selected as One of Top 12 Ventures in LTP Startup Challenge for MRO Solutions in Aviation Industry
import React from 'react';
import './newsDetails.css';
import bannerimg14 from "../News/NewsData/NewsImageAssets/NewsCardImg13.jpg";

const NewsDetails14 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Telepresenz® Selected as One of Top 12 Ventures in LTP Startup Challenge for MRO Solutions in Aviation Industry</h1>
        <div className='banner-Img '>
            <img src={bannerimg14} alt="Telepresenz" />
        </div>
        <p className='property'>
           Lufthansa Technik Philippines (LTP) Startup Challenge is a unique opportunity for entrepreneurs and start-ups 
           looking to apply their ideas/solutions to aviation’s MRO (maintenance repair and overhaul) industry. Telepresenz® 
           was shortlisted as one of the TOP 12 selected ventures to receive the 4-day training program and participate in the international pitch competition</p>
        <p>Telepresenz® was recently selected as one of the top 12 ventures to participate in the Lufthansa Technik Philippines (LTP) 
            Start-up Challenge, a program designed to support and promote innovative start-ups in the field of maintenance, repair, 
            and overhaul (MRO) in the aviation industry. Sponsored by Seedstars and Lufthansa Technik Philippines, the program received 
            over 100 applications from around the world and provided the selected ventures with a 4-day training program and the opportunity 
            to pitch their ideas to a panel of judges.</p>
        <p>During the pitch competition, Telepresenz® CEO and Co-Founder Indu Tolia presented the company’s innovative solutions for digitizing 
           manual processes and improving the efficiency and accuracy of MRO operations. By collecting data points at various stages and providing 
           centralized access to this information, Telepresenz® streamlines processes, enables real-time operational insights, and facilitates 
           compliance with regulations and audit checks.</p>
        <p>In addition to digitizing manual processes, Smart inspection capabilities within the platform use artificial intelligence, machine learning, 
            and computer vision frameworks to improve the accuracy and efficiency of inspections in the MRO industry. This technology helps identify errors 
            and hazards quickly, improving the quality of repairs and reducing costs. It also provides alert notifications and helps companies maintain regulatory compliance.</p>
        <p>Telepresenz® video collaboration capabilities also allow remote subject matter experts to provide real-time guidance to technicians, helping to 
            resolve problems faster and more efficiently. Overall, the Telepresenz® platform provides a reliable and safe platform for MRO and health and 
            safety (HSE) use cases in the aviation industry, with key features including digitized operations, smart inspections, and expert on-demand support.</p>
      </div>
    </>
  )
}

export default NewsDetails14;
