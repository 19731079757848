import React, { useEffect, useState } from "react";
import { Country } from "./Country";
import { FaLocationDot } from "react-icons/fa6";
import {
  nameValidator,
  emailValidator,
  phoneNumberValidator,
} from "./Validator.js";
import "./contact.css";
// import Modal from "./Modal.jsx";

export default function Contact() {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone_number: "",
    company_name: "",
    country: "",
    industry: "",
    message: "",
  });

  const [error, setError] = useState({
    nameError: "",
    emailError: "",
    phoneNumberError: "",
  });

  const [modal, setModal] = useState({
    showModal: false,
    modalTitle: "",
    modalBody: "",
    serverErrorCode: null,
  });

  const [message , setMessage] = useState("");

  let HTTP_STATUS = null;
  const onUpdateField = (e) => {
    const nextFormState = {
      ...form,
      [e.target.name]: e.target.value,
    };
    setForm(nextFormState);
  };

  // useEffect(() => {

  // }, [Modal])


  const onSubmitForm = (e) => {
    e.preventDefault();

    const nameError = nameValidator(form.name);
    const emailError = emailValidator(form.email);
    const phoneNumberError = phoneNumberValidator(form.phone_number);

    let base_url = "https://dev.telepresenzdemo.com";

    if (
      nameError === null &&
      emailError === null &&
      phoneNumberError === null
    ) {
      setError({
        nameError: "",
        emailError: "",
        phoneNumberError: "",
      });

      fetch(base_url + "/api/submitEnterpriseRequest", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      })
        .then((response) => {
          HTTP_STATUS = response.status;
          return response.json();
        })
        .then((data) => {
          let formTitle = null,
            formBody = null;
          if (HTTP_STATUS === 200) {
            formTitle = "Form Submitted";
            formBody = "The form has been submitted";
            setMessage("The form has been submitted successfully");
          } else if (HTTP_STATUS === 500) {
            formTitle = "Server Error!!!";
            formBody = "Something went wrong, try again later";
            setMessage("Server Error, Something went wrong, try again later ");
          } else if (HTTP_STATUS === 400) {
            formTitle = "Database Error!!!";
            formBody = data + "";
            setMessage("Database Error");
          } else if (HTTP_STATUS === 524) {
            formTitle = "Server Timeout!!!";
            formBody = "Please retry after some time";
            setMessage("Server Timeout , Please retry after some time");
          } else {
            formBody = data + "";
          }
          // setForm({
          //   name: "",
          //   email: "",
          //   phone_number: "",
          //   company_name: "",
          //   country: "",
          //   industry: "",
          //   message: "",
          // });
          setModal({
            showModal: true,
            modalTitle: formTitle,
            modalBody: formBody,
            serverErrorCode: HTTP_STATUS,
          });

          setForm({
            name: "",
            email: "",
            phone_number: "",
            company_name: "",
            country: "",
            industry: "",
            message: "",
          });

        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setError({
        nameError: nameError !== null ? nameError : "",
        emailError: emailError !== null ? emailError : "",
        phoneNumberError: phoneNumberError !== null ? phoneNumberError : "",
      });
    }

  };

  return (
    <div className="contactcontainer">
      <div className="contactBanner">
        <div className="ourPresenceSection1">
          <p>Connecting Teams Around The World</p>
          <h1>Our Presence</h1>
          <p className="tp-geographic">
            <span>“Telepresenz”</span> spans 35+ interconnected geographic
            regions, offering a complete suite of industrial AR/AI powered
            applications and deployments around the world.
          </p>
        </div>
        <div className="ourPresenceSection2">
          <p className="purPresenceTitle">
            Telepresenz Headquarters & Contact Info
          </p>
          <div className="addressSection">
            <div>
              <h5>
                <span>
                  <FaLocationDot />
                </span>
                Headquarters
              </h5>
              <p>101 Crawfords Corner Road, Suite 4116, Holmdel, NJ 07733</p>
            </div>
            <div>
              <h5>
                <span>
                  <FaLocationDot />
                </span>
                New York
              </h5>
              <p>335 Madison Avenue 16th Fl, New York, New York 10017</p>
            </div>
            <div>
              <h5>
                <span>
                  <FaLocationDot />
                </span>
                Asia
              </h5>
              <p>
                Old Madras Rd, Salarpuria Magnificia 13th Fl, No. 78, Bengaluru
                East, Karnataka IN
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="contactcontainer3">
        <h2>Get in touch for More Details/Demo</h2>
        <h5>
          If you have any questions, just fill in the contact form, and we will
          answer you shortly.
        </h5>
      </div>
      <div className="errorDisplay">
        <span> {error.nameError} </span>
        <span> {error.emailError} </span>
        <span> {error.phoneNumberError} </span>
      </div>
      <div className="contactcontainer4">
        <form id="form-id" onSubmit={onSubmitForm}>
          <div className="formContainer">
            <input
              type="text"
              name="name"
              value={form.name}
              autocomplete="off"
              id=""
              className="inputelement"
              aria-label="Name field"
              placeholder="Your Name*"
              onChange={onUpdateField}
            />
            <input
              type="email"
              name="email"
              value={form.email}
              autocomplete="off"
              id=""
              className="inputelement"
              aria-label="Email field"
              placeholder="E-mail*"
              onChange={onUpdateField}
            />
            <input
              type="number"
              name="phone_number"
              value={form.phone_number}
              id=""
              className="inputelement"
              aria-label="Number field"
              min="0"
              placeholder="Phone number*"
              onChange={onUpdateField}
            />
            <input
              type="text"
              name="company_name"
              value={form.company_name}
              autocomplete="off"
              id=""
              className="inputelement"
              aria-label="CompanyName field"
              placeholder="Company name*"
              onChange={onUpdateField}
            />
            <Country
              className="inputelement"
              onUpdateField={onUpdateField}
              value={form.country}
            />

            <select
              name="industry"
              value={form.industry}
              id=""
              className="inputelement"
              aria-label="Industry field"
              onChange={onUpdateField}
            >
              <option value="">Select industry</option>
              <option value="Field Services">Field Services</option>
              <option value="Assembly Plant">Assembly Plant</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Training">Training</option>
              <option value="Remote Inspection">Remote Inspection</option>
              <option value="MRO">MRO</option>
              <option value="Medicine">Medicine</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="formMessage">
            <div>
              <textarea
                name="message"
                value={form.message}
                id=""
                cols="147"
                rows="9"
                placeholder="Message"
                className="messageCl"
                aria-label="Message field"
                onChange={onUpdateField}
              ></textarea>
            </div>
            <div className="formAlert">   
              {HTTP_STATUS===200 ? 
                     <p>{message}</p> : 
                     <p className="err_alert">{message}</p>}
            </div>
            <div>
              <button className="sendMessagebtn" type="submit" value="submit">
                SEND MESSAGE
              </button>
            </div>
          </div>
        </form>
        {/* <Modal /> */}
      </div>
    </div>
  );
}
