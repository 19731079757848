//https://telepresenz.com/innovations-and-trends-disrupting-services-mro/
//Innovations and Trends Disrupting Services & MRO
import React from 'react';
import bannerimg9 from "../News/NewsData/NewsImageAssets/NewsCardImg8.png";
import './newsDetails.css';

const Newsdetails09 = () => {
  return (
    <>
      <div className='newsDetails'>
            <h1>Innovations and Trends Disrupting Services & MRO</h1>
            <div className='banner-Img'>
                <img src={bannerimg9} alt="Innovations and Trends" />
            </div>
            <p>Recently, EthAum Venture Partners hosted a virtual thought leadership panel on the topic “Innovations Disrupting Services & MRO Industry”. 
            The panel featured a diverse group of experts, including Indu Tolia, CEO, Telepresenz, who shared her valuable insights and trends 
            observed globally in the maintenance repair and operations industry. Marc Essig from Edge, Dinesh Tallur from Siemens Digital Industries 
            Software, Wladmir Castro from Automind, Rahael Carlo Domingo from Collins Aerospace were other panelists.</p>
            <p>The discussion primarily focused on the impact of disruptive innovations in the Oil and Gas, Aerospace, and Defence industries, and how it is 
            transforming the MRO industry. The panel provided a unique perspective on how emerging technologies and changing market demands are shaping these critical sectors.</p>
            <p>To watch the recorded session, click <a href="https://www.youtube.com/watch?v=rY6tYk0UYeY" target='_blank'>here.</a></p>
            <p><a href="https://www.linkedin.com/events/innovationsinaerospace-asummitb7050751435965153280/comments/" target='_blank'>Register</a> to be a part of the next summit on <div className='thick'>Innovations in Aerospace on June 13th 2023</div></p>
            <p>Here are the highlights of the discussion on Innovations Disrupting Services & MRO Industry.</p>
            <h4>Major Challenges in MRO services</h4>
            <p>For MRO leaders, one of the most significant issues is lack of budgets. To drive MROs successfully, we need technology and hardware that needs investments. Maintenance 
            leaders seek predictability to reduce unplanned stops that cause downtime for operations, but achieving this can be complex and varies from industry to industry and 
            company to company. Technical resources, knowledge, and work practices all impact in achieving this goal.</p>
            <p>Another critical issue is the long-life cycle of systems, particularly in the aerospace and defence industries. These systems can last for 20-30 years, making obsolescence management and 
            supply chain a significant challenge. Ensuring the system is operational as much as possible on the field is crucial. Shortening maintenance activities and implementing preventive 
            maintenance can help, but it requires the right tools, space, skill, and timing.</p>
            <p>Grounded time was mentioned as the most striking KPI, directly impacting revenue and the system’s ability to operate. Following predefined manuals and maintenance activities can reduce 
            downtime, but the challenge lies in moving towards predictive and prescriptive maintenance. While predictive maintenance can help predict when maintenance activities are necessary, it is 
            not enough. Watch the <a href="https://www.youtube.com/watch?v=rY6tYk0UYeY" target='_blank'>video</a> to know some of the solutions in emerging technology that can help address these issues.</p>
            <h4>New Technology and Innovation in MRO</h4>
            <p>The MRO industry has seen significant advancements in recent years due to the rapid digitalization of various processes. One such example is the shift from paper-based checklists to digital formats, 
                which offer better traceability and tracking of progress. Augmented and virtual reality technologies have also emerged, allowing for remote collaboration, real-time troubleshooting, and faster repairs. 
                These innovations have improved the overall efficiency of the industry and enabled inspections and repairs from virtually anywhere.</p>
            <p>Another emerging concept in the MRO industry is residual life extension, which involves re-engineering ageing components to extend the useful life of aircraft systems. With the help of predictive 
               analytics and high-fidelity simulation models, engineers can optimize the design and material of old components, improving the overall reliability of the aircraft system.</p>
            <p>Overall, the MRO industry is continually evolving, and as technology continues to develop, we can expect even more exciting innovations to come.</p>
            <h4>Forward-looking Disruptions</h4>
            <p>In the next 5-10 years, the MRO industry is expected to undergo significant changes due to the adoption of emerging technologies. AI and machine learning will likely be the first technologies to be adopted, 
                as they can help make better decisions and reduce downtime.</p>
            <p>Additionally, the growth of cloud-based data storage and the need for increased data security and sustainability will shape the industry and provide opportunities for innovation and growth. However, 
                the adoption of these technologies may vary depending on the industry and the reference point of the user.</p>
            <h4>Telepresenz for Integrating Technology into Work Practices</h4>
            <p>Telepresenz provides three main ways to support their customers. Firstly, we sell directly to customers and invest time in discovering their problems and challenges, introducing them to the system’s 
               capabilities, deploying it, and training them. For advanced customers using AI/ML, Telepresenz’s on-site presence helps to understand their processes and identify the best solutions.</p>
            <p>Secondly, Telepresenz works closely with local partners to provide support closer to  customer’s site and train them on how to use the hardware and software. Interactive sessions
               with technical teams help in providing support or integration with Maximo or SAP.</p>
            <p>Lastly, Telepresenz works with system integrators who already have insights into the customer’s IT infrastructure, ensuring that they receive the necessary support. With these three ways of support, 
               Telepresenz makes sure that the customer is never left without the help they need.</p>
            <div className='subContent'>
                <h6>What is Telepresenz’s approach to providing support to customers in the Defence industry and how do we balance on-site and virtual support to meet our customers’ needs?</h6>
                <p>Telepresenz offers both on-site and virtual support, depending on our customers’ needs and Service Level Agreements (SLAs). Sometimes, the support is required 
                   within a couple of hours, while at other times, it can be within 24 hours.</p>
                <p>We offer on-site support during the deployment period, and we ensure that everything runs smoothly. We take the time to understand our customers needs, conducting 
                   deep discovery sessions with them, to propose the right solution that adds value to their business.</p>
                <p>Our approach is a combination of both on-site and virtual support, allowing us to offer the best possible service to our customers.</p>
                <h6>What are the benefits of the modular structure of Telepresenz solutions, and how do the different deployment options offered by providers 
                    allow clients to customize the solution to their specific needs and IT infrastructure?</h6>
                <p>One of the key benefits of Telepresenz is our modular structure, which allows for customization based on specific needs and requirements. This means 
                   that users can pick and choose the features they need, making the solution more effective and suited to their use cases.</p>
                <p>In terms of deployment, customers can host the solution on their own cloud infrastructure, such as AWS and Azure, or opt for an on-premises deployment 
                   if they prefer a physical server type deployment within their own facility and network.</p>
                <p>We also offer a hybrid solution that is tailored to the client’s IT needs. With these deployment options, clients can choose the option that best suits their business requirements and IT infrastructure.</p>
                <h6>Is Telepresenz designed exclusively for a specific wearable device, or is it also compatible with other wearable devices?</h6>
                <p>Telepresenz is device agnostic and is supported on a variety of wearable devices. Each wearable device goes through a certification process before it can be deployed to 
                    ensure everything functions as expected. The solution is compatible with any handheld device, allowing customers to utilize their existing regular phones or tablets, 
                    with no variation in functionality.</p>
                <h6>How can Legacy systems like ERP, planning and scheduling tools be integrated with newer technologies such as connected worker, remote operation, and smart operations? 
                    Should integration occur at the data layer or through APIs?</h6>
                <p>The integration of legacy systems with newer technologies often occurs through REST APIs. However, there are cases where a high-level integration is needed, 
                    where older systems may not interface well with newer ones.</p>
                <p>In such cases, data can be exported and imported manually. Integration can also occur at the data layer, where real-time data exchange is possible. For example, with Telepresenz, 
                    all workflows, checklists, asset data, and updates can be imported in real-time, and every line item completed by an operator can be updated instantaneously. Pictures, videos, 
                    and scanned asset data can be visualized for real-time performance reports. This data can be pushed back into the external system for updates.</p>
                <h6>Does Telepresenz provide a causative or causal model to support failure predictions in its decision support system for providing corrective solutions?</h6>
                <p>Currently, Telepresenz does not provide causative or causal models for failure predictions. However, the solution is capable of providing insights such as where and when the failure 
                    happened, how often it happened, and the typical steps taken to detect the failure based on the data it captures.</p>
                <p>If customers have their own analysis tools to ingest and process the data, they can develop their own custom reports and models to identify the root cause of the failure. This enables 
                    customers to bring in an additional level of information and look at things more comprehensively.</p>
                <p>Overall, Telepresenz solution provides valuable data insights to customers, allowing them to make more informed decisions and take appropriate corrective actions to prevent future failures.</p>
            </div>
        
      </div>
    </>
  )
}

export default Newsdetails09;
