//https://telepresenz.com/press-release-apr-14-2020/
//Press Release – Apr 14, 2020

import React from 'react'
import bannerimg44 from "../News/NewsData/NewsImageAssets/NewsCardImg43.png";
import './newsDetails.css';

const NewsDetails44 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Press Release – Apr 14, 2020</h1>
        <div className='banner-Img '>
            <img src={bannerimg44} alt="Telepresenz" />
        </div>
        <p className='subHeader'>FOR IMMEDIATE RELEASE</p>
        <p>Telepresenz® <a href={'mailto:info@care4d.com'}>info@care4d.com </a>732-440-9453</p>
        <p className='subHeader'>CARE4D Announces United States Patent and Trademark Approval on Telepresenz®</p>
        <p>EDISON, NJ – CARE4D is pleased to announce the grants of USPTO Registration Nos: 6011127 & 6011128 for the Telepresenz® 
        solution. The trademarks include the logo with the word “TELEPRESENZ” stacked on “VIRTUALIZE THE EXPERT”.</p>
        <p>The <a href="https://www.telepresenzdemo.com/" target='_blank'>Telepresenz® </a>solution from CARE4D enables your front-line workers to virtualize experts on demand. Utilizing augmented reality 
        headset with remote mentor solution, remote workers can be connected to an expert in any situation.</p>
        <p>CEO of <a href="https://www.telepresenzdemo.com/" target='_blank'>Telepresenz®</a> , 
           <a href="https://www.telepresenzdemo.com/meet-indu-tolia-creator-and-founder-of-care4d-and-now-telepresenz/" target='_blank'> Indu Tolia</a>, speaks about the new trademark 
           approval for their company. “Registering the <a href="https://www.telepresenzdemo.com/" target='_blank'>Telepresenz</a> trademark with the USPTO will help to further strengthen 
           the branding of our products in the markets we serve. By continuing to establish and validate the Telepresenz brand, we believe we can address the costly 
           and persistent challenges remote teams face today”.</p>
        <p>CARE4D’s <a href="https://www.telepresenzdemo.com/" target='_blank'>  Telepresenz®</a> solution aids and propels service in the fields of assembly plants, factories, manufacturing, 
           MRO, field service, training, telemedicine, and remote inspection. This thoughtfully designed real-time collaboration product offers solutions and improvements 
           to enterprises’ most important aspects of communication, operations, and problem-solving.</p>
        <p>To learn more about the Telepresenz solution from CARE4D and receive a 60-day trial, visit <a href="https://www.telepresenzdemo.com/" target='_blank'>https://www.telepresenzdemo.com/ </a>
        or call (732) 440-9453</p>
      </div>
    </>
  )
}

export default NewsDetails44;
