import React, { useState, useEffect } from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import "./pagination.css"

const Pagination = ({paginationNumber, getUpdatedPagination}) => { 
    /*obtain data from localStorage*/
    const storedPage = localStorage.getItem('currentPage');
    const initialPage = storedPage ? parseInt(storedPage) : 1; 
    const [currentPage, setCurrentPage] = useState(initialPage);
    const pageBulletArray = [];

    for (let i = 1; i <= paginationNumber; i++) {
        pageBulletArray.push(i);
    }

    const paginationBulletUI = pageBulletArray.map((number, index) => (
        <li
            key={index}
            className={`${currentPage === number ? "activePage" : ""
                }`}
        >
            <a className="page-link"
                onClick={() => changeCurrentPage(number)}>
                {number}
            </a>
        </li>
    )) 

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const changeCurrentPage = (num) => {
        setCurrentPage(num);
    }

    const nextPage = () => {
        if (currentPage !== paginationNumber) {
            setCurrentPage(currentPage + 1);
        }
    }
    
    /*fetch the stored currentpage from localStorage upon the component’s mounting. */ 
    useEffect(() => {
        localStorage.setItem('currentPage', currentPage);
        getUpdatedPagination(currentPage);
      }, [currentPage, getUpdatedPagination]);
       
    /*useEffect(() => {  getUpdatedPagination(currentPage)}, [currentPage]);*/

    return (
        <nav className="pagination-container">
            <ul className="pagination">
                <li>
                    <button
                        onClick={previousPage}
                        className="page-link"
                        disabled={currentPage === 1}
                    >
                        <IoIosArrowBack />
                    </button>
                </li>

                {paginationBulletUI}

                <li>
                    <button
                        onClick={nextPage}
                        disabled={currentPage === paginationNumber}
                        className="page-link"
                    >
                        <IoIosArrowForward />
                    </button>
                </li>
            </ul>
        </nav>
    )
}
export default Pagination
