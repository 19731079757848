import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import telepresenzLogo from "../../assets/Telepresenz-Logo.png";
import "./Header.css";

function Header() {
  const [activeButton, setActiveButton] = useState(1);
  const [showSideBar, setShowSideBar] = useState(false);

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    setShowSideBar(false);
  };

  const handleSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  return (
    <div className="tp-header">
      <div className="headerContainer">
        <GiHamburgerMenu className="hamburgerMenuIcon" onClick={handleSideBar} />
        <Link to="/" className="headerLogo">
          <img alt="telepresenzLogo" src={telepresenzLogo} />
        </Link>
        <div
          className={`headerLeftSection ${showSideBar === true ? "slideInSideBar" : "slideOutSideBar"}`}
        >
          <ul className="headerUi">
            <li className="headerLink"
              /*className={`headerLink ${activeButton === 1 ? "active" : ""}`}*/
              
            >
              <Link className= {`levelOne ${activeButton === 1 ? "active" : ""}`} to="/"
               onClick={() => handleButtonClick(1)}
              >
                Home
              </Link>
            </li>
            <li className="headerLink"
              /*className={`headerLink ${activeButton === 2 ? "active" : ""}`}
              onClick={() => handleButtonClick(2)}*/
            >
              <Link  className={`levelOne ${activeButton === 2 ? "active" : ""}`}  to="/about" 
               onClick={() => handleButtonClick(2)}>
                About Us
              </Link>
            </li>
            <li className="headerLink"
              /*className={`headerLink ${activeButton === 3 ? "active" : ""}`}
              onClick={() => handleButtonClick(3)}*/
            >
              <Link className={`levelOne ${activeButton === 3 ? "active" : ""}`}  to="/products" 
               onClick={() => handleButtonClick(3)}> 
                Our Products
              </Link>
            </li>
            <li className="headerLink"
              /*className={`headerLink ${activeButton === 4 ? "active" : ""}`}
              onClick={() => handleButtonClick(4)}*/
            >
              <Link className={`levelOne ${activeButton === 4 ? "active" : ""}`}  to="/services" 
               onClick={() => handleButtonClick(4)}>  
                Our Services
              </Link>
            </li>
             <li className="headerLink"
              /*className={`headerLink ${activeButton === 5 ? "active" : ""}`}
              onClick={() => handleButtonClick(5)}*/
            >
              <Link className={`levelOne ${activeButton === 5 ? "active" : ""}`}  to="/team" 
               onClick={() => handleButtonClick(5)} >
                Our Team
              </Link>
            </li>
            <li
              className={"resourses"}
            >
              <Link className="levelOne resourcesCl">
                Resources
              </Link>
              <ul className="subMenuList">
                <li
                  className={`subMenu ${activeButton === 6 ? "active" : " "}`}
                  onClick={() => handleButtonClick(6)}
                >
                  <Link
                    to="/news">
                    News
                  </Link>
                </li>
                <li
                  className={`subMenu ${activeButton === 7 ? "active" : " "}`}
                  onClick={() => handleButtonClick(7)}
                >
                  <Link to="/blogs" >Blogs</Link>
                </li>
                <li
                className={`subMenu ${activeButton === 8 ? "active" : " "}`}
                onClick={() => handleButtonClick(8)}>
                <Link to="/career" >Careers</Link>
                </li>
              </ul>
            </li>
          </ul>

        </div>

      </div>
      
        <ul className="btnSection">
          <li
            className="loginBtn"
            onClick={() => handleButtonClick(9)}
          >
            <Link
              className=""
              target="_blank"
              to="https://app.telepresenz.com/">
              Login
            </Link>
          </li>
          <li
            className="demoBtn"
            onClick={() => handleButtonClick(10)}
          >
            <Link
              className="levelOne"
              to="/contact">
              Request Demo
            </Link>
          </li>
        </ul>
      
    </div>
  );
}

export default Header;
