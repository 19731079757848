import NewsCardImg from "./NewsImageAssets/NewsCardImg.jpg";
import NewsCardImg1 from "./NewsImageAssets/NewsCardImg1.jpg";
import NewsCardImg2 from "./NewsImageAssets/NewsCardImg2.jpg";
import NewsCardImg3 from "./NewsImageAssets/NewsCardImg3.jpg";
import NewsCardImg4 from "./NewsImageAssets/NewsCardImg4.jpg";
import NewsCardImg5 from "./NewsImageAssets/NewsCardImg5.jpg";
import NewsCardImg6 from "./NewsImageAssets/NewsCardImg6.png";
import NewsCardImg7 from "./NewsImageAssets/NewsCardImg7.png";
import NewsCardImg8 from "./NewsImageAssets/NewsCardImg8.png";
import NewsCardImg9 from "./NewsImageAssets/NewsCardImg9.jpg";
import NewsCardImg10 from "./NewsImageAssets/NewsCardImg10.jpg";
import NewsCardImg11 from "./NewsImageAssets/NewsCardImg11.jpg";
import NewsCardImg12 from "./NewsImageAssets/NewsCardImg12.jpg";
import NewsCardImg13 from "./NewsImageAssets/NewsCardImg13.jpg";
import NewsCardImg14 from "./NewsImageAssets/NewsCardImg14.jpg";
import NewsCardImg15 from "./NewsImageAssets/NewsCardImg15.png";
import NewsCardImg16 from "./NewsImageAssets/NewsCardImg16.jpg";
import NewsCardImg17 from "./NewsImageAssets/NewsCardImg17.jpg";
import NewsCardImg18 from "./NewsImageAssets/NewsCardImg18.jpg";
import NewsCardImg19 from "./NewsImageAssets/NewsCardImg19.png";
import NewsCardImg20 from "./NewsImageAssets/NewsCardImg20.png";
import NewsCardImg21 from "./NewsImageAssets/NewsCardImg21.jpg";
import NewsCardImg22 from "./NewsImageAssets/NewsCardImg22.png";
import NewsCardImg23 from "./NewsImageAssets/NewsCardImg23.png";
import NewsCardImg24 from "./NewsImageAssets/NewsCardImg24.jpg";
import NewsCardImg25 from "./NewsImageAssets/NewsCardImg25.jpg";
import NewsCardImg26 from "./NewsImageAssets/NewsCardImg26.png";
import NewsCardImg27 from "./NewsImageAssets/NewsCardImg27.jpg";
import NewsCardImg28 from "./NewsImageAssets/NewsCardImg28.png";
import NewsCardImg29 from "./NewsImageAssets/NewsCardImg29.png";
import NewsCardImg30 from "./NewsImageAssets/NewsCardImg30.png";
import NewsCardImg31 from "./NewsImageAssets/NewsCardImg31.png";
import NewsCardImg32 from "./NewsImageAssets/NewsCardImg32.png";
import NewsCardImg33 from "./NewsImageAssets/NewsCardImg33.png";
import NewsCardImg34 from "./NewsImageAssets/NewsCardImg34.jpg";
import NewsCardImg35 from "./NewsImageAssets/NewsCardImg35.jpg";
import NewsCardImg36 from "./NewsImageAssets/NewsCardImg36.jpg";
import NewsCardImg37 from "./NewsImageAssets/NewsCardImg37.jpg";
import NewsCardImg38 from "./NewsImageAssets/NewsCardImg38.jpg";
import NewsCardImg39 from "./NewsImageAssets/NewsCardImg39.png";
import NewsCardImg40 from "./NewsImageAssets/NewsCardImg40.jpeg"
import NewsCardImg41 from "./NewsImageAssets/NewsCardImg41.png";
import NewsCardImg42 from "./NewsImageAssets/NewsCardImg42.jpg";
import NewsCardImg43 from "./NewsImageAssets/NewsCardImg43.png";
import NewsCardImg44 from "./NewsImageAssets/NewsCardImg44.png";
import NewsCardImg45 from "./NewsImageAssets/NewsCardImg45.jpg";
import NewsCardImg46 from "./NewsImageAssets/NewsCardImg46.jpg";
import NewsCardImg47 from "./NewsImageAssets/NewsCardImg47.jpg";

{/*export const NewsData = [
  {
    "id": 1,
    "date": "November 27, 2023",
    "category": "Featured",
    "title": "The Significance of Personal Protective Equipment (PPE) and AI-Based Smart Operations",
    "img": NewsCardImg1,
    "content": "In the dynamic and ever-evolving landscape of the utilities industry, ensuring the safety of field workers is paramount. The nature of their tasks often involves working in hazardous environments, making it imperative to employ protective measures. One such indispensable tool.",
    "url": "the-significance-of-personal-protective-equipment-ppe-and-ai-based-smart-operations/",
  },
  {
    "id": 2,
    "date": "October 27, 2023",
    "category": "Featured",
    "title": "Exploring the Future of Wearable Technology: What Lies Ahead?",
    "content": "Today, technology is rapidly advancing, and wearables have emerged as an interesting frontier for innovation. Wearables, to put it briefly, are devices designed to be worn on the body, providing various functionalities that are not only personalized but also conveniently",
    "img": NewsCardImg2,
    "url": "exploring-the-future-of-wearable-technology-what-lies-ahead/",
  },
  {
    "id": 3,
    "date": "September 25, 2023",
    "category": "Featured",
    "title": "Wearables vs. Handheld Devices: A Field MRO Perspective",
    "content": "In the dynamic world of Field Maintenance, Repair, and Operations (MRO), staying connected and efficient is paramount. The choice between wearables and handheld devices has become a pivotal decision for businesses aiming to optimize their field operations. As companies persist in making significant technological investments in tablets and phones, a pertinent question arises: do these investments hinder the seamless integration of wearables into business operations? Before we even make a choice, it’s important for us to understand the requirements and various scenarios in which field maintenance and repair operations are carried.",
    "img": NewsCardImg3,
    "url": "wearables-vs-handheld-devices-a-field-mro-perspective/",
  },
  {
    "id": 4,
    "date": "August 30, 2023",
    "category": "Featured",
    "title": "Harnessing Remote Assistance and Digital Workflows for Comprehensive Digitalization",
    "content": "In the current state of digitalization strategies for field operations, industries are rapidly embracing cutting-edge technologies such as the Internet of Things (IoT), Artificial Intelligence (AI), and AugmentedReality (AR) to optimize processes and enhance productivity. Real-time data collection, predictivemaintenance, and remote ",
    "img": NewsCardImg4,
    "url": "harnessing-remote-assistance-and-digital-workflows-for-comprehensive-digitalization/",
  },
  {
    "id": 5,
    "date": "August 8, 2023",
    "category": "Featured",
    "title": "From Reactive to Proactive: Real-time Data, Analytics, and Telepresenz for Maintenance Excellence",
    "content": "In today’s rapidly advancing technological landscape, businesses are constantly seeking innovative ways to optimize their operations and improve efficiency. One such approach gaining significant traction is the utilization of real-time data and analytics, along with Telepresenz for Maintenance Excellence. By",
    "img": NewsCardImg5,
    "url": "from-reactive-to-proactive-real-time-data-analytics-and-telepresenz-for-maintenance-excellence/",
  },
  {
    "id": 6,
    "date": "June 29, 2023",
    "category": "Featured",
    "title": "Stay Safe in Hazardous Environments: 3 Tips for Assisted Reality Users",
    "content": "Assisted reality devices have revolutionized the way we interact with the world around us. It has the ability to superimpose information of the real world by allowing a person to view a screen within his immediate field of vision, hands",
    "img": NewsCardImg6,
    "url": "stay-safe-in-hazardous-environments-3-key-tips-for-assisted-reality-device-users/",
  },
  {
    "id": 7,
    "date": "May 26, 2023",
    "category": "Featured",
    "title": "Reducing Downtime and Costs: The Impact of Remote MRO on Utilities Asset Management",
    "content": "Utility companies are tasked with managing complex industrial assets that require regular maintenance to ensure they perform optimally. However, managing these assets can be a daunting task, particularly when they are spread across multiple locations. Fortunately, remote MRO solutions offer.",
    "img": NewsCardImg7,
    "url": "reducing-downtime-and-costs-the-impact-of-remote-mro-on-utilities-asset-management/",
  },
  {
    "id": 8,
    "date": "April 28, 2023",
    "category": "Featured",
    "title": "Innovations and Trends Disrupting Services & MRO",
    "content": "Recently, EthAum Venture Partners hosted a virtual thought leadership panel on the topic “Innovations Disrupting Services & MRO Industry”. The panel featured a diverse group of experts, including Indu Tolia, CEO, Telepresenz, who shared her valuable insights and trends observed",
    "img": NewsCardImg8,
    "url": "innovations-and-trends-disrupting-services-mro/",
  },
  {
    "id": 9,
    "date": "April 4, 2023",
    "category": "Customer Stories",
    "title": "HAECO Group deploys AR technology to enhance maintenance operation and inspection",
    "content": "HAECO Group, one of the world’s leading aviation solutions provider, has begun deploying augmented reality (AR) technology from Telepresenz® to enhance the efficiency and quality of its maintenance operation and inspection.",
    "img": NewsCardImg9,
    "url": "haeco-group-deploys-ar-technology-to-enhance-maintenance-operation-and-inspection/",
  },
  {
    "id": 10,
    "date": "March 24, 2023",
    "category": "Featured",
    "title": "Offset Environmental Impact with Remote MRO",
    "content": "Advancement of technologies has opened numerous possibilities to support repair and maintenance operations remotely. Reduced travel and efficient use of resources can be an effective way to offset environmental impact with remote mro while still ensuring that equipment and systems",
    "img": NewsCardImg10,
    "url": "offset-environmental-impact-with-remote-mro/",
  },
  {
    "id": 11,
    "date": "February 15, 2023",
    "category": "Featured",
    "title": "Maximizing Efficiency and Safety in Mining Operations",
    "content": "Maximizing efficiency and ensuring safety in the mining industry is essential for both worker well-being and environmental protection. As a critical sector that provides raw materials for various industries, the mining industry plays a vital role in driving economic growth",
    "img": NewsCardImg11,
    "url": "maximizing-efficiency-and-safety-in-mining-operations/",
  },
  {
    "id": 12,
    "date": "January 17, 2023",
    "category": "Featured",
    "title": "2023 Trends to Watch in Connected Worker Solutions",
    "content": "As we start the new year, it’s important to keep an eye on the latest trends in connected worker solutions. These technologies are becoming increasingly important for companies looking to improve the efficiency, safety, and communication of their field workers",
    "img": NewsCardImg12,
    "url": "2023-trends-to-watch-in-connected-worker-solutions/",
  },
  {
    "id": 13,
    "date": "January 4, 2023",
    "category": "News",
    "title": "Telepresenz® Selected as One of Top 12 Ventures in LTP Startup Challenge for MRO Solutions in Aviation Industry",
    "content": "Telepresenz® was recently selected as one of the top 12 ventures to participate in the Lufthansa Technik Philippines (LTP) Start-up Challenge, a program designed to support and promote innovative start-ups in the field of maintenance, repair, and overhaul (MRO) in the aviation industry.",
    "img": NewsCardImg13,
    "url": "telepresenz-selected-as-one-of-top-12-ventures-in-ltp-startup-challenge-for-mro-solutions-in-aviation-industry/",
  },
  {
    "id": 14,
    "date": "December 19, 2022",
    "category": "News",
    "title": "Telepresenz® for Seamless Rail Operations During FIFA World Cup 2022",
    "content": "We are excited to announce that Telepresenz® provides the underlying real-time support platform to manage rail operations in Doha.",
    "img": NewsCardImg14,
    "url": "telepresenz-for-seamless-rail-operations-during-fifa-world-cup-2022/",
  },
  {
    "id": 15,
    "date": "September 13, 2022",
    "category": "Downloads",
    "title": "Emergency Response PDF",
    "content": "Download our White Paper",
    "img": NewsCardImg15,
    "url": "emergency-response-pdf/",
  },
  {
    "id": 16,
    "date": "September 2, 2022",
    "category": "Press Releases",
    "title": "CBT Partners with Telepresenz to Expand Connected Worker Capabilities",
    "content": "Orange, CA, Sept. 01, 2022 (GLOBE NEWSWIRE) — CBT, a woman-owned Domain Expert Integrator, today announced its partnership with Telepresenz, a software solution company that specializes in digitizing mobile workforces. Together, CBT and Telepresenz will offer Connected Worker solutions including Remote Mentoring, Digital Workflow, and Knowledge Capture.In the dynamic and ever-evolving landscape of the utilities industry, ensuring the safety of field workers is paramount. The nature of their tasks often involves working in hazardous environments,",
    "img": NewsCardImg16,
    "url": "cbt-partners-with-telepresenz-to-expand-connected-worker-capabilities/",
  },
  {
    "id": 17,
    "date": "September 1, 2022",
    "category": "Press Releases",
    "title": "New Strategic Partnership Announcement: Telepresenz & SoftwareONE Hong Kong",
    "content": "We are proud to announce a new strategic partnership with SoftwareONE Hong Kong by providing workers with all the tools they need to execute complex tasks in the field efficiently and safely using wearable devices.",
    "img": NewsCardImg17,
    "url": "softwareone-hong-kong-teams-up-with-telepresenz/",
  },
  {
    "id": 18,
    "date": "August 22, 2022",
    "category": "News",
    "title": "How to Determine if Wearables are Right for Your Workforce",
    "content": "Wearables are the latest cutting-edge technology that is becoming commonplace employee items in today’s business world. Organizations are rapidly integrating these wearables in their organizational practice and strategy to maximize their numerous benefits.",
    "img": NewsCardImg18,
    "url": "how-to-determine-if-wearables-are-right-for-your-workforce/",
  },
  {
    "id": 19,
    "date": "August 3, 2022",
    "category": "Press Releases",
    "title": "Operations Enablement and Remote Support software for Vuzix Smart Glasses through Converge IoT and Telepresenz",
    "content": "Converge IoT, a National Distributor/Primary Agent for the T-Mobile for Business Channel Partner Program, will sell Vuzix smart glasses utilizing Telepresenz software across multiple industry verticals using T-Mobile’s 5G network.",
    "img": NewsCardImg19,
    "url": "operations-enablement-and-remote-support-software-for-vuzix-smart-glasses/",
  },
  {
    "id": 20,
    "date": "August 1, 2022",
    "category": "Customer Stories",
    "title": "Extending Digital Twin Software to Frontline Workers",
    "content": "Telepresenz and Antea announce a strategic partnership to integrate and jointly pursue projects in the energy sector.",
    "img": NewsCardImg20,
    "url": "telepresenz-extends-antea-digital-twin-to-frontline-workers/",
  },
  {
    "id": 21,
    "date": "August 1, 2022",
    "category": "Featured",
    "title": "Moziware® Teams Up With Telepresenz® To Launch New Pocket Connected Worker",
    "content": "Moziware® today announced a new partnership that combines their lightweight pocket-sized hands-free wearable CIMO (pronounced ‘see-more’) with Telepresenz® integrated connected worker solutions. Immediately available are Telepresenz Connected Worker and Remote Operations bundled with CIMO.",
    "img": NewsCardImg21,
    "url": "moziware-teams-up-with-telepresenz-to-launch-new-pocket-connected-worker/",
  },
  {
    "id": 22,
    "date": "August 1, 2022",
    "category": "Featured",
    "title": "Cutting-edge MRO Tech Training In The Field",
    "content": "Download our White Paper",
    "img": NewsCardImg22,
    "url": "mro-tech-training/",
  },
  {
    "id": 23,
    "date": "July 18, 2022",
    "category": "News",
    "title": "Moziware® and Telepresenz® team up to launch a new Pocket Connected Worker",
    "content": "Moziware® today announced a new partnership that combines their lightweight pocket-sized hands-free wearable CIMO (pronounced ‘see-more’) with Telepresenz® integrated connected worker solutions. Immediately available are Telepresenz Connected Worker and Remote Operations bundled with CIMO.",
    "img": NewsCardImg23,
    "url": "moziware-and-telepresenz-team-up-to-launch-a-new-pocket-connected-worker/",
  },
  {
    "id": 24,
    "date": "May 9, 2022",
    "category": "Downloads",
    "title": "Remote digital operations, monitoring, and training capabilities White Paper",
    "content": "Download our White Paper",
    "img": NewsCardImg24,
    "url": "telepresenz-white-paper/",
  },
  {
    "id": 25,
    "date": "February 25, 2022",
    "category": "News",
    "title": "Telepresenz® Partners with Antea™ to Extend Digital Twin to The Frontline Worker",
    "content": "Telepresenz and Antea announce a strategic partnership to integrate and jointly pursue projects in the energy sector.",
    "img": NewsCardImg25,
    "url": "telepresenz-partners-with-antea-to-extend-digital-twin-to-the-frontline-worker/",
  },
  {
    "id": 26,
    "date": "February 21, 2022",
    "category": "Deployment Case",
    "title": "Asset Integrity Management and Remote Worker White Paper",
    "content": "Download the Asset Integrity Management and Remote Worker White Paper",
    "img": NewsCardImg26,
    "url": "asset-integrity-management-and-remote-worker-white-paper/",
  },
  {
    "id": 27,
    "date": "February 21, 2022",
    "category": "Client Resources",
    "title": "Automated industrial technologies are some of the most powerful tools at the hands of modern business owners, and they are constantly changing and getting better.",
    "img": NewsCardImg27,
    "url": "automated-industrial-technologies/",
  },
  {
    "id": 28,
    "date": "November 3, 2021",
    "category": "News",
    "title": "Telepresenz Earns “Vendor of the Year” Award at the 2021 Ingram Micro Experience Event",
    "content": "The prestigious accolade celebrates Telepresenz’s partner-led strategy, exemplary level of engagement and focus on mutual business growth",
    "img": NewsCardImg28,
    "url": "telepresenz-earns-vendor-of-the-year-award-at-the-2021-ingram-micro-experience-event/",
  },
  {
    "id": 29,
    "date": "November 1, 2021",
    "category": "News",
    "title": "AMGC Webinar Summary",
    "content": "Want to Lean More About Remote Assistance for Your Industry?",
    "img": NewsCardImg29,
    "url": "amgc-webinar-summary/",
  },
  {
    "id": 30,
    "date": "October 13, 2021",
    "category": "Press Releases",
    "title": "Industrial Wearables – why they’re important and how this new technology is helping industrial workers.",
    "content": "Wearables have been getting a lot of attention recently, with major tech companies like Apple and Samsung leading the charge to mass-market smartwatches and other wearables.",
    "img": NewsCardImg29,
    "url": "industrial-wearables/",
  },
]*/}

export const NewsData = [

  {
    "id": 1,
    "date":"November 11, 2019",
    "category": "News",
    "title": "Meet Indu Tolia, Creator and Founder of CARE4D and Now, Telepresenz®",
    "content":"As an experienced business operator, investor and entrepreneur with over 22 years of experience in the delivery of technology solutions and extensive experience leading IT programs, Indu Tolia has quite an extensive and impressive resume....",
    "img": NewsCardImg47,
    "url": "meet-indu-tolia-creator-and-founder-of-care4d-and-now-telepresenz/",
  },

  {
    "id": 2,
    "date":"December 11, 2019",
    "category": "News",
    "title": "2019 Advancements in AR",
    "content":"As time passes, technology improves. This year we’ve seen advancements in Augmented Reality regarding processors, training, and costs. With projected investments rising the worth of the AR/VR industry to $45 billion dollars, it’s becoming clear.... engineers and investors are devoted to enhancing the quality and speed of this innovative software.",
    "img": NewsCardImg46,
    "url": "2019-advancements-in-ar/",
  },

  {
    "id": 3,
    "date":"January 14, 2020",
    "category": "News",
    "title": "RealWear Ready Partner Spotlight: CARE4D",
    "content":"The Telepresenz® solution from CARE4D is now presenting its certified application for RealWear HMT-1 devices. This innovative technology offers a streamlined remote mentoring solution equipped with integrated workflows for enterprise teams.... specifically built for RealWear HMT-1 devices. ",
    "img": NewsCardImg45,
    "url": "realwear-ready-partner-spotlight-care4d/",
  },

  {
    "id": 4,
    "date":"March 20, 2020",
    "category": "News",
    "title": "Elevator Service and Remote Mentor",
    "content":"With the surge in residential high-rise buildings in major cities, the elevator industry has reached new heights. MSN reported it was one of the fastest-growing industries of 2018. With limited land space, developers are forced to build up to support local populations....",
    "img": NewsCardImg44,
    "url": "elevator-service-and-remote-mentor/",
  },
  {
    "id": 5,
    "date":"April 15, 2020",
    "category": "News",
    "title": "Press Release – Apr 14, 2020",
    "content":"CARE4D Announces United States Patent and Trademark Approval on Telepresenz® EDISON, NJ – CARE4D is pleased to announce the grants of USPTO Registration Nos: 6011127 & 6011128 for the Telepresenz® solution. The trademarks include the logo with the word.... “TELEPRESENZ” stacked on “VIRTUALIZE THE EXPERT”.",
    "img": NewsCardImg43,
    "url": "press-release-apr-14-2020/",
  },

  {
    "id": 6,
    "date":"April 16, 2020",
    "category": "News",
    "title": "How Remote Mentor Solutions Can Help in Times of COVID-19",
    "content":"Unprecedented times call for novel solutions companies can implement to continue running and adapting to a rapidly changing world. Adversities such as restrictions in the number of employees allowed on job sites, delayed production, altered....",
    "img": NewsCardImg42,
    "url": "how-remote-mentor-solutions-can-help-in-times-of-covid-19/",
  },
  {
    "id": 7,
    "date":"June 1, 2020",
    "category": "News",
    "title": "How Remote Assistance Is Helping Companies in Times of COVID",
    "content":"Many companies thought the need to go digital would develop years down the line because their current processes were “business as usual”. Due to COVID-19, these corporations were forced to adopt a completely virtual workforce overnight.... ",
    "img": NewsCardImg41,
    "url": "how-remote-assistance-is-helping-companies-in-times-of-covid/",
  },
  {
    "id": 8,
    "date":"June 16, 2020",
    "category": "Client Resources",
    "title": "Terms & Conditions",
    "content":"Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using the https://app.telepresenz.com/ website (the “Service”) operated by C.A.R.E. Core Augmented Reality Education LLC DBA CARE4D (“us”, “we”, or “our”) . Your access to....", 
    "img": NewsCardImg40,
    "url": "termsconditions/",
  },

  {
    "id": 9,
    "date":"July 9, 2020",
    "category": "News",
    "title": "Utilizing Remote Assistance Software to Get More Done",
    "content":"With so many companies needing technology to maintain business continuity, remote assistance software is becoming highly crucial across varied industries.Throughout the past few months, businesses and customers across the globe have adopted various new....",
    "img": NewsCardImg39,
    "url": "utilizing-remote-assistance-software-to-get-more-done/",
  },
  
  {
    "id": 10,
    "date":"September 16, 2020",
    "category": "News",
    "title": "Vuzix Expands HIPAA Compliant Healthcare Offerings with the Addition of Telepresenz from CARE4D for the M400 Smart Glasses",
    "content":"Telepresenz® from Care4D offers hands-free augmented reality functionality for healthcare and enterprise professionals.... ",
    "img": NewsCardImg38,
    "url": "vuzix-expands-hipaa-compliant-healthcare-offerings-with-the-addition-of-telepresenz-from-care4d-for-the-m400-smart-glasses/",
  },


  {
    "id": 11,
    "date":"May 5, 2021",
    "category": "News",
    "title": "Free Real-time remote assistance and workflows with every device you purchase",
    "content":"Industrial enterprise customers everywhere are discovering a unique way to manage business continuity, improve service levels, train employees, improve compliance, and reduce costs. CARE4D and Ingram Micro Inc., a leading global technology....",
    "img": NewsCardImg37,
    "url": "free-real-time-remote-assistance-and-workflows-with-every-device-you-purchase/",
  },


  {
    "id": 12,
    "date":"May 13, 2021",
    "category": "News",
    "title": "NJ Tech Weekly interviews our Founders",
    "content":"“Telepresenz (Edison) has an unusual origin story. Wife and husband team Indu and Ritesh Tolia had created CARE4D (Edison), an edtech startup, in 2014, with the idea of bringing immersive technology — like augmented reality (AR) and virtual reality.... ",
    "img": NewsCardImg36,
    "url": "nj-tech-weekly-interviews-our-founders/",
  },

  {
    "id": 13,
    "date":"June 17, 2021",
    "category": "Downloads",
    "title": "Telepresenz® Platform Capabilities",
    "content":"Download the Telepresenz® Platform Capabilities",
    "img": NewsCardImg35,
    "url": "telepresenz-platform-capabilities/",
  },

  {
    "id": 14,
    "date":"June 21, 2021",
    "category": "Downloads",
    "title": "Industrial Wearable Guide",
    "content":"Download the Industrial Wearable Guide",
    "img": NewsCardImg34,
    "url": "industrial-wearable-guide/",
  },
  { 
    "id": 15,
    "date":"July 22, 2021",
    "category": "Press Releases",
    "title": "Telepresenz® Experiences Global Hyper-Growth Delivering Industrial Digital Transformation for The Connected Worker Through Smart Glass Technology",
    "content":"",
    "img": NewsCardImg33,
    "url": "telepresenz-experiences-global-hyper-growth-delivering-industrial-digital-transformation-for-the-connected-worker-through-smart-glass-technology/",
  },

  { 
    "id": 16,
    "date":"August 10, 2021",
    "category": "Downloads",
    "title": "Remote Assist and Inspection Use Cases",
    "content":"Download the Remote Assist and Inspection Use Cases",
    "img": NewsCardImg32,
    "url": "remote-assist-and-inspection-use-cases/",
  },

  { 
    "id": 17,
    "date":"September 14, 2021",
    "category": "News",
    "title": "Successful Deployment: Utilities",
    "content":"A sizeable mobile technician workforce who are contractors at a National Utility Board is required to be supported and supervised as they perform their regular maintenance and repair operations, with safety being paramount. Expert supervisors are often....",
    "img": NewsCardImg31,
    "url": "successful-deployment-utilities/",
 },
 
  {
    "id": 18,
    "date": "October 13, 2021",
    "category": "Press Releases",
    "title": "Industrial Wearables – why they’re important and how this new technology is helping industrial workers.",
    "content": "Wearables have been getting a lot of attention recently, with major tech companies like Apple and Samsung leading the charge to mass-market smartwatches and other wearables....",
    "img": NewsCardImg30,
    "url": "industrial-wearables/",
  },

  {
    "id": 19,
    "date": "November 1, 2021",
    "category": "News",
    "title": "AMGC Webinar Summary",
    "content": "Want to Lean More About Remote Assistance for Your Industry?",
    "img": NewsCardImg29,
    "url": "amgc-webinar-summary/",
  },

  {
    "id": 20,
    "date": "November 3, 2021",
    "category": "News",
    "title": "Telepresenz Earns “Vendor of the Year” Award at the 2021 Ingram Micro Experience Event",
    "content": "Edison, NJ – Telepresenz is pleased to be named IoT “Vendor of the Year” by Ingram Micro Inc. The annual Ingram Micro “Vendor of the Year” awards celebrate the success of more than two dozen emerging and established-channel focused vendor organizations across .... ",
    "img": NewsCardImg28,
    "url": "telepresenz-earns-vendor-of-the-year-award-at-the-2021-ingram-micro-experience-event/",
  },

  {
    "id": 21,
    "date": "February 21, 2022",
    "category": "Client Resources",
    "title":"How Automated Industrial Technologies Keep Work Productivity on the Rise",
    "content": "Automated industrial technologies are some of the most powerful tools at the hands of modern business owners, and they are constantly changing and getting better. While industrial automation has mostly been used in manufacturing...",
    "img": NewsCardImg27,
    "url": "automated-industrial-technologies/",
  },

  {
    "id": 22,
    "date": "February 21, 2022",
    "category": "Deployment Case",
    "title": "Asset Integrity Management and Remote Worker White Paper",
    "content": "Download the Asset Integrity Management and Remote Worker White Paper",
    "img": NewsCardImg26,
    "url": "asset-integrity-management-and-remote-worker-white-paper/",
  },

  {
    "id": 23,
    "date": "February 25, 2022",
    "category": "News",
    "title": "Telepresenz® Partners with Antea™ to Extend Digital Twin to The Frontline Worker",
    "content": "Telepresenz and Antea announce a strategic partnership to integrate and jointly pursue projects in the energy sector.Telepresenz’s award winning remote assistance software platform runs on hands-free wearable devices (such as Realwear™, Vuzix™....",
    "img": NewsCardImg25,
    "url": "telepresenz-partners-with-antea-to-extend-digital-twin-to-the-frontline-worker/",
  },






  {
    "id": 24,
    "date": "May 9, 2022",
    "category": "Downloads",
    "title": "Remote digital operations, monitoring, and training capabilities White Paper",
    "content": "Download our White Paper",
    "img": NewsCardImg24,
    "url": "remote-digital-operations-monitoring-and-training-capabilities-white-paper/",
  },

  {
    "id": 25,
    "date": "July 18, 2022",
    "category": "News",
    "title": "Moziware® and Telepresenz® team up to launch a new Pocket Connected Worker",
    "content": "Moziware® today announced a new partnership that combines their lightweight pocket-sized hands-free wearable CIMO (pronounced ‘see-more’) with Telepresenz® integrated connected ....",
    "img": NewsCardImg23,
    "url": "moziware-and-telepresenz-team-up-to-launch-a-new-pocket-connected-worker/",
  },

  /*{
    "id": 26,
    "date": "August 1, 2022",
    "category": "Featured",
    "title": "Cutting-edge MRO Tech Training In The Field",
    "content": "Download our White Paper",
    "img": NewsCardImg22,
    "url": "mro-tech-training/",
  },
  
  {
    "id": 27,
    "date": "August 1, 2022",
    "category": "Customer Stories",
    "title": "Extending Digital Twin Software to Frontline Workers",
    "content": "Telepresenz and Antea announce a strategic partnership to integrate and jointly pursue projects in the energy sector.",
    "img": NewsCardImg20,
    "url": "telepresenz-extends-antea-digital-twin-to-frontline-workers/",
  },*/

  {
    "id": 26,
    "date": "August 3, 2022",
    "category": "Press Releases",
    "title": "Operations Enablement and Remote Support software for Vuzix Smart Glasses through Converge IoT and Telepresenz",
    "content": "Converge IoT, a National Distributor/Primary Agent for the T-Mobile for Business Channel Partner Program.... ",
    "img": NewsCardImg19,
    "url": "operations-enablement-and-remote-support-software-for-vuzix-smart-glasses/",
  },

  {
    "id": 27,
    "date": "August 22, 2022",
    "category": "News",
    "title": "How to Determine if Wearables are Right for Your Workforce",
    "content": "Wearables are the latest cutting-edge technology that is becoming commonplace employee items in today’s business world. Organizations are rapidly integrating these wearables in their organizational practice and strategy to maximize their numerous.... ",
    "img": NewsCardImg18,
    "url": "how-to-determine-if-wearables-are-right-for-your-workforce/",
  },

  {
    "id": 28,
    "date": "September 1, 2022",
    "category": "Press Releases",
    "title": "New Strategic Partnership Announcement: Telepresenz & SoftwareONE Hong Kong",
    "content": "We are proud to announce a new strategic partnership with SoftwareONE Hong Kong by providing workers with all the tools they need to execute complex tasks in the field efficiently and safely using wearable devices....",
    "img": NewsCardImg17,
    "url": "softwareone-hong-kong-teams-up-with-telepresenz/",
  },

  {
    "id": 29,
    "date": "September 2, 2022",
    "category": "Press Releases",
    "title": "CBT Partners with Telepresenz to Expand Connected Worker Capabilities",
    "content": "Orange, CA, Sept. 01, 2022 (GLOBE NEWSWIRE) — CBT, a woman-owned Domain Expert Integrator, today announced its partnership with Telepresenz, a software solution company that specializes in digitizing mobile workforces. Together, CBT and Telepresenz .....",
    "img": NewsCardImg16,
    "url": "cbt-partners-with-telepresenz-to-expand-connected-worker-capabilities/",
  },

  {
    "id": 30,
    "date": "September 13, 2022",
    "category": "Downloads",
    "title": "Emergency Response PDF",
    "content": "Download our White Paper",
    "img": NewsCardImg15,
    "url": "emergency-response-pdf/",
  },

  /*{
    "id": 33,
    "date": "December 19, 2022",
    "category": "News",
    "title": "Telepresenz® for Seamless Rail Operations During FIFA World Cup 2022",
    "content": "We are excited to announce that Telepresenz® provides the underlying real-time support platform to manage rail operations in Doha.",
    "img": NewsCardImg14,
    "url": "telepresenz-for-seamless-rail-operations-during-fifa-world-cup-2022/",
  },*/

  {
    "id": 31,
    "date": "January 4, 2023",
    "category": "News",
    "title": "Telepresenz® Selected as One of Top 12 Ventures in LTP Startup Challenge for MRO Solutions in Aviation Industry",
    "content": "Telepresenz® was recently selected as one of the top 12 ventures to participate in the Lufthansa Technik Philippines (LTP) Start-up Challenge, a program designed to support....  ",
    "img": NewsCardImg13,
    "url": "telepresenz-selected-as-one-of-top-12-ventures-in-ltp-startup-challenge-for-mro-solutions-in-aviation-industry/",
  },

  /*{
    "id": 35,
    "date": "January 17, 2023",
    "category": "Featured",
    "title": "2023 Trends to Watch in Connected Worker Solutions",
    "content": "As we start the new year, it’s important to keep an eye on the latest trends in connected worker solutions. These technologies are becoming increasingly important for companies looking to improve the efficiency, safety, and communication of their field workers",
    "img": NewsCardImg12,
    "url": "2023-trends-to-watch-in-connected-worker-solutions/",
  },

  {
    "id": 36,
    "date": "February 15, 2023",
    "category": "Featured",
    "title": "Maximizing Efficiency and Safety in Mining Operations",
    "content": "Maximizing efficiency and ensuring safety in the mining industry is essential for both worker well-being and environmental protection. As a critical sector that provides raw materials for various industries, the mining industry plays a vital role in driving economic growth",
    "img": NewsCardImg11,
    "url": "maximizing-efficiency-and-safety-in-mining-operations/",
  },

  {
    "id": 37,
    "date": "March 24, 2023",
    "category": "Featured",
    "title": "Offset Environmental Impact with Remote MRO",
    "content": "Advancement of technologies has opened numerous possibilities to support repair and maintenance operations remotely. Reduced travel and efficient use of resources can be an effective way to offset environmental impact with remote mro while still ensuring that equipment and systems",
    "img": NewsCardImg10,
    "url": "offset-environmental-impact-with-remote-mro/",
  },*/

  {
    "id": 32,
    "date": "April 4, 2023",
    "category": "Customer Stories",
    "title": "HAECO Group deploys AR technology to enhance maintenance operation and inspection",
    "content": "HAECO Group, one of the world’s leading aviation solutions provider, has begun deploying augmented reality (AR) technology from Telepresenz® to enhance the efficiency and quality....",
    "img": NewsCardImg9,
    "url": "haeco-group-deploys-ar-technology-to-enhance-maintenance-operation-and-inspection/",
  },

]

