//https://telepresenz.com/meet-indu-tolia-creator-and-founder-of-care4d-and-now-telepresenz/
//Meet Indu Tolia, Creator and Founder of CARE4D and Now, Telepresenz®.
import React from 'react';
import bannerimg48 from "../News/NewsData/NewsImageAssets/NewsCardImg47.jpg";
import './newsDetails.css'

const NewsDetails48 = () => {
  return (
    <>
      <div className='newsDetails'>
          <h1>Meet Indu Tolia, Creator and Founder of CARE4D and Now, Telepresenz®.</h1>
          <div className='banner-Img '>
            <img src={bannerimg48} alt="Meet Indu Tolia, Creator and Founder of CARE4D and Telepresenz" />
          </div>
          <p className='subHeader'>Meet Indu Tolia, Creator and Founder of CARE4D and Now, <a href="https://www.telepresenzdemo.com/" target='_blank'>Telepresenz®</a></p>
          <p>As an experienced business operator, investor and entrepreneur with over 22 years of experience in the delivery of technology solutions and extensive 
            experience leading IT programs, Indu Tolia has quite an extensive and impressive resume. Before her most current endeavors, Indu and her team have 
            worked on several AR/VR and MR applications at CARE4D. These include a variety of educational AR products and projects allowing students to master 
            topics ranging from Mathematics and English to Science curriculums covering Earth, Physical, and Life Sciences. Other projects included the 
            integration of wearable technologies to create an interactive Virtual Solar System using the Hololens, Virtual control of unmanned rovers to 
            detect areas of threat using the HTC-Vive VR system, VR Speech Training using Oculus Rift, to name a few. With these profound projects, a strong 
            engineering background, and a fascination for all things ‘Technology’, Indu devoted herself to improving and encouraging education and remote 
            guidance for the Enterprise with <a href="https://www.telepresenzdemo.com/" target='_blank'>Telepresenz®.</a> In her words, “Augmented Reality is a leading-edge 
            immersive technology that naturally lends itself to engage, inspire and educate folks in any environment.”</p>
            <p className='subHeader'>APPRECIATION FOR REMOTE SUPPORT</p>
            <p>Indu witnessed firsthand how residents in the Emergency Rooms were consulting with senior doctors to pinpoint and diagnose patients’ conditions 
                in pursuit save lives. She was moved by their success. Seeing how other companies were saving thousands of dollars by getting remote teams the 
                assistance they needed with no delays, touched and sparked Indu’s core values of enhancing education and training with technology. Indu 
                expresses her gratitude to be able to walk alongside her customers as they realize the value that <a href="https://www.telepresenzdemo.com/" target='_blank'>Telepresenz® </a>
                is bringing. “…it is what keeps us passionate and committed to what we are doing!”</p>
            <p className='subHeader'>CARE4D</p>
            <p>CARE4D has a K-12 supplemental education curriculum product infused with AR. Indu was concerned when she saw the outdated methods in which her 
                children were learning and decided the ability to engage and teach this new generation of learners, needs to be revolutionized. Through meticulous 
                consideration and devotion to perfect this product, Indu worked with educators and software engineers alike to achieve the epitome of an evolved 
                and effective method of teaching. Having seen her success in this product only blossomed more innovative ideas to improve not only the knowledge 
                of our children but also increase the productivity of technological Enterprises by providing real-time training and guidance. </p>
            <p><a href="https://www.telepresenzdemo.com/" target='_blank'>TELEPRESENZ® </a></p> 
            <p>With this came Indu’s next venture, <a href="https://www.telepresenzdemo.com/" target='_blank'>Telepresenz®.</a>
               <a href="https://www.telepresenzdemo.com/" target='_blank'> Telepresenz®</a> is designed with the same technology frameworks as CARE4D. This software, when used with compatible 
               glasses, allows Subject Matter Experts to connect with remote teams and provide real-time mentoring and instruction from anywhere in the world. The need for 
               this technology was apparent when Indu realized that most organizations have fewer Senior SMEs and larger teams of less experienced folks that are in the 
               field. Constant communication and consultation with engineers in this position have proven to be in high demand as they often need assistance when it 
               comes to diagnosing and resolving issues as they work on complex equipment or processes. When utilizing <a href="https://www.telepresenzdemo.com/" target='_blank'>Telepresenz® </a>
               and the various AR toolsets built within the platform, organizations can be assured with greater certainty that not only will there be no errors in the work 
               being performed by their remote teams, but also that knowledge transfer and retention is best as folks are learning on the job while under the supervision of 
               an experienced SME.</p>
            <p className='subHeader'>PASSION KNOWS NO LIMITS</p>
            <p>Indu Tolia continues to impress the engineering industry with her innovative ideas and motivation to design a better tomorrow. Her passion knows no limits 
                because she truly loves her work and strives for improvement. She shows much gratitude for her team as they share her values, taking pride in the work 
                they do. The hard work of her and her team are validated by satisfied and impressed customers. Indu’s drive for the betterment of enterprise efficiencies 
                gives hope to admirers of technological innovation across different verticals and internationally as well.</p>
      </div>
    </>
  )
}

export default NewsDetails48;
