//https://telepresenz.com/asset-integrity-management-and-remote-worker-white-paper/
//Asset Integrity Management and Remote Worker White Paper
import React, { useState } from 'react';
import {Card, CardGroup} from 'react-bootstrap';
import contactUs from './NewsDetailsAssets/contact-us.png';
import './newsDetails.css';

const NewsDetails27 = () => {

  const [showcard1, setShowcard1] = useState(false) ; 
  const [showcard2, setShowcard2] = useState(false) ;

  const cardData1=[
    {id:1, content:"Remote Assist Video Meetings & Collaboration"},
    {id:2, content:"Connected Worker Instructions"},
    {id:3, content:"Control Room Coordination"},
    {id:4, content:"IIoT & Product Data Management"},
    {id:5, content:"Augmented Reality"}
  ];

  const cardData2=[
    {id:1, title:"Customization", content:"This is just a simple text made for this unique and awesome template, you can easily edit it as you want."},
    {id:2, title:"Integration", content:"This is just a simple text made for this unique and awesome template, you can easily edit it as you want."},
    {id:3, title:"Security", content:"This is just a simple text made for this unique and awesome template, you can easily edit it as you want."}
  ];

  const handleClickCard1 = () =>{
    setShowcard1(true);
    setShowcard2(false);
  };

  const handleClickCard2 = () =>{
     setShowcard1(false);
     setShowcard2(true);
  };

  return (
    <>
      <div className='newsDetails'>
          <h1>Asset Integrity Management and Remote Worker White Paper</h1>
          <p>Asset integrity management meshes with cutting-edge<a href="https://telepresenz.com/product/" target='_blank'> remote worker technology</a> to reduce human error to increase productivity, 
            boost cost savings, and more! Download the PDF to learn all the details.</p>
          <div className='row'>
            <div className='leftcolumn'>
                  <img className='circularImg'src={contactUs} alt="Contact Us" />
                  <button className='buttonContainer'>Download Our Free Guide To Enterprise Wearables</button>
            </div>
            <div className='rightcolumn' >
                <h3>Use it on most enterprise wearables including:</h3> 
                <button className='cardEnableButton' onClick={handleClickCard1}>Powerful Features</button>
                <button className='cardEnableButton' onClick={handleClickCard2}>Proven Solutions </button>
                <div className='cardContainer'>
                     {showcard1 && cardData1.map(card=>(
                      <div key={card.id} className='cardContent'>
                          <Card>
                            <Card.Body>
                            <Card.Text>{card.content}</Card.Text>
                            </Card.Body>
                          </Card>
                        
                      </div>
                    ))}
                </div>
                <div className='cardContainer'>
                  { showcard2 && cardData2.map(card=>(
                    <div key={card.id} className='cardContent'>
                        <Card>
                           <Card.Body>
                            <Card.Title>{card.title}</Card.Title>
                            <Card.Text>{card.content}</Card.Text>
                           </Card.Body>
                        </Card>
                    </div>

                  ))}
                  
                </div>

            </div>
            
                 
          </div>
      </div> 
            
    </>
  )
}

export default NewsDetails27;
