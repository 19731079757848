import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { RiInstagramFill } from "react-icons/ri";
import { FaLinkedin } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import TelepresenzLogo from "../../assets/Telepresenz-Logo.png";
import { useEffect } from "react";
import "./Footer.css";

function Footer() {

  const d = new Date();
  let year = d.getFullYear();

  const [activeFooterLink, setActiveFooterLink] = useState(null);

    useEffect(() => {
      const updateHeaderSubMenu = (linkText) => {
        const headerSubMenuList = document.querySelectorAll('.subMenuList .subMenu');
        headerSubMenuList.forEach((item) => {
          const link = item.querySelector('a').textContent.trim();
          const isActive = item.classList.contains('active');
          if (link === linkText) {
            item.classList.add('active');
            console.log("active link:",link);
            console.log("linktext ", linkText);
          } else {
            item.classList.remove('active');
            console.log("not link:",link);
            console.log("linktext ", linkText);
          }
         
        });
      };
    
      updateHeaderSubMenu(activeFooterLink); // Call the function with the activeFooterLink state
    }, [activeFooterLink]); // Add activeFooterLink as a dependency to useEffect
    
  const handleFooterLink = (linkText) => {
    const headerLinks = document.querySelectorAll('.levelOne');
        headerLinks.forEach((link) => {
        link.classList.remove('active');
    });
    const headerSubmenu = document.querySelectorAll('.subMenuList .subMenu');
         headerSubmenu.forEach((link) =>{
         link.classList.remove('active');
         });


    setActiveFooterLink(linkText); // Update activeFooterLink when a footer link is clicked
  };


  return (
    <>
      <div className="footerSection">
        <div className="footercol">
          <div>
            <img width="200px" alt="TelepresenzLogo" src={TelepresenzLogo} />
          </div>
          <div className="footerAboutUs">
            <p>
              Telepresenz - Empower your mobile workforce through blend &
              unification of Next Gen Smart Tech Platforms like
              Augmented/Assisted Reality (AR), Artificial Intelligence (AI), and
              Machine Learning (ML)
            </p>
          </div>
          <div className="socialIconsSection">
            <div className="socialIcons">
              <Link to="https://www.facebook.com/telepresenz" target="_blank">
                <FaFacebookF />
              </Link>
            </div>
            <div className="socialIcons">
              <Link
                to="https://www.youtube.com/channel/UCs7mcMg_yngd9WVV3tYkuPA"
                target="_blank"
              >
                <IoLogoYoutube />
              </Link>
            </div>
            <div className="socialIcons">
              <Link to="https://www.instagram.com/telepresenz/" target="_blank">
                <RiInstagramFill />
              </Link>
            </div>
            <div className="socialIcons">
              <Link
                to="https://www.linkedin.com/uas/login?session_redirect=%2Fcompany%2F5231015%2F"
                target="_blank"
              >
                <FaLinkedin />
              </Link>
            </div>
            <div className="socialIcons">
              <Link
                to="https://twitter.com/Telepresenz/status/1296160415433580545"
                target="_blank"
              >
                <FaXTwitter />
              </Link>
            </div>
          </div>
        </div>
        <div className="footercol footerDemoSec">
        <div className="footerLink">
            <Link to="/contact"  onClick={() => handleFooterLink('Contact Us')}>Contact Us</Link>
        </div>
          <div className="footerLink">
            <Link to="/news"  onClick={() => handleFooterLink('News')}>News </Link>
          </div>
          <div className="footerLink">
            <Link to="/blogs"  onClick={() => handleFooterLink('Blogs')}>Blogs </Link>
          </div>
         
          
          {/*<div>
            <Link to="/career">Careers</Link>
          </div>*/}
        </div>
        <div className="footercol">
          <div>
            <div>
              <h4>Get In Touch</h4>
            </div>
            <p className="footerEmail">
              <strong>Email: </strong>info@telepresenz.com
            </p>
            <p className="footerContNumber">
              <strong>Contact Number: </strong>USA: +18889433033
            </p>
            <p className="footerAddress">
              <strong>Address: </strong> 101 Crawfords Corner Road, Suite 4116,
              Holmdel, NJ 07733
            </p>
          </div>
        </div>
      </div>
      <div className="copyrightSection">
        <p>
          © {year} Telepresenz. All rights reserved. <span><Link to="/privacy"> Privacy.</Link></span>
        </p>
      </div>
    </>
  );
}

export default Footer;
