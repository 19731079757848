import React, { useState, useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Recent } from "./components/Recent/Recent.jsx";
import Category from "./components/Category/Category";
import Search from "./components/SearchComponent/Search";
import NewsCard from "./components/NewsCard/NewsCard";
import Pagination from "./components/Pagination/Pagination.jsx";
import { NewsData } from "./NewsData/NewsCardData.js";
import "./news.css";

const News = () => {
  const [searchText, setSearchText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const [searchResults, setSearchResults] = useState(NewsData);
  const [displayedOptions, setdisplayedOptions] = useState(NewsData);
  const [selectedPagination, setSelectedPagination] = useState(1);
  const [numOfNewsCards, setNumOfNewsCards] = useState(0);
  const [finalRecordsToDisplay, setFinalRecordsToDisplay] = useState([]);
  const numOfCardsPerPage = 6;
  const paginationNumber = Math.ceil(numOfNewsCards / numOfCardsPerPage);

  const totalPagesArray = [];
  
  //

  const getSelectedCategory = (selectedCate) => {
    setSelectedCategory(selectedCate);
  }

  const handleSearchInput = (event) => {
    const filteredData = NewsData.filter((item) => (
      item.title.toLowerCase().includes(searchText.toLowerCase())
    ));
    setSearchResults(filteredData);
  }

  //  search change
  const handleInputChange = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);
    handleSearch(searchValue);
  };

  // for search dropdown
  const handleSearch = (searchInputText) => {
    const filteredSearchData = NewsData.filter((item) =>
      item.title.toLowerCase().includes(searchInputText.toLowerCase())
    );
    setdisplayedOptions(filteredSearchData);
  };

  //sorting the cards based on the date
  
   useEffect(()=>{
    const sortedNewsData = [...searchResults].sort((a, b) => new Date(b.date) - new Date(a.date));
    setSearchResults(sortedNewsData)
  });

  // for category search
  useEffect(() => {
    selectedCategory !== "All" ? (setSearchResults(NewsData.filter((item, index) => item.category === selectedCategory))) :
      setSearchResults(NewsData);
  }, [selectedCategory]);

  useEffect(() => {
    if (!searchText) {
      setSearchResults(NewsData);
    }
  }, [searchText]);

  useEffect(() => {
    setNumOfNewsCards(searchResults.length);
  }, [searchResults.length]);

  useEffect(() => {
    for (let i = 0; i <= numOfNewsCards + 1;) {
      totalPagesArray.push(i);
      i = i + numOfCardsPerPage;
    }
  }, [numOfNewsCards, selectedPagination, searchResults]);


  useEffect(() => {
    setFinalRecordsToDisplay(searchResults.slice(totalPagesArray[selectedPagination - 1], totalPagesArray[selectedPagination]));
  }, [selectedPagination, searchResults, numOfNewsCards]);

  

  const displayNewsCards = finalRecordsToDisplay.map((record, index) => (
    <NewsCard
      key={index}
      CardImg={record.img}
      CardTitle={record.title}
      CardCategory={record.category}
      CardContent={record.content}
      CardDate={record.date}
      CardUrl={record.url}
    />
  ))

  

  const getUpdatedPagination = (updatedPaginationNum) => {
    setSelectedPagination(updatedPaginationNum)
  }

  return (
    <div className="news-container">
      <div className="news-banner">
        {selectedCategory === "ALL" ? (
          <h1 className="news">News and Insights</h1>
        ) : (
          <h2 className="category-header">Category : {selectedCategory}</h2>
        )}

        <span className="newshome">
          {selectedCategory !== "ALL" ? (
            <div>
              <IoIosArrowForward /> {selectedCategory}
            </div>
          ) : null}
        </span>
      </div>

      <div className="news-body">
        <div className="news-cardSection">
          {displayNewsCards}
        </div>

        <div className="news-sidebar">
          <Search
            handleInputChange={handleInputChange}
            handleSearchInput={handleSearchInput}
            displayedOptions={displayedOptions}
            searchText={searchText}
          />

          {/*<Recent />*/}

          <Category
            getSelectedCategory={getSelectedCategory}
          />
        </div>

      </div>

      {/* pagination */}
      <div>
        {searchResults.length > 1 && <Pagination paginationNumber={paginationNumber} numOfNewsCards={searchResults.length} getUpdatedPagination={getUpdatedPagination} />}
      </div>
    </div>
  );
}

export default News;