import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from 'swiper/modules';
import "swiper/css";
import "./partnerSwiper.css";

const partnerImages = require.context('../../assets/Partners', true);
const partnerImgList = partnerImages.keys().map(image => partnerImages(image));

const PartnerSwiper = () => {
  return (
    <>
    <h1 className="partner-heading">Industry leaders working with Telepresenz in the field </h1>
      <Swiper
        slidesPerView={5}
        spaceBetween={30}
        loop={true}
        freeMode={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          400: {
            slidesPerView: 3,
          },
          639: {
            slidesPerView: 3,
          },
          865: {
            slidesPerView: 4,
          },
          1000: {
            slidesPerView: 4,
          },
          1500: {
            slidesPerView: 5,
          },
          1700: {
            slidesPerView: 5,
          },
        }}
        pagination={{
          clickable: true,
        }}
        className="swiper-main"
      >

        {partnerImgList.map((image, index) => (
          <SwiperSlide className="partner-swipper"><img key={index} src={image} alt={`image-${index}`} /></SwiperSlide>
        ))}

      </Swiper >
    </>

  )
}

export default PartnerSwiper



