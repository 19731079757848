import React from 'react'
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import "./search.css";

const Search = (props) => {

const searchDropDownBox = props.searchText && (
    <div className="searchdrop">
        {props.displayedOptions.map((item, index) => (
            <Link key={index} to={`/newsdetails/${item.title.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '').replace(/-+/g, '-')}/`} className="news-link">
                <div className="searchdropcontainer">
                        <img
                            src="https://cdn.pixabay.com/photo/2022/01/28/18/32/leaves-6975462_1280.png"
                            alt="dropdownimg"
                            height="21px"
                            width="21px"
                        />                               
                        <p value={item.title}>{item.title.slice(0, 30)}</p>
                </div>
            </Link>
        ))}
    </div>
)

    return (
        <div className="newssearch">
            <div>
                <h3>Search</h3>
            </div>
            <div className="searcharea">
                <input
                    type="text"
                    className="searchspace"
                    placeholder="Search News..."
                    value={props.searchText}
                    onChange={props.handleInputChange}
                />
                <CiSearch
                    onClick={props.handleSearchInput}
                    className="searchicon"
                />
            </div>

            {searchDropDownBox}
        </div>
    )
}

export default Search
