//https://telepresenz.com/realwear-ready-partner-spotlight-care4d/
//RealWear Ready Partner Spotlight: CARE4D

import React from 'react';
import bannerimg46 from "../News/NewsData/NewsImageAssets/NewsCardImg45.jpg";
import './newsDetails.css';

const NewsDetails46 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>RealWear Ready Partner Spotlight: CARE4D</h1>
        <div className='banner-Img '>
            <img src={bannerimg46} alt="RealWear Ready Partner Spotlight: CARE4D" />
        </div>
        <p>The <a href="https://www.telepresenzdemo.com/" target='_blank'> Telepresenz®</a> solution from CARE4D is now presenting its certified application for RealWear 
        HMT-1 devices. This innovative technology offers a streamlined remote mentoring solution equipped with integrated workflows for enterprise teams, 
        specifically built for RealWear HMT-1 devices. The <a href="https://www.telepresenzdemo.com/" target='_blank'> Telepresenz®</a> platform enables real-time, 
        two-way communication between a base station expert and remote users.</p>
        <p>The creator behind <a href="https://www.telepresenzdemo.com/" target='_blank'> Telepresenz®</a>, 
        <a href="https://www.telepresenzdemo.com/meet-indu-tolia-creator-and-founder-of-care4d-and-now-telepresenz/" target='_blank'> Indu Tolia</a>,
        states “this technology is the brainchild stemmed from my life’s experience working with the field service industry of today and 
        built for the future of connected workforce”. <a href="https://www.telepresenzdemo.com/" target='_blank'> Telepresenz®</a> and RealWear together
        create a lethal combination of a highly customizable and scalable SaaS solution with simplicity at its core. 
        <a href="https://www.telepresenzdemo.com/" target='_blank'> Telepresenz®</a> uses a unique form factor of the device that allows users to leverage the 
        program’s exceptional voice navigation and processing capabilities to deliver seamless direction from anywhere in the world. </p>
        <p>CARE4D’s <a href="https://www.telepresenzdemo.com/" target='_blank'> Telepresenz®</a> solution aids and propels service in the fields of assembly plants, 
        factories, manufacturing, MRO, field service, training, telemedicine, and remote inspection. This thoughtfully designed immersive product 
        offers solutions and improvements to enterprises’ most important aspects of communication, operations, and problem-solving. </p>
        <div className='newsDetailImg'>
          <img src="https://www.telepresenzdemo.com/wp-content/uploads/2020/01/unnamed.1.png" alt="Indu Tolia" />
        </div>
        <p>To learn more about the Telepresenz® application from CARE4D and how it partners with RealWear, visit realwear.com or call (732) 440-9453. </p>
      </div>
    </>
  )
}

export default NewsDetails46;
