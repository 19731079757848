//https://telepresenz.com/termsconditions/
//Terms and Conditions 

import React from 'react';
import './newsDetails.css';

const NewsDetails41 = () => {
  return (
    <>
       <div className='newsDetails'>
          <h1>Terms & Conditions</h1>
          <h2>Terms and Conditions </h2>
          <p>Last updated: April 06, 2020 </p>
          <p>Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using the
          <a href="https://app.telepresenz.com/" target='_blank'> https://app.telepresenz.com/ </a> website (the “Service”) 
          operated by C.A.R.E. Core Augmented Reality Education LLC DBA  <b>CARE4D</b> (“us”, “we”, or “our”) .</p>
          <p>Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. 
          These Terms apply to all visitors, users and others who wish to access or use the Service. </p>
          <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the 
          terms then you do not have permission to access the Service. </p>
          <p className='subHeader'>Purchase</p>
          <p>Definition: A purchase is a new request to get user licenses on any subscription plan offered. If the purchase is the first ever purchase 
          (which is usually at the time of signup), the subscription term is 12 months plus any free trial period if offered. Any subsequent purchases 
          of additional licenses will be charged be on a pro-rated basis with the subscription term expiring on the same date as the original purchase. </p>
          <p className='subHeader'>Subscriptions </p>
          <p>Definition: A subscription is by default a 12 month period from the date first charged on any subscription plan. As new users/plans are 
           added to the account, the subscription term remains the same and does not change. Additional purchases are calculated with the original 
           subscription end date and charged on a pro-rated basis. All additional purchases for your account after the initial purchase, will always 
           have the subscription end date of the original purchase. </p>
          <p>The Service is billed on a subscription basis (“Purchase(s)”). You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). 
           Billing cycles are set on a annual basis. </p>
          <p>At the end of each Billing Cycle, your Purchase(s) will automatically renew under the exact same conditions unless you cancel it or CARE4D cancels it. 
           You may cancel your Purchase(s) renewal either through your online account management page or by contacting CARE4D customer support team. </p>
          <p>A valid payment method, including credit card, is required to process the payment for your Purchase(s) of Subscription(s). You shall provide CARE4D 
            with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. 
            By submitting such payment information, you automatically authorize CARE4D to charge all Subscription fees incurred through your account to any 
            such payment instruments. </p>
          <p>Should automatic billing fail to occur for any reason, CARE4D will issue an electronic invoice indicating that you must proceed manually, within a 
           certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice. Access to the Service will be revoked if 
           payment is not received within the deadline date. </p>
          <p className='subHeader'>Cancelling Subscriptions</p>
          <p>Subscriptions can be cancelled at any time for any user on the account. Cancelling a subscription will make that user ‘inactive’ and free up a 
           user license as a license credit to be assigned to any other user/new user on the account. There will be no refunds for cancelled subscriptions. </p>
          <p className='subHeader'>Cancelling Purchases</p>
          <p>Purchases can be cancelled at any time, but no refunds will be provided for the remainder of subscription term. Purchases can be cancelled by 
           deleting the account at which time all account data will be lost and cannot be recovered. </p>
          <p className='subHeader'>Overage Charges</p>
          <p>Once the minutes allocated for a user are completely utilized, the user will continue to have access to Telepresenz and will be charged for all 
           overage minutes at USD 0.15 per minute. Active customers will be billed for any overage on the 1st day of the next month during your subscription 
           term for overage minutes accrued for the month by all the users in your account and charged automatically to the credit card on file. Customers who 
           may have accrued overage minutes at the time of cancelling purchase/s, will be billed for overage minutes and charged automatically to the credit 
           card on file, before deleting the purchase. </p>
          <p className='subHeader'>Free Trial</p>
          <p>CARE4D may, at its sole discretion, offer a Subscription plan with a free trial for a limited period of time (“Free Trial”).</p>
          <p>You may be required to enter your billing information in order to sign up for the Free Trial. </p>
          <p>If you do enter your billing information when signing up for the Free Trial, you will not be charged by CARE4D until the Free Trial has expired. 
          On the last day of the Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees 
          for the type of Subscription you have selected. </p>
          <p>At any time and without notice, CARE4D reserves the right to (i) modify the terms and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer. </p>
          <p className='subHeader'>Fee Changes</p>
          <p>CARE4D, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any Subscription fee 
          change will become effective at the end of the then- current Billing Cycle. </p>
          <p>CARE4D will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate 
           your Subscription before such change becomes effective. </p>
          <p>Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount. </p>
          <p className='subHeader'>Refunds</p>
          <p>No refunds are provided on cancellation of subscriptions. </p>
          <p className='subHeader'>Accounts </p>
          <p>When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, 
           and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service. </p>
          <p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to 
            your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or 
            password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach 
            of security or unauthorized use of your account. </p>
          <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject 
           to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene. </p>
          <p className='subHeader'>Intellectual Property</p>
          <p>The Service and its original content, features and functionality are and will remain the exclusive property of CARE4D and its licensors. The Service is 
            protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in 
            connection with any product or service without the prior written consent of CARE4D. </p>
          <p className='subHeader'>Links To Other Web Sites</p>
          <p>Our Service may contain links to third party web sites or services that are not owned or controlled by CARE4D. </p>
          <p>CARE4D has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services.
             We do not warrant the offerings of any of these entities/individuals or their websites.</p>
          <p>You acknowledge and agree that CARE4D shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by 
          or in connection with use of or reliance on any such content, goods or services available on or through any such third party web sites or services. </p>
          <p>We strongly advise you to read the terms and conditions and privacy policies of any third party web sites or services that you visit. </p>
          <p className='subHeader'>Termination</p>
          <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, 
          for any reason whatsoever and without limitation, including but not limited to a breach of the Terms. </p>
          <p>If you wish to terminate your account, you may simply discontinue using the Service. </p>
          <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership 
           provisions, warranty disclaimers, indemnity and limitations of liability. </p>
          <p className='subHeader'>Indemnification</p>
          <p>You agree to defend, indemnify and hold harmless CARE4D and its licensee and licensors, and their employees, contractors, agents, officers and directors, 
            from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees), 
            resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password, or b) a breach of these Terms. </p>
          <p className='subHeader'>Limitation Of Liability</p>
          <p>In no event shall CARE4D, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, 
           consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) 
           your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content 
           obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, 
           tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy 
           set forth herein is found to have failed of its essential purpose. </p>
          <p className='subHeader'>Disclaimer</p>
          <p>Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express 
           or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance. </p>
          <p>CARE4D its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular 
            time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the 
            Service will meet your requirements. </p>
          <p className='subHeader'>Exclusions</p>
          <p>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, 
           so the limitations above may not apply to you. </p>
          <p className='subHeader'>Governing Law</p>
          <p>These Terms shall be governed and construed in accordance with the laws of New Jersey, United States, without regard to its conflict of law provisions. </p>
          <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid 
           or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding 
           our Service, and supersede and replace any prior agreements we might have had between us regarding the Service. </p>
           <p className='subHeader'>Changes</p>
           <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days 
            notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. </p>
           <p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. 
            If you do not agree to the new terms, you are no longer authorized to use the Service. </p>
           <p className='subHeader'> Contact Us</p>
           <p>If you have any questions about these Terms, please contact us @ <a href={'mailto:support.telepresenz@care4d.com'}>support.telepresenz@care4d.com</a></p>
       </div>
      
    </>
  )
}

export default NewsDetails41;
