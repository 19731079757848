//https://telepresenz.com/softwareone-hong-kong-teams-up-with-telepresenz/
//New Strategic Partnership Announcement: Telepresenz & SoftwareONE Hong Kong
import React from 'react';
import './newsDetails.css';
import bannerimg18 from "../News/NewsData/NewsImageAssets/NewsCardImg17.jpg";

const NewsDetails18 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>New Strategic Partnership Announcement: Telepresenz & SoftwareONE Hong Kong</h1>
        <div className='banner-Img '>
            <img src={bannerimg18} alt="Telepresenz & SoftwareONE Hong Kong" />
        </div>
        <p>We are proud to announce a new strategic partnership with SoftwareONE Hong Kong by providing workers with all the tools they 
           need to execute complex tasks in the field efficiently and safely using wearable devices.</p>
        <p>To extend our sole partnership with RealWear, SoftwareONE Hong Kong and Telepresenz have teamed up to combine our capacities 
           towards valuable Go-to-Market ideas and provide innovative solutions for customers by empowering workers with remote inspections 
           and maintenance; and also increasing productivity with robust collaboration tools.</p>
        <h3>About Telepresenz</h3>
        <p>Telepresenz is a global software solution company that specializes in digitizing mobile workforces through the use of our purpose-built, 
           off-the-shelf SaaS platform that integrates with ERP, EMMS, AIMS, and manufacturing systems to increase productivity, deliver better decisions, 
           and lower risk for our customers. Our objective is to offer the market’s most user-friendly, feature-rich, and scalable connected worker 
           platform, providing field workers with real-time access to knowledge, data, and information for diagnosing and addressing critical 
           issues as they arise. Telepresenz is a pioneer in mobile-first industrial digital transformation, with expertise in enterprise wearables, 
           IIoT, AI, and augmented intelligence.</p>
        <h3>About SoftwareONE</h3>
        <p>SoftwareONE (SIX: SWON) is a leading global provider of end-to-end software and cloud technology solutions, headquartered in Switzerland. With an IP
           and technology-driven service portfolio, it enables companies to holistically develop and implement their commercial, technology and digital strategies. 
           This is achieved by modernizing applications and migrating critical workloads on public clouds, while simultaneously managing and optimizing related 
           software and cloud assets and licensing. SoftwareONE’s offerings are connected by PyraCloud, its proprietary digital platform, which provides customers 
           with data-driven, actionable intelligence.</p>
        <p>For more information about SoftwareONE, please visit: <a href="https://www.softwareone.com/" target='_blank'>https://www.softwareone.com</a></p>
        <p>Telepresenz Sales and Contact: <a href={'mailto:sales@telepresenz.com'}>sales@telepresenz.com</a></p>
      </div>
    </>
  )
}

export default NewsDetails18;
