import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import {Recent} from "../News/components/Recent/Recent.jsx"
import Category from "../News/components/Category/Category.jsx"
import Search from "../News/components/SearchComponent/Search.jsx";
import NewsCard from "../News/components/NewsCard/NewsCard";
import Pagination from "../News/components/Pagination/Pagination.jsx";
import { NewsData } from "../News/NewsData/NewsCardData.js";
import {BlogData} from "./BlogData/BlogCardData.js"
import "./blog.css";

const Blog = () => {
  const [searchText, setSearchText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const [searchResults, setSearchResults] = useState([]);
  const [displayedOptions, setdisplayedOptions] = useState(BlogData);
  const [selectedPagination, setSelectedPagination] = useState(1);
  const [numOfNewsCards, setNumOfNewsCards] = useState(0);
  const [finalRecordsToDisplay, setFinalRecordsToDisplay] = useState([]);
  const [updatedTotalPagesArray, setUpdatedTotalPagesArray]=useState(1);
  const numOfCardsPerPage = 6;
  const paginationNumber = Math.ceil(numOfNewsCards / numOfCardsPerPage);

  const totalPagesArray = [];

  const getSelectedCategory = (selectedCate) => {
    setSelectedCategory(selectedCate);
  }

  const handleSearchInput = (event) => {
    const filteredData = BlogData.filter((item) => (
      item.title.toLowerCase().includes(searchText.toLowerCase())
    ));
    setSearchResults(filteredData);
  }

  //  search change
  const handleInputChange = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);
    handleSearch(searchValue);
  };

  // for search dropdown
  const handleSearch = (searchInputText) => {
    const filteredSearchData = BlogData.filter((item) =>
      item.title.toLowerCase().includes(searchInputText.toLowerCase())
    );
    setdisplayedOptions(filteredSearchData);
  };

  useEffect(() => {
    setSearchResults(BlogData);
  }, []);

  //sorting the cards based on the date
   //
   useEffect(()=>{
    const sortedBlogData = [...searchResults].sort((a, b) => new Date(b.date) - new Date(a.date));
    setSearchResults(sortedBlogData)
  });

  // for category search
  useEffect(() => {
    selectedCategory !== "All" ? (setSearchResults(BlogData.filter((item, index) => item.category === selectedCategory))) :
      setSearchResults(BlogData);
  }, [selectedCategory]);

  useEffect(() => {
    if (!searchText) {
      setSearchResults(BlogData);
    }
  }, [searchText]);

  useEffect(() => {
    setNumOfNewsCards(searchResults.length);
  }, [searchResults]);

  useEffect(() => {
    for (let i = 0; i <= numOfNewsCards;) {
      totalPagesArray.push(i);
      i = i + numOfCardsPerPage;
    }
    setUpdatedTotalPagesArray(totalPagesArray); 
  }, [numOfNewsCards, selectedPagination, searchResults]);


  useEffect(() => {
    setFinalRecordsToDisplay(searchResults.slice(totalPagesArray[selectedPagination - 1], totalPagesArray[selectedPagination]));
  }, [selectedPagination, searchResults, numOfNewsCards]);


  const displayNewsCards = finalRecordsToDisplay.map((record, index) => (
    <NewsCard
      key={index}
      CardImg={record.img}
      CardTitle={record.title}
      CardCategory={record.category}
      CardContent={record.content}
      CardDate={record.date}
      CardUrl={record.url}
    />
  ))

  const getUpdatedPagination = (updatedPaginationNum) => {
    setSelectedPagination(updatedPaginationNum)
  }

  return (
    <div className="news-container">
      <div className="news-banner">
        {selectedCategory === "ALL" ? (
          <h1 className="news">News and Insights</h1>
        ) : (
          <h2 className="category-header">Category : {selectedCategory}</h2>
        )}

        <span className="newshome">
          {/*<Link to="/" className="news-links">
            <h5>Home</h5>
        </Link>*/}

          {selectedCategory !== "ALL" ? (
            <div>
              <IoIosArrowForward /> {selectedCategory}
            </div>
          ) : null}
        </span>
      </div>

      <div className="news-body">
        <div className="news-cardSection">
          {displayNewsCards}
        </div>

        <div className="news-sidebar">
          <Search
            handleInputChange={handleInputChange}
            handleSearchInput={handleSearchInput}
            displayedOptions={displayedOptions}
            searchText={searchText}
          />

         {/*} <Recent />*/}

         {/* <Category
            getSelectedCategory={getSelectedCategory}
          /> */}
        </div>

      </div>

      {/* pagination */}
      <div>
        {searchResults.length > 1 && <Pagination paginationNumber={paginationNumber} numOfNewsCards={searchResults.length} getUpdatedPagination={getUpdatedPagination} />}
      </div>
    </div>
  );
}

export default Blog;