//https://telepresenz.com/industrial-wearable-guide/
//Request our Industrial Wearable Guide

import React from 'react';
import vuzix from './NewsDetailsAssets/vuzix.jpg';
import vuzixFreeHand from './NewsDetailsAssets/vuzix-free-hands.jpg';
import vuzixHMT from './NewsDetailsAssets/vuzix-hmt.jpg';
import './newsDetails.css';
const NewsDetails35 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>Request our Industrial Wearable Guide</h1>
        <div className='flexContainer'>
            <div className='flexItemRight'>
                <img src={vuzix} alt="Emergrncy Response" />
            </div>
            <div className='flexItemLeft'>
                <img src={vuzixFreeHand} alt="Emergency Response" />
            </div>
        </div>
      </div>
    </>
  )
}

export default NewsDetails35;
