import telepresenzCardImg1 from "../../assets/services/discoveryNconsultation1.jpg";
import telepresenzCardImg2 from "../../assets/services/domainExpertise1.jpg";
import telepresenzCardImg3 from "../../assets/services/customization1.jpg";
import telepresenzCardImg4 from "../../assets/services/maintainenceContracts1.jpg";
import telepresenzCardImg5 from "../../assets/services/projDeployment1.jpg";
import telepresenzCardImg6 from "../../assets/services/PMnBMsupport1.jpg";

export const contents = [
  {
    imageURL: telepresenzCardImg1,
    header: "Discovery / consultation",
    description:
      [
        "Embarking on a journey of customer-focused solutions, the discovery and scoping phases are pivotal",
        "Like explorers gathering vital insights, we delve into the customer’s world, asking key questions to unearth their goals and challenges.",
        "This initial exploration shapes our understanding and guides the subsequent scoping phase.",
        "Here, we balance ambitions with practical constraints, plotting a feasible and impactful roadmap.",
        "This concise process ensures our solutions not only align with but also elevate customer expectations, steering projects towards success with precision and empathy.",
      ],
  },
  {
    imageURL: telepresenzCardImg2,
    header: "Domain Expertise",
    description:
      [
        "In a world driven by specialization, offering domain expertise as a service stands as a beacon of guidance and excellence.",
        "This approach harnesses years of focused experience, transforming it into a valuable asset for clients.",
        "Specialists in fields like finance, healthcare, or technology delve into their reservoirs of knowledge, offering insights and solutions tailored to specific industry challenges.",
        "This service transcends mere consultation; it's about embedding deep understanding into every aspect of a project, ensuring informed decisions and cutting-edge outcomes.",
        "Ultimately, it's not just about sharing knowledge—it's about elevating businesses to new heights of proficiency and success.",
      ],
  },
  {
    imageURL: telepresenzCardImg3,
    header: "Customisation",
    description:
      [
        "Embracing the uniqueness of each customer, product customization services offer a personalized touch to standard offerings.", "This service transforms generic items into bespoke creations, aligning with individual preferences and needs.",
        "From custom-designed apparel to tailored software solutions, the scope is vast and diverse.",
        "This approach not only enhances customer satisfaction but also fosters a deeper connection with the brand.",
        "By involving customers in the creation process, businesses not only meet specific requirements but also create a sense of ownership and exclusivity.",
        "Product customization is not just a service; it's an experience.",
      ],
  },
  {
    imageURL: telepresenzCardImg4,
    header: "Mx Contracts",
    description:
      [
        "Annual Maintenance Contracts (AMCs) offer a proactive solution to safeguarding the longevity and performance of products and systems.",
        "This service provides regular check-ups and repairs, ensuring consistent functionality and preventing unforeseen breakdowns.", "It's akin to a health plan for your technical assets, from IT infrastructure to machinery, where routine care wards off major issues.",
        "AMCs offer peace of mind with their predictability in costs and scheduling.",
        "They're not just maintenance services; they're partnerships in reliability and efficiency.",
        "Businesses benefit from minimized downtime and optimized performance, making AMCs an intelligent investment in the smooth operation of essential systems.",
      ],
  },
  {
    imageURL: telepresenzCardImg5,
    header: "Onsite Deployments",
    description:
      [
        "Offering on-site implementation, deployments, and integrations as a service embodies a commitment to hands-on, tailored support.",
        "This approach brings experts directly to the client's location, facilitating real-time, customized solutions.",
        "Whether it's setting up new software, integrating various systems, or deploying complex IT infrastructure, on-site presence ensures accuracy and efficiency.",
        "This service transcends remote assistance, allowing for immediate response to challenges and adaptation to unique business environments.",
        "It's a blend of expertise and flexibility, ensuring seamless transitions and integrations.",
        "Clients benefit from minimized disruption and maximized system potential.",
      ],
  },
  {
    imageURL: telepresenzCardImg6,
    header: "PM/BA support",
    description:
      [
        "Offering Project Management and Business Analysis as a combined service creates a powerhouse of strategic planning and execution.",
        "This dual approach harnesses the meticulousness of business analysis with the structured direction of project management.",
        "Business analysts delve into the nuances of client needs, market trends, and operational requirements, forming a solid foundation of insights.",
        "Project managers then use these insights to steer projects towards timely and efficient completion.",
        "Together, they ensure alignment of business goals with project outcomes, optimizing resources and mitigating risks.",
        "This service is not just about managing projects; it's about achieving business objectives with precision and foresight.",
      ],
  },
];
