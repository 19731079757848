import React from 'react'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import "./banner.css";

const Banner = (props) => {
  return (
    <div className="bannerContainer">
        <div className="bannerTextSection">
          <h1>{props.pageName}</h1>
        </div>
        </div>
  )
}

export default Banner;
