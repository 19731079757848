//https://telepresenz.com/cbt-partners-with-telepresenz-to-expand-connected-worker-capabilities/
//CBT Partners with Telepresenz to Expand Connected Worker Capabilities
import React from 'react';
import './newsDetails.css';
import bannerimg17 from "../News/NewsData/NewsImageAssets/NewsCardImg16.jpg";

const NewsDetails17 = () => {
  return (
    <>
      <div className='newsDetails'>
        <h1>CBT Partners with Telepresenz to Expand Connected Worker Capabilities</h1>
        <div className='banner-Img '>
            <img src={bannerimg17} alt="CBT Partners with Telepresenz to Expand Connected Worker Capabilities" />
        </div>
        <p>Orange, CA, Sept. 01, 2022 (GLOBE NEWSWIRE) — CBT, a woman-owned Domain Expert Integrator, today announced its partnership with Telepresenz, 
            a software solution company that specializes in digitizing mobile workforces. Together, CBT and Telepresenz will offer
            <a href="https://www.globenewswire.com/Tracker?data=-61RWt4PnGCw8OGldtirx8E70-dQTHogVZ_IJ-CkIzmsJAFZWuAUujF115IORxkH0C7MKJWcXyMMPod9NBPcrZF7tSCbLljeXcFTIuh--fZ9XUOAsmjLEfnohl9ffnf-" target='_blank'> Connected Worker </a>
            solutions including Remote Mentoring, Digital Workflow, and Knowledge Capture.</p>
        <p>As a result of the partnership, CBT and Telepresenz will help companies achieve direct near-term ROI by identifying and correcting 
            current system inefficiencies. The Connected Worker solutions will not only increase efficiency but also allow in-field data reporting, 
            improved training, enhanced safety, and much more. Ultimately, the solutions will help business operations stay competitive by modernizing their workforce.</p>
        <p>These capabilities bolster CBT’s industry-leading portfolio of IoT and IT/OT convergence solutions, including Connected Worker, Worker Health & Safety, 
            Condition Monitoring & Predictive Maintenance, Asset Integrity Management, and Video as a Sensor.</p>
        <p className='property'>“We’re excited to partner with Telepresenz to further our Connected Worker capabilities. Over the past year, with companies 
            forced to re-think their work environments and significantly reduce on-site staff, CBT’s Connected Worker solutions have been especially effective,” 
            said Kelly Ireland, Founder, CEO, and CTO at CBT. “This partnership shows our continuous commitment to our customers in providing the best solutions and services.”</p>
        <p className='property'>“We are thrilled to be partnering with CBT to offer businesses the ability to gain real-time insights by connecting people, 
            devices, data, and processes,” said Indu Tolia, CEO at Telepresenz.</p>
        <p>To learn more about the benefits of the Internet of Things (IoT) solutions offered by CBT and Telepresenz, schedule a 
            <a href="https://www.globenewswire.com/Tracker?data=ECcleIHIFl--eLALqa0dK9J8aHFAdLzDhE0a5_y8nD8Vuh54G_MwlsDGCbXVVSyunoXwfqmrRuY3VXZAvxwHGw==" target='_blank'> Quickstart </a>
            ideation session with CBT or reach out directly to the team at 
            <a href={'mailto:cbtinfo@cbtechinc.com'}> cbtinfo@cbtechinc.com</a>.</p>
        <h6>About Telepresenz</h6>
        <p>Telepresenz Inc. is a software solution company that specializes in digitizing mobile workforces, using our purpose-built, off-the-shelf, 
            SaaS platform that integrates ERP, EMMS, AIMS, and manufacturing systems to increase productivity, deliver better decisions, and lower risk for our customers.</p>
        <h6>About CBT</h6>
        <p>CBT is an unparalleled design-thinking and integration-engineering company. It utilizes unique expertise to bridge the gap between OT and IT 
            and accelerate smart operations in manufacturing, utilities, oil and gas, and healthcare. CBT’s solutions are powered by next-generation 
            innovations from an industry-leading partner ecosystem, led by Telepresenz, Hewlett Packard Enterprise, PTC, AMD, NVIDIA, Intel, Nokia, 
            ABB, GuardHat, RealWear, and many more. As a first mover and Domain Expert Integrator, CBT has a proven track record of taking customers 
            from ideas to execution in production environments. Its solutions go beyond the data center to deliver business transformation across the enterprise.</p>
      </div>
    </>
  )
}

export default NewsDetails17;
